import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    textDate: {
      padding: "1rem",
      fontSize: ".875rem",
      fontWeight: 500,
      fontFamily: "Rubik",
      lineHeight: "1.625rem",
      color: colors.grayText,
    },
    scrollInternal: {
      borderRadius: "8px",
      overflow: "hidden",
      paddingRight: "0.5rem",
    },
  })
);

export default useStyles;
