import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        cardContent: {
            minHeight: "62.4375rem",
            flexDirection: "column",
            color: colors.primary,
        },
        rootTabs: {
            display: "flex",
            flexDirection: "column",
            paddingTop: "10.5rem",
            padding: "2rem",
            background: colors.background,
            /* height: "100%",
            minHeight: "100%", */
        },
        tabStyles: {
            maxWidth: "15rem",
            width: "23.2%",
            border: `1px solid ${colors.background}`,
            marginRight: "1rem",
            borderTopLeftRadius: "0.625rem",
            borderTopRightRadius: "0.625rem",
            textTransform: "none",
            fontSize: "1rem",
            color: "rgba(74, 144, 226, 1)",
            background: colors.white,
            borderBottom: `0.063rem solid ${colors.white}`,
        },
        TabPanelStyles: {
            border: `1px solid ${colors.background}`,
            borderTopRightRadius: "0.625rem",
            borderBottomRightRadius: "0.625rem",
            borderBottomLeftRadius: "0.625rem",
            background: colors.white,
            boxSizing: "content-box",
            width: "100%",
            height: "100%",
            minHeight: "100%",
        },
    })
);

export default useStyles;
