//
import * as types from "./type";

export const login = () => ({
  type: types.LOGIN,
  payload: {},
});

export const setUserData = (data) => ({
  type: types.SET_USER_DATA,
  payload: { data },
});

export const getUser = () => ({
  type: types.GET_USER_DATA,
  payload: {},
});
