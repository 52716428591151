import React, { useReducer } from "react";
import DisableTableReducer from "./DisableTableReducer";
import DisableTableContext from "./DisableTableContext";

const DisableTableState = (props) => {
  //check local storage
  const initialState = {
    disableTable: false,
  };

  const [state, dispatch] = useReducer(DisableTableReducer, initialState);

  const setDisableTabla = async (data) => {
    dispatch({
      type: "SET_DISABLE_TABLA",
      payload: data,
    });
  };

  return (
    <DisableTableContext.Provider
      value={{ disableTable: state.disableTable, setDisableTabla }}
    >
      {props.children}
    </DisableTableContext.Provider>
  );
};

export default DisableTableState;
