import Skeleton from "@mui/material/Skeleton";
import colors from "constants/colors";
import Grid from "@mui/material/Grid";

const SkeletonTransaction = () => {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Skeleton
        variant="rectangular"
        style={{
          marginTop: "16px",
          background: colors.background,
          borderRadius: "10px",
        }}
        width={"100%"}
        height={"9rem"}
      />
      <Skeleton
        variant="rectangular"
        style={{
          marginTop: "16px",
          background: colors.background,
          borderRadius: "10px",
        }}
        width={"100%"}
        height={"41.2rem"}
      />
    </Grid>
  );
};

export default SkeletonTransaction;
