import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      color: colors.primary,
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
      textTransform: "uppercase",
      verticalAlign: 'top'
    },
    tableCellData: {
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      padding: "0px",
    },
  })
);

export default useStyles;
