import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    textArea: {
      borderRadius: "8px",
      border: "1px solid #B6C5D5",
      background: "white",
      fontFamily: "Rubik",
      fontSize: "16px",
      color: colors.textTable,
      "&:focus": {
        border: "2px solid #B6C5D5",
      },
    },
  })
);

export default useStyles;
