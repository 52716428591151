import KeyCloakService from "services/KeyCloakService";

const RenderOnRole = ({ roles, children }) => {
    //ignore role user
    /* if (roles.includes('none')) {
        return children
    } */

    if (KeyCloakService.isLoggedIn) {
        return KeyCloakService?.hasRole(roles) ? children : null
    }
};

export default RenderOnRole