import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    leftIconButton: {
      color: colors.primary,
    },
    rightIconButton: {
      color: colors.primary,
    },
    TableRow: {
      "&:hover": {
        backgroundColor: colors.backgroundHover,
      },
    },
  })
);

export default useStyles;
