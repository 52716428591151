import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    TableRow: {
      "&:hover": {
        backgroundColor: colors.backgroundHover,
      },
    },
    cardInternal: {
      // width: "100%",
      // height: "9rem",
      background: colors.background,
      borderRadius: ".5rem",
      borderTopLeftRadius: "0",
      boxShadow: "none",
      margin: "auto",
    },
    cardInternalTabs: {
      // width: "100%",
      // height: "9rem",
      background: colors.background,
      boxShadow: "none",
      borderTopLeftRadius: ".5rem",
      borderTopRightRadius: ".5rem",
      margin: "auto",
    },
    cardTable: {
      width: "100%",
      minHeight: "41.2rem",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      margin: "auto",
    },
    gridInternal: {
      padding: "2rem",
    },
    div: {
      marginTop: ".875rem",
      marginBottom: ".875rem",
      borderTop: `1px solid ${colors.borderDiv}`,
    },
    textHeaderSub: {
      paddingBottom: "1rem",
      color: colors.primary,
    },
    buttomFilter: {
      background: colors.primary,
      color: colors.white,
      borderRadius: "1.125rem",
      width: "6.125rem",
      height: "2.25rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.225rem",
      marginLeft: "1.125rem",
      paddingRight: "0.313rem",
      paddingLeft: "0.313rem",
      "&:hover": {
        backgroundColor: colors.primarydark,
      },
      "&:active": {
        backgroundColor: colors.cyan,
      },
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    dateTextField: {
      width: 250,
      color: colors.primarydark + " !important",
      borderRadius: "1em",
    },
  })
);

export default useStyles;
