export default {
  primaryLight: "#479EF8",
  primary: "#023F80",
  primarydark: "#002A55",

  magentaLight: "#FF95B3",
  magenta: "#DD2457",
  magentaDark: "#B11E47",

  cyanLight: "#71D7D9",
  cyan: "#1AAAAD",
  cyanDark: "#137F82",

  cyanText: "#076568",

  yellowLight: "#FECF2F",
  yellowDark: "#D6AE28",

  orangeText: "#FFA53F",
  //grays
  grayText: "#373E45",
  textTable: "#4A4A4A",
  gray3: "#647180",
  grayDisabled: "#B6C5D5",
  grayBackgroudElement: "#DEE8F3",
  grayBackgroud: "#F0F5FB",
  white: "#FFFFFF",

  //DRAWER
  drawerText: "##43444C",

  //background
  background: "#F0F5FB",
  //background
  backgroundHover: "#dee3ed",

  //Card inputs
  cardInputBanda: "#E4F4F9",
  cardInputChip: "#E8F9F0",
  cardInputCtls: "#FBE5F4",
  cardInputManual: "#FDFAE4",

  //div border
  borderDiv: "#C3CCDD",

  //apps_installed:
  publishedBkg: "#ABD1F9",
  published: "#004288",
  outdatedBkg: "#F4E1AE",
  outdated: "#4A3704 ",
  pilotBkg: "#BFF2F5",
  pilot: "#075052",
  notInformedBkg: "#CFB8F4",
  notInformed: "#472086",
  semverErrorBkg: "#FFC0CB",
  semverError: "#FF0000",

  enabledChip: "#C6F7E9",
  disabledChip: "#D0D0D0"
};