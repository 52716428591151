import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SkeletonHome from "components/skeletonHome";
import AlertCustom from "components/alert";
import TerminalsTable from "./terminalsTable/HomePage";
import useStyles from "./HomeStyles";
import TerminalService from "services/terminal.service";
import mixpanel from "mixpanel-browser";
import { useWindowSize } from "hooks/useWindowSize";
import UpdateDataContext from "context/update/UpdateDataContext";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useKeycloak } from "@react-keycloak/web";

const Home = () => {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const [loadingData, setLoadingData] = useState({
    showLoadingAlert: false,
    errorCodeAlert: "",
    showLoadingSkeleton: true,
  });

  const { setUpdateData, updateData } = useContext(UpdateDataContext);
  const { drawerOpen } = useContext(OpenDrawerContext);

  const fetchTerminals = async (pagePosition: number) => {
    try {
      const res = await TerminalService.terminal_list(pagePosition);
      if (res.status === 200) {
        localStorage.setItem("terminals", JSON.stringify(res.data));
        setUpdateData(true);
        setLoadingData({ ...loadingData, showLoadingAlert: false });
      }
    } catch (error: any) {
      console.log(error?.response?.status.toString());
      console.log(error?.message.toString());
      setLoadingData({
        showLoadingAlert: true,
        showLoadingSkeleton: false,
        errorCodeAlert: error?.response?.status || error?.message,
      });
    }
  };

  useEffect(() => {
    localStorage.removeItem("terminals");
    localStorage.removeItem("terminals_diagnostics");
    localStorage.removeItem("audit_log");
    localStorage.setItem("terminals", JSON.stringify({}));
    setUpdateData(true);
    setLoadingData({ ...loadingData, showLoadingAlert: false });
  }, []);

  const keycloak = useKeycloak();

  useEffect(() => {
    if (updateData) {
      setLoadingData({
        showLoadingAlert: false,
        showLoadingSkeleton: false,
        errorCodeAlert: "",
      });
      setUpdateData(false);
    }
  }, [updateData]);

  useEffect(() => {
    if (loadingData.showLoadingAlert && loadingData.errorCodeAlert) {
      mixpanel.track("sc_diagnostico_error", {
        Error: loadingData.errorCodeAlert,
        Screen: "Diagnostics - Error Screen",
      });
    }
  }, [loadingData.errorCodeAlert]);

  const handleClickAlert = () => {
    //mixpanel
    mixpanel.track("retry_button", {
      Screen: "Diagnostics - Terminals List",
    });
    fetchTerminals(1);
  };

  return (
    <Grid
      className={styles.root}
      style={{
        paddingLeft: drawerOpen
          ? windowSize.width < 1200
            ? "4rem"
            : "16rem"
          : "4rem",
      }}
    >
      <Grid>
        {loadingData.showLoadingSkeleton ? (
          <SkeletonHome />
        ) : loadingData.showLoadingAlert ? (
          <AlertCustom
            message_error={loadingData.errorCodeAlert}
            message_error_description={loadingData.errorCodeAlert}
            onClick2={handleClickAlert}
          />
        ) : (
          <TerminalsTable />
        )}
      </Grid>
    </Grid>
  );
};

export default Home;
