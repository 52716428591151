import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: "1.375rem",
      paddingLeft: "1.375rem",
      paddingRight: ".8rem",
      paddingBottom: ".5rem",
      boxShadow: "none",
      background: colors.background,
    },
  })
);

export default useStyles;
