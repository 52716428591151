import React, { useReducer } from "react";
import UpdateDataReducer from "./UpdateDataReducer";
import UpdateDataContext from "./UpdateDataContext";

const UpdateDataState = (props) => {
  //check local storage
  const initialState = {
    updateData: false,
  };

  const [state, dispatch] = useReducer(UpdateDataReducer, initialState);

  const setUpdateData = async (data) => {
    dispatch({
      type: "SET_UPDATE_DATA",
      payload: data,
    });
  };

  return (
    <UpdateDataContext.Provider
      value={{ updateData: state.updateData, setUpdateData }}
    >
      {props.children}
    </UpdateDataContext.Provider>
  );
};

export default UpdateDataState;
