import { useState } from "react";
import colors from "constants/colors";
import useStyles from "../TerminalsTableStyles";
import { Card, Grid } from "@mui/material/";
import Typography from "@mui/material/Typography";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
} from "chart.js";
import { Doughnut, PolarArea } from "react-chartjs-2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { compareVersions } from "compare-versions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const Metrics = (props: any) => {
  const styles: any = useStyles();

  const [value, setValue] = useState("Diagnóstico");

  const handleOptionSelect = (event: any, newValue: any) => {
    setValue(newValue);
    // setOpen(false);
  };

  const sortedTerminals = props.metrics.terminals.models.sort(function (
    a: any,
    b: any
  ) {
    return b.totalModel - a.totalModel;
  });

  const installedTerminals: any = [];

  sortedTerminals.map((t: any) => {
    t.totalModel >= 50
      ? installedTerminals.push({
          model: t.manufacturer + " " + t.modelName,
          value: t.totalModel,
        })
      : null;
  });

  let installedTotal = 0;
  sortedTerminals.forEach((t: any) => {
    installedTotal = installedTotal + t.totalModel;
  });

  const appList = props.metrics.appVersions.filter(
    (app: any, i: number) =>
      props.metrics.appVersions.findIndex(
        (a: any) => a.appName === app.appName
      ) === i
  );

  const sortedAppList = appList.map((element: any) => {
    return element.appName;
  });

  const connectionTypes: any = {
    labels: [],
    datasets: [
      {
        label: "Terminales",
        data: [],
        backgroundColor: [
          colors.publishedBkg,
          colors.notInformedBkg,
          colors.pilotBkg,
          colors.outdatedBkg,
          colors.semverErrorBkg,
        ],
      },
    ],
  };

  let sumTotals = 0;

  props.metrics.connectionTypes.forEach((com: any, i: number) => {
    if (com.description === "None" || com.description === "") {
      sumTotals = sumTotals + com.totals;
    } else {
      connectionTypes.labels.push(`${com.description} - ${com.totals}`),
        connectionTypes.datasets[0].data.push(com.totals);
    }
  });

  connectionTypes.labels.push(`No informado - ${sumTotals}`);
  connectionTypes.datasets[0].data.push(sumTotals);

  const networkOperators: any = {
    labels: [],
    datasets: [
      {
        label: "Terminales",
        data: [],
        backgroundColor: [
          colors.publishedBkg,
          colors.notInformedBkg,
          colors.pilotBkg,
          colors.outdatedBkg,
          colors.semverErrorBkg,
        ],
      },
    ],
  };

  props.metrics.networkOperators.forEach((op: any) => {
    const opType = op.description === "" ? "No informado" : op.description;
    networkOperators.labels.push(`${opType} - ${op.totals}`),
      networkOperators.datasets[0].data.push(op.totals);
  });

  const selectedApp = value ? value : "Diagnóstico";

  const filteredApps = props.metrics.appVersions.filter(
    (app: any) => !app.versionNumber.includes("SNAPSHOT")
  );

  const sortedAppValues = filteredApps.sort((a: any, b: any) => {
    return compareVersions(b.versionNumber, a.versionNumber);
  });

  interface Data {
    id: number;
    version: string;
    terminals: string;
  }
  function createData(id: number, version: string, terminals: string): Data {
    return { id, version, terminals };
  }

  let rows: Data[] = [];

  sortedAppValues.filter((app: any, n: number) => {
    app.appName === selectedApp
      ? rows.push(createData(n, `${app.versionNumber}`, app.totales))
      : null;
  });

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1AAAAD" : "#308fe8",
    },
  }));

  let dynamicMaxValueAnytime =
    props.metrics.terminals.diagnosticsAnyTime[0].total +
    props.metrics.terminals.diagnosticsAnyTime[1].total;
  let dynamicMaxValueLastWeek =
    props.metrics.terminals.diagnosticsLastDays[0].total +
    props.metrics.terminals.diagnosticsLastDays[1].total;

  let dynamicMaxValueGPS =
    props.metrics.locations.done + props.metrics.locations.notDone;

  return (
    <Paper
      style={{
        overflow: "auto",
        minWidth: 600,
        boxShadow: "none",
      }}
    >
      <div style={{ width: "94rem" }}>
        <div style={{ width: "94rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={4} xl={4}>
              <Card className={styles.cardContent}>
                <Grid
                  className={styles.gridInternal}
                  style={{ padding: "1rem" }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      marginBottom: "2em",
                    }}
                  >
                    <Typography className={styles.textTittle}>
                      Terminales instaladas
                    </Typography>
                    <Typography
                      className={styles.textSub}
                      style={{ padding: 0 }}
                    >
                      Fecha de último barrido:{" "}
                      {props.metrics.terminals.processDate}
                    </Typography>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={12}>
                      <Card className={styles.cardMetrics}>
                        <div
                          className={styles.textSub}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                          }}
                        >
                          <Typography className={styles.textTittleSM}>
                            Parque instalado: {installedTotal}
                          </Typography>
                          <ul className={styles.modelList}>
                            {installedTerminals.map((m: any, i: number) => {
                              return (
                                <li key={i}>
                                  {m.model} - {m.value}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Card>
                      <Grid item xs={12} xl={12}>
                        <Card className={styles.cardMetrics}>
                          <Typography className={styles.textTittleSM}>
                            Envío de diagnóstico última semana:
                          </Typography>
                          <div className={styles.textSub}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                (props.metrics.terminals.diagnosticsLastDays[0]
                                  .total *
                                  100) /
                                dynamicMaxValueLastWeek
                              }
                            />
                            <div style={{ paddingTop: "0.5em" }}>
                              {`${(
                                (props.metrics.terminals.diagnosticsLastDays[0]
                                  .total *
                                  100) /
                                dynamicMaxValueLastWeek
                              ).toFixed(2)}% - ${
                                props.metrics.terminals.diagnosticsLastDays[0]
                                  .total
                              } de ${dynamicMaxValueLastWeek} terminales`}
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item xs={6} xl={6}>
                          <Card className={styles.cardMetrics}>
                            <Typography className={styles.textTittleSM}>
                              Envío de diagnóstico histórico:
                            </Typography>
                            <div className={styles.textSub}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={
                                  (props.metrics.terminals.diagnosticsAnyTime[0]
                                    .total *
                                    100) /
                                  dynamicMaxValueAnytime
                                }
                              />
                              <div style={{ paddingTop: "0.5em" }}>
                                {`${(
                                  (props.metrics.terminals.diagnosticsAnyTime[0]
                                    .total *
                                    100) /
                                  dynamicMaxValueAnytime
                                ).toFixed(2)}% - ${
                                  props.metrics.terminals.diagnosticsAnyTime[0]
                                    .total
                                } de ${dynamicMaxValueAnytime} terminales`}
                              </div>
                            </div>
                          </Card>
                        </Grid>
                        <Grid item xs={6} xl={6}>
                          <Card className={styles.cardMetrics}>
                            <Typography className={styles.textTittleSM}>
                              Envío de geolocalización:
                            </Typography>
                            <div className={styles.textSub}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={
                                  (props.metrics.locations.done * 100) /
                                  dynamicMaxValueGPS
                                }
                              />
                              <div style={{ paddingTop: "0.5em" }}>
                                {`${(
                                  (props.metrics.locations.done * 100) /
                                  dynamicMaxValueGPS
                                ).toFixed(2)}% - ${
                                  props.metrics.locations.done
                                } de ${dynamicMaxValueGPS} terminales`}
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={3} xl={3}>
              <Card className={styles.cardContent}>
                <Grid className={styles.gridInternal}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={12}>
                      <Typography
                        className={styles.textTittle}
                        style={{ margin: "0.5em" }}
                      >
                        Métodos de conexión
                      </Typography>
                      <div className={styles.chartContent}>
                        <div>
                          <Doughnut
                            data={connectionTypes}
                            height={240}
                            width={340}
                            options={{
                              responsive: false,
                              maintainAspectRatio: true,
                              layout: {
                                padding: 0,
                              },
                              plugins: {
                                legend: {
                                  position: "bottom",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={12}>
                      <Typography
                        className={styles.textTittle}
                        style={{ margin: "0.5em" }}
                      >
                        Operadoras
                      </Typography>
                      <div className={styles.chartContent}>
                        <div>
                          <Doughnut
                            data={networkOperators}
                            height={240}
                            width={310}
                            options={{
                              responsive: false,
                              maintainAspectRatio: true,
                              layout: {
                                padding: 0,
                              },
                              plugins: {
                                legend: {
                                  position: "bottom",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={5} xl={5}>
              <Card className={styles.cardContent}>
                <Grid
                  className={styles.gridInternal}
                  style={{ padding: "1rem", minHeight: 1000 }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      marginBottom: ".5em",
                    }}
                  >
                    <Typography className={styles.textTittle}>
                      Versiones instaladas
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={sortedAppList}
                      value={value}
                      onChange={handleOptionSelect}
                      sx={{
                        width: "50%",
                        height: 3,
                        marginBottom: "4em",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seleccioná la app"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                  <Paper
                    style={{
                      maxHeight: 360,
                      overflow: "auto",
                      width: "100%",
                      paddingBottom: "10em",
                      boxShadow: "none",
                      background: colors.background,
                    }}
                  >
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Versión</TableCell>
                          <TableCell>Terminales</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.version}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.terminals}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </Paper>
  );
};

export default Metrics;
