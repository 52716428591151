/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { useContext } from "react";
import clsx from "clsx";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import colors from "constants/colors";
//Context
import TabsContext from "context/pages/home/diagnostics/TabsContext";

const styles = (theme: Theme) =>
({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px",
    color: colors.grayText,
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      ...(theme.direction === "rtl" && {
        paddingLeft: "0 !important",
      }),
      ...(theme.direction !== "rtl" && {
        paddingRight: undefined,
      }),
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: colors.backgroundHover,
      maxWidth: "98.5%",
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
} as const);

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width?: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: readonly ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

const versionStatusStyles: any = {
  0: {
    label: "Desactualizada",
    background: colors.outdatedBkg,
    color: colors.outdated,
  },
  1: {
    label: "Publicada",
    background: colors.publishedBkg,
    color: colors.published,
  },
  2: { label: "Piloto", background: colors.pilotBkg, color: colors.pilot },
  3: {
    label: "No Informada",
    background: colors.notInformedBkg,
    color: colors.notInformed,
  },
  4: {
    label: "Error en Semver",
    background: colors.semverErrorBkg,
    color: colors.semverError,
  },
};

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 30,
    rowHeight: 55,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{
          height: rowHeight,
        }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        <div
          style={{
            width: 200,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {cellData}
        </div>
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span
          style={
            label === "App instalada"
              ? {
                fontSize: "1.25rem",
                fontFamily: "Rubik",
                fontWeight: 500,
                lineHeight: "1.75rem",
              }
              : { fontFamily: "Rubik", fontSize: ".875rem", fontWeight: 500 }
          }
        >
          {label}
        </span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            stickyHeader
            aria-label="sticky table"
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                  //new responsive enable
                  width={width}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(
  MuiVirtualizedTable
);
// ---
interface Data {
  id: number;
  name: string;
  version: string;
  status: any;
}
function createData(
  id: number,
  name: string,
  version: string,
  status: any
  //packageName: string,
): Data {
  return { id, name, version, status };
}

const ReactVirtualizedTable = ({ apps }: any) => {
  const rows: Data[] = [];
  //Context
  const { setUpdateTab } = useContext(TabsContext);

  if (!!apps.matched) {
    for (let i = 0; i < apps.matched.length; i += 1) {
      rows.push(
        createData(
          i,
          apps.matched[i]?.name,
          apps.matched[i]?.version,
          <Chip
            label={versionStatusStyles[apps.matched[i]?.status].label}
            style={{
              width: "141px",
              fontSize: "14px",
              borderRadius: "5px",
              backgroundColor:
                versionStatusStyles[apps.matched[i]?.status].background,
              color: versionStatusStyles[apps.matched[i]?.status].color,
            }}
          />
          //apps_installed[i]?.packageName,
        )
      );
    }
  }

  return (
    <Paper
      style={{
        height: 430,
        width: "100%",
        boxShadow: "none",
        background: colors.background,
      }}
    >
      {!!apps.installed && (
        <VirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          columns={[
            {
              //width: 250,
              label: "Aplicación",
              dataKey: "name",
            },
            {
              //width: 280,
              label: "Version",
              dataKey: "version",
            },
            {
              //width: 280,
              label: "Estado",
              dataKey: "status",
            },
          ]}
        />
      )}
    </Paper>
  );
};
export default React.memo(ReactVirtualizedTable);
