import React, { useEffect, useContext, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { ReactComponent as NotLatLon } from "assets/svg/not_lat_lon.svg";
import useStyles from "../styles";
import terminalService from "services/terminal.service";
import {
  Collapse,
  Divider,
  IconButton,
  Paper,
  SnackbarContent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReactJson from "react-json-view";

interface Response {
  data: any;
  message: string;
  status: number;
}
interface BusinessConfig {
  application_code: string;
  business_config_id: number;
  business_description: string;
  business_property: string;
  business_value: any;
}

const ParamCell = ({ title, data }: { title: string; data: any }) => {
  const [open, setOpen] = useState(false);

  const isJson = (item : any) => {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;    
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
    
  return typeof value === "object" && value !== null;
  }

  return (
    <>
      <TableRow onClick={() => setOpen(!!!open)}>
        <TableCell>{title}</TableCell>
        <TableCell sx={{ justifyContent: "flex-end", display: "flex" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!!!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ maxWidth: "90vw" }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TableCell sx={{ width: "90vw" }}>
            <TableContainer component={Paper}>
              <Table
                sx={{ maxWidth: "90vw" }}
                size="small"
                aria-label="Properties"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Property</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data as Array<BusinessConfig>).map((row) => (
                    <TableRow
                      key={row.business_config_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        {row.business_property}
                      </TableCell>
                      <TableCell align="left">
                        {row.business_description}
                      </TableCell>
                      <TableCell align="left">
                        {
                          isJson(row.business_value) ? ( 

                            <>
                              <ReactJson 
                                src={row.business_value} 
                                name={ null } 
                                enableClipboard={ false } 
                                groupArraysAfterLength={ 5 }
                                collapseStringsAfterLength={ 25 } 
                                collapsed={true}
                                displayDataTypes={false}
                              />
                            </>
                          ) : (
                            row.business_value
                          )
                        }
                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableCell>
        </Collapse>
      </TableRow>
    </>
  );
};

const Parameters = (props: any) => {
  const styles = useStyles();

  const [businessConfig, setBusinessConfig] = useState<any>();
  const [enabledPrepaid, setEnabledPrepaid] = useState<BusinessConfig[]>();
  const [installmentsCalc, setInstallmentsCalc] = useState<BusinessConfig[]>();

  const callServiceGetConfig = async () => {
    const { data: resp }: { data: Response } =
      await terminalService.business_config_get();

    console.log(resp.data)

    if (resp.status === 200) {
      setBusinessConfig(resp?.data);
    }
  };

  useEffect(() => {
    callServiceGetConfig();
  }, []);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ padding: "1rem" }}
              >
                <TableHead></TableHead>
                <TableBody sx={{ maxWidth: "90vw" }}>
                  {businessConfig &&
                    Object.entries(businessConfig).map(([key, value]) => (
                      <ParamCell key={key} title={key} data={value} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Parameters;
