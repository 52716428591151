import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    TableRow: {
      "&:hover": {
        backgroundColor: colors.backgroundHover,
      },
    },
    root: {
      paddingTop: "1.3rem",
      paddingLeft: "1.375rem",
      paddingRight: ".8rem",
      paddingBottom: ".5rem",
      boxShadow: "none",
      background: colors.background,
    },
    cardInternal: {
      width: "100%",
      height: "6rem",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      margin: "auto",
      paddingTop: ".5em",
    },
    cardTable: {
      width: "100%",
      minHeight: "41.2rem",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      margin: "auto",
    },
    gridInternal: {
      paddingTop: "1rem",
      paddingLeft: "1.5rem",
      paddingRight: "1rem",
      paddingBottom: "2.5rem",
    },
    div: {
      marginTop: ".875rem",
      marginBottom: ".875rem",
      borderTop: `1px solid ${colors.borderDiv}`,
    },
    textHeaderSub: {
      paddingBottom: "1rem",
      color: colors.primary,
    },
    leftIconButton: {
      color: colors.primary,
    },
    rightIconButton: {
      color: colors.primary,
    },
    buttomFilter: {
      background: colors.primary,
      color: colors.white,
      borderRadius: "1.125rem",
      width: "6.125rem",
      height: "2.25rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.225rem",
      marginLeft: "1.125rem",
      paddingRight: "0.313rem",
      paddingLeft: "0.313rem",
      "&:hover": {
        backgroundColor: colors.primarydark,
      },
      "&:active": {
        backgroundColor: colors.cyan,
      },
    },
    filterAlign: {
      display: "flex",
      height: "100px",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default useStyles;
