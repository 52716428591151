import {
  SET_UPDATE_FILTER,
  SET_UPDATE_DATA_TRANSACTION,
  SET_UPDATE_TRANSACTION_SKELETON,
  SET_UPDATE_TRANSACTION_ALERT,
  SET_UPDATE_TRANSACTION_ALERT_CODE
} from "context/types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_UPDATE_FILTER:
      return {
        ...state,
        filterTransaction: payload,
      };
    case SET_UPDATE_DATA_TRANSACTION:
      return {
        ...state,
        dataTransactions: payload,
      };
    case SET_UPDATE_TRANSACTION_SKELETON:
      return {
        ...state,
        transactionSkeleton: payload,
      };
    case SET_UPDATE_TRANSACTION_ALERT:
      return {
        ...state,
        alertErrorTransactions: payload,
      };
    case SET_UPDATE_TRANSACTION_ALERT_CODE:
      return {
        ...state,
        errorCodeAlertTransactions: payload,
      };
    default:
      return state;
  }
};
