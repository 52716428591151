import useStyles from "../ResumenStyles";
import { Grid } from "@mui/material/";
import ErrorBoundary from "components/errorBoundary";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import QrDataMerchantData from "./QrDataMerchantData";

interface Props {
  branch: string;
  checkout: string;
  terminalNumber: string;
  cbu: string;
  fieldOfActivity: string;
  paymentMethodData: any;
  fechaAlta: string;
}

const QrData = ({
  branch,
  checkout,
  terminalNumber,
  cbu,
  fieldOfActivity,
  paymentMethodData,
  fechaAlta,
}: Props) => {
  const styles: any = useStyles();

  return (
    <Paper
      style={{
        overflow: "auto",
        //minWidth: 600,
        width: "100%",
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={styles.textTittle}
              style={{
                paddingTop: ".5em",
                paddingLeft: ".5em",
                paddingBottom: "1.5em",
              }}
            >
              Información QR
            </Typography>
            <ErrorBoundary>
              <div className={styles.QRDataContainer}>
                <Typography className={styles.textSubBold}>
                  Sucursal / Branch
                </Typography>
                <Typography className={styles.textSub}>{branch}</Typography>
              </div>
              <div className={styles.div} style={{ width: "85%" }}></div>
              <div className={styles.QRDataContainer}>
                <Typography className={styles.textSubBold}>
                  Caja / Checkout
                </Typography>
                <Typography className={styles.textSub}>{checkout}</Typography>
              </div>
              <div className={styles.div} style={{ width: "85%" }}></div>
              <div className={styles.QRDataContainer}>
                <Typography className={styles.textSubBold}>Terminal</Typography>
                <Typography className={styles.textSub}>
                  {terminalNumber}
                </Typography>
              </div>
              <div className={styles.div} style={{ width: "85%" }}></div>
              <div className={styles.QRDataContainer}>
                <Typography className={styles.textSubBold}>CBU</Typography>
                <div className={styles.textSub}>{cbu}</div>
              </div>
              <div className={styles.div} style={{ width: "85%" }}></div>
              <div className={styles.QRDataContainer}>
                <Typography className={styles.textSubBold}>Rubro</Typography>
                <Typography className={styles.textSub}>
                  {fieldOfActivity}
                </Typography>
              </div>
              <div className={styles.div} style={{ width: "85%" }}></div>
              <div className={styles.QRDataContainer}>
                <Typography className={styles.textSubBold}>
                  Fecha de alta
                </Typography>
                <Typography className={styles.textSub}>{fechaAlta}</Typography>
              </div>
            </ErrorBoundary>
          </Grid>
          <Grid item xs={1}>
            <div className={styles.divider}>
              <br></br>
            </div>
          </Grid>
          <Grid item xs={5}>
            <Typography
              className={styles.textTittle}
              style={{
                paddingTop: ".5em",
                paddingLeft: ".5em",
                paddingBottom: "1.5em",
              }}
            >
              Establecimientos QR Habilitados
            </Typography>
            <QrDataMerchantData establishments={paymentMethodData || []} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default QrData;
