export default {
  token: {
    required: {
      value: true,
      message: "El campo Token es requerido",
    },
  },
  email: {
    required: {
      value: true,
      message: "El campo E-mail es requerido",
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-z0-9_]{2,16}$/i,
      message: "El formato de tu E-mail es incorrecto",
    },
  }
};
