import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import colors from "constants/colors";
import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
//Context
import TabsContext from "context/pages/home/diagnostics/TabsContext";
import { useContext } from "react";
import useStyles from "./Styles";
import { useWindowSize } from "hooks/useWindowSize";
interface Column {
  id: 'name' | 'packageName' | 'version' | 'dataSize' | 'cacheSize' | 'codeSize';
  label: string;
  minWidth?: number;
  align?: 'left';
}

const columns: Column[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 180,
    align: 'left',
  },
  {
    id: 'packageName',
    label: 'PackageName',
    minWidth: 220,
    align: 'left',
  },
  {
    id: 'version',
    label: 'Version',
    minWidth: 285,
    align: 'left',
  },
  {
    id: 'dataSize',
    label: 'DataSize',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'cacheSize',
    label: 'CacheSize',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'codeSize',
    label: 'CodeSize',
    minWidth: 100,
    align: 'left',
  },
];

type Props = {
  state?: any;
};

interface Data {
  name: string;
  packageName: string;
  version: string;
  dataSize: string;
  cacheSize: string;
  codeSize: string;
}
function createData(
  name: string,
  packageName: string,
  version: string,
  dataSize: string,
  cacheSize: string,
  codeSize: string
): Data {
  return { name, packageName, version, dataSize, cacheSize, codeSize };
}

const StickyHeadTable: React.FC<Props> = ({
  state
}) => {
  const styles = useStyles();
  const windowSize = useWindowSize();
  //Context
  const { setUpdateApps, appsInstall } = useContext(TabsContext);

  const rows: Data[] = [];
  const rowsPackageNamePrisma: Data[] = [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateApps(event.target.checked);
    //setChecked(event.target.checked);
  };

  const valuesRemove = [
    "com.qrd",
    "com.newland",
    "com.android",
    "com.qti",
    "com.qualcomm",
    "org.simalliance",
    "com.qapp",
    "org.codeaurora",
    "com.qcom",
    "oms.drmservice",
    "com.qti",
    "com.google",
    "com.svox",
    "com.dsi",
    "com.wdstechnology",
    "com.abc",
  ];

  if (!!state) {
    for (let i = 0; i < Object.values(state).length; i += 1) {
      rows.push(
        createData(
          state[i].name,
          state[i].packageName,
          state[i].version,
          state[i].dataSize,
          state[i].cacheSize,
          state[i].codeSize
        )
      );
    }
    for (let i = 0; i < state.length; i += 1) {
      //filter packageName != prisma
      const found = valuesRemove.find(
        (element) => !state[i]?.packageName.indexOf(element)
      );
      if (!found) {
        rowsPackageNamePrisma.push(
          createData(
            state[i].name,
            state[i].packageName,
            state[i].version,
            state[i].dataSize,
            state[i].cacheSize,
            state[i].codeSize
          )
        );
      }
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: ".3rem",
        }}
      >
        <Checkbox
          checked={appsInstall}
          onChange={handleChange}
          sx={{
            color: colors.primary,
            "&.Mui-checked": {
              color: colors.primary,
            },
          }}
        />
        <Typography style={{ color: colors.grayText }}>
          Sólo Apps de Prisma
        </Typography>
      </div>
      <Paper sx={{
        width: '100%',
        height: '100%',
        background: colors.background,
        boxShadow: "none",
        overflowY: "hidden",
        overflowX: "auto",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        boxSizing: "border-box",
        paddingBottom: ".15rem"
      }}>
        {!!state && (<>
          <TableContainer sx={{ maxHeight: '100%', }}>
            <Table stickyHeader aria-label="sticky table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={uuidv4()}
                      align={column.align}
                      style={{
                        background: column.label === "Name" ? colors.background : "transparent",
                        minWidth: column.minWidth,
                        position: column.label === "Name" ? "sticky" : "static",
                        left: 0,
                        borderTop: windowSize.width < 1519 ? `1px solid ${colors.borderDiv}` : "",
                        borderRight: windowSize.width < 1519 && column.label === "Name" ? `1px solid ${colors.borderDiv}` : "",
                        borderBottom: `1px solid ${colors.borderDiv}`,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(appsInstall ? rowsPackageNamePrisma : rows)
                  .map((row) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={uuidv4()} className={styles.TableRow}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={uuidv4()} align={column.align}
                              style={{
                                background: windowSize.width < 1519 ? colors.background : "transparent",
                                position: column.label === "Name" ? "sticky" : "static",
                                left: 0,
                                borderRight: windowSize.width < 1519 && column.label === "Name" ? `1px solid ${colors.borderDiv}` : "",
                                borderBottom: `1px solid ${colors.borderDiv}`,
                              }}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>)}
      </Paper>
    </>
  );
}
export default StickyHeadTable;