import Button from "@mui/material/Button";
import colors from "constants/colors";
import { ReactComponent as SuccessIcon } from "assets/svg/buttonIcon/successIcon.svg";
import { ReactComponent as WarningIcon } from "assets/svg/buttonIcon/warningIcon.svg";
import { ReactComponent as Warning_Icon } from "assets/svg/buttonIcon/warning_icon.svg";
import { ReactComponent as ErrorIcon } from "assets/svg/buttonIcon/errorIcon.svg";

type Props = {
  tittle?: string;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customStyle?: object;
};

const Buttom: React.FC<Props> = ({ tittle, icon, onClick, customStyle }) => {
  return (
    <Button
      color="inherit"
      style={{
        color: colors.white,
        borderRadius: "1.75rem",
        width: "8.5625rem",
        height: "2.25rem",
        textTransform: "none",
        fontFamily: "Rubik",
        fontSize: ".875rem",
        fontWeight: 400,
        lineHeight: "1.225rem",
        justifyContent: "space-between",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        ...customStyle,
      }}
      onClick={onClick}
      endIcon={
        (icon === "Saludable" && <SuccessIcon />) ||
        (icon === "Alerta" && <WarningIcon />) ||
        (icon === "Alerta2" && <Warning_Icon />) ||
        (icon === "Critico" && <ErrorIcon />)
      }
    >
      {tittle}
    </Button>
  );
};

export default Buttom;
