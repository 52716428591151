import React, { useEffect, useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Button,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import colors from "constants/colors";
import { ReactComponent as ArrowDownIcon } from "assets/svg/down_icon.svg";
//Context
import RoleContext from "context/userRole/RoleContext";
import RenderOnRole from "helpers/RenderOnRole";
import { makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles({
  //selectDropdown: { color: "#fff", backgroundColor: "#1b1f38" },
  /*  menuItem: {
         "&:hover": {
             backgroundColor: "#3b3f58"
         },
     }, */
  leftIconButton: {
    color: colors.primary,
  },
  rightIconButton: {
    color: colors.primary,
  },
});

import MixPanelService from "services/mixpanel.service";
interface Column {
  id:
  | "intent_date"
  | "qr_id"
  | "intent_type"
  | "value"
  | "installments"
  | "status"
  | "status_date"
  | "response_message"
  | "ticket_number"
  | "allow_benefit"
  | "is_transfer";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}
//object
const objectValues: any = {
  intent_date: "Fecha de intención",
  qr_id: "ID QR",
  intent_type: "Tipo de intención",
  value: "Monto",
  installments: "Cuotas",
  status: "Estado",
  status_date: "Fecha de estado",
  response_message: "Respuesta",
  ticket_number: "Número de ticket",
  allow_benefit: "Beneficios",
  is_transfer: "Transferencia",
};

const columns: readonly Column[] = [
  { id: "intent_date", label: objectValues["intent_date"], minWidth: 130 },
  { id: "qr_id", label: objectValues["qr_id"], minWidth: 80 },
  { id: "intent_type", label: objectValues["intent_type"], minWidth: 100 },
  { id: "value", label: objectValues["value"], minWidth: 100 },
  { id: "installments", label: objectValues["installments"], minWidth: 80 },
  { id: "status", label: objectValues["status"], minWidth: 100 },
  { id: "status_date", label: objectValues["status_date"], minWidth: 130 },
  {
    id: "response_message",
    label: objectValues["response_message"],
    minWidth: 100,
  },
  {
    id: "ticket_number",
    label: objectValues["ticket_number"],
    minWidth: 100,
  },
  {
    id: "allow_benefit",
    label: objectValues["allow_benefit"],
    minWidth: 100,
  },
  { id: "is_transfer", label: objectValues["is_transfer"], minWidth: 100 },
];

interface Data {
  intent_date: string;
  qr_id: string;
  intent_type: string;
  value: string;
  installments: string;
  status: string;
  status_date: string;
  response_message: string;
  ticket_number: string;
  allow_benefit: string;
  is_transfer: string;
}

function createData(
  intent_date: string,
  qr_id: string,
  intent_type: string,
  value: string,
  installments: string,
  status: string,
  status_date: string,
  response_message: string,
  ticket_number: string,
  allow_benefit: string,
  is_transfer: string
): Data {
  return {
    intent_date,
    qr_id,
    intent_type,
    value,
    installments,
    status,
    status_date,
    response_message,
    ticket_number,
    allow_benefit,
    is_transfer,
  };
}

type Props = {
  state?: any;
  selectState?: any;
  fetchPage?: any;
  setFetchPage?: any;
  total_elements: any;
  total_pages: any;
};

const StickyHeadTable = ({
  state,
  fetchPage,
  setFetchPage,
  total_elements = 0,
  total_pages = 0,
}: Props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [openTrxDialog, setOpenTrxDialog] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [currentTrx, setCurrentTrx] = React.useState<any>([]);
  const [rowIndex, setRowIndex] = React.useState<number>(0);

  const TextOnlyTooltip = withStyles({
    arrow: {
      color: "#373E45",
    },
    tooltip: {
      backgroundColor: "#373E45",
      width: "103px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 400,
      "&.MuiTooltip-tooltip": {
        marginBottom: "8px !important",
      },
    },
  })(Tooltip);

  const handleClickOpen = (rid: any) => {
    setOpenTrxDialog(true);
    setRowIndex(rid);
  };

  const handleClose = () => {
    setOpenTrxDialog(false);
    setCurrentTrx([]);
  };

  const copyErrorToClipboard = () => {
    setOpenSnackbar(true);
    navigator.clipboard.writeText(JSON.stringify(currentTrx, null, 2));
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  //Context
  const { role } = useContext(RoleContext);
  const rows: Data[] = [];

  for (let i = 0; i < Object.values(state).length; i += 1) {
    const valueString = state[i].value.toString();
    const resValue =
      valueString.substring(0, valueString.length - 2) +
      "." +
      valueString.substring(valueString.length - 2);

    let status = "";
    switch (state[i].status) {
      case "EXPIRED":
        status = "Expirado"
        break;
      case "APPROVED":
        status = "Aprobado"
        break;
      case "CREATE_ERROR":
        status = "Error en creación"
        break;
      case "REJECTED":
        status = "Rechazado"
        break;
      case "UNRESOLVED":
        status = "No resuelto"
        break;
      default:
        status = state[i].status
        break;
    }

    rows.push(
      createData(
        new Date(state[i].intent_date).toLocaleString(),
        state[i].qr_id,
        state[i].intent_type === "payment"
          ? "Compra"
          : state[i].intent_type === "annulment"
            ? "Anulación"
            : "Devolución",
        "$ " + resValue,
        state[i].installments,
        status,
        new Date(state[i].status_date).toLocaleString(),
        state[i].response_message === "APPROVED" ? "Aprobado" : state[i].response_message,
        state[i].ticket_number,
        state[i].allow_benefit,
        state[i].is_transfer
      )
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (page < newPage) {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "next_page_button_transactions",
        "Diagnostics - Intents tab",
        role
      );
    } else {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "previous_page_button_transactions",
        "Diagnostics - Intents tab",
        role
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "results_per_page_button_transactions",
      "Diagnostics - Intents tab",
      role,
      { Results: +event.target.value }
    );
  };
  const loadPreviousPage = async () => {
    if (fetchPage > 0) {
      setFetchPage(fetchPage - 1);
    }
    MixPanelService.sendDataMixPanel(
      "previous_page_button_transactions",
      "Diagnostics - Intents tab",
      role
    );
  };
  const loadNextPage = async () => {
    setFetchPage(fetchPage + 1);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "next_page_button_transactions",
      "Diagnostics - Intents tab",
      role
    );
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        background: colors.background,
        boxShadow: "none",
        overflowY: "hidden",
        overflowX: "auto",
        boxSizing: "border-box",
      }}
    >
      <TableContainer sx={{ minHeight: "41.2rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "transparent",
                    position: "static",
                    left: 0,
                    borderBottom: `1px solid ${colors.borderDiv}`,
                  }}
                >
                  <Typography
                    style={{
                      color: colors.primary,
                      fontSize: ".875rem",
                      fontWeight: 500,
                      lineHeight: "1.25rem",
                      textTransform: "uppercase",
                      verticalAlign: "top",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rid) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={uuidv4()}>
                    {columns.map((column) => {
                      const value: any = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            background: "transparent",
                            position: "static",
                            left: 0,
                            borderBottom: `1px solid ${colors.borderDiv}`,
                            padding: 6,
                            height: 60,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                color: colors.textTable,
                                fontSize: ".875rem",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {value}
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                    <RenderOnRole roles={["Administrador"]}>
                      <TableCell
                        style={{ width: "3em" }}
                        onClick={() => handleClickOpen(rid)}
                      >
                        <TextOnlyTooltip
                          title={"Datos de transferencia"}
                          arrow
                          placement="bottom"
                        >
                          <IconButton color="info">
                            <InfoOutlinedIcon sx={{ color: "#023F80" }} />
                          </IconButton>
                        </TextOnlyTooltip>
                      </TableCell>
                    </RenderOnRole>
                  </TableRow>
                );
              })}
            <Dialog
              open={openTrxDialog}
              onClose={handleClose}
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                color={colors.primary}
                sx={{
                  margin: 0,
                  paddingTop: "2em",
                  paddingLeft: "2em",
                  fontSize: "20px",
                }}
              >
                {"Datos de intención"}
              </DialogTitle>
              <Divider />
              <DialogContent>
                <pre
                  className="layout__item u-1/2-lap-and-up"
                  style={{ margin: ".5em", lineHeight: 1.8 }}
                >
                  {JSON.stringify(state[rowIndex], null, 2)}
                </pre>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{ width: "50%", margin: "1em", color: colors.primary }}
                >
                  Cerrar
                </Button>
                <Button
                  onClick={copyErrorToClipboard}
                  sx={{ width: "50%", margin: "1em", color: colors.primary }}
                  autoFocus
                >
                  Copiar información
                </Button>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000}
                  onClose={handleCloseSnackbar}
                  message="Copiado al portapapeles"
                  action={action}
                />
              </DialogActions>
            </Dialog>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100 /* , 20, 50, 100 */]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => {
          let total: number = fetchPage * 100;
          let totalNext: number = (fetchPage + 1) * 100;
          return (
            (fetchPage === 0 ? 1 : total + 1) +
            " - " +
            (fetchPage !== 0 && total_elements <= total
              ? total_elements
              : totalNext > total_elements
                ? total_elements
                : totalNext) +
            " de " +
            total_elements
          );
        }}
        labelRowsPerPage={""}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          color: colors.primary,
        }}
        SelectProps={{
          //MenuProps: { classes: { paper: classes.selectDropdown } },
          IconComponent: ArrowDownIcon,
        }}
        //classes={{ menuItem: classes.menuItem }}
        backIconButtonProps={{
          onClick: () => loadPreviousPage(),
          className: classes.leftIconButton,
          disabled: false,
        }}
        nextIconButtonProps={{
          onClick: () => loadNextPage(),
          className: classes.rightIconButton,
          disabled: false,
        }}
      />
    </Paper>
  );
};
export default React.memo(StickyHeadTable);
