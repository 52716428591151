/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import clsx from "clsx";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import Tooltip from "@mui/material/Tooltip";
import ButtomCustom from "components/elements/buttonCustom";
import colors from "constants/colors";
import { Chip } from "@mui/material";
//test Data
//import fakeDateGeneralState from "./fakeDateGeneralState";

const styles = (theme: Theme) =>
({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px",
    color: colors.grayText,
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      ...(theme.direction === "rtl" && {
        paddingLeft: "0 !important",
      }),
      ...(theme.direction !== "rtl" && {
        paddingRight: undefined,
      }),
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
} as const);

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width?: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: readonly ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 65,
    rowHeight: 65,
  };
  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };
  cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    const { /* columns, */ classes, rowHeight, onRowClick } = this.props;
    const dataObject: any = {
      api_status: "Conexión a los servicios",
      chip: "Lectura por chip",
      card_reader: "Lectura por banda magnética",
      nfc: "Lectura por contactless",
      print: "Impresión",
      touch: "Pantalla táctil",
      wifi: "Wifi",
      storage_free: "Almacenamiento",
      memory_free: "Memoria",
      optimum: (
        <Chip
          label="Saludable"
          style={{
            width: "141px",
            fontSize: "14px",
            borderRadius: "5px",
            backgroundColor: "#BFF2F5",
            color: "#075052",
          }}
        />
      ),
      alert: (
        <Chip
          label="En alerta"
          style={{
            width: "141px",
            fontSize: "14px",
            borderRadius: "5px",
            backgroundColor: "#F4E1AE",
            color: "#4A3704",
          }}
        />
      ),
      critical: (
        <Chip
          label="Crítico"
          style={{
            width: "141px",
            fontSize: "14px",
            borderRadius: "5px",
            backgroundColor: "#FDB0B0",
            color: "#921034",
          }}
        />
      ),
    };
    //custom Tooltip
    const TextOnlyTooltip = withStyles({
      arrow: {
        marginLeft: "2.2rem",
        color: "#373E45",
      },
      tooltip: {
        backgroundColor: "#373E45",
        minHeight: "36px",
        minWidth: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    })(Tooltip);
    //object custom Tooltip
    const objectButtom = (data: any) => {
      const status = Object.values(data)[0];
      const description = (Object.values(data)[1] as string) || "";
      const longText = (
        <div
          style={{
            whiteSpace: "pre-line",
            fontSize: "12px",
            fontWeight: "normal",
            letterSpacing: "0.4px",
            lineHeight: "16px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            color: "#FFFFFF",
            margin: "0.5rem",
          }}
        >
          {" "}
          {description}{" "}
        </div>
      );

      if (status === "optimum") {
        return (
          <TextOnlyTooltip
            title={longText}
            arrow
            placement="top"
            disableHoverListener={description.length ? false : true}
          >
            <div>
              <Chip
                label="Saludable"
                style={{
                  width: "130px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#BFF2F5",
                  color: "#075052",
                }}
              />
            </div>
          </TextOnlyTooltip>
        );
      }
      if (status === "alert") {
        return (
          <TextOnlyTooltip
            title={longText}
            arrow
            placement="top"
            disableHoverListener={description.length ? false : true}
          >
            <div>
              <Chip
                label="En alerta"
                style={{
                  width: "130px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#F4E1AE",
                  color: "#4A3704",
                }}
              />
            </div>
          </TextOnlyTooltip>
        );
      }
      if (status === "critical") {
        return (
          <TextOnlyTooltip
            title={longText}
            arrow
            placement="top"
            disableHoverListener={description.length ? false : true}
          >
            <div>
              <Chip
                label="Crítico"
                style={{
                  width: "130px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#FDB0B0",
                  color: "#921034",
                }}
              />
            </div>
          </TextOnlyTooltip>
        );
      }
    };
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          ((typeof cellData === "object" ||
            cellData === "optimum" ||
            cellData === "alert" ||
            cellData === "critical") &&
            "right") ||
          "left"
        }
      >
        {(typeof cellData === "number" && (
          <ButtomCustom
            tittle={
              cellData >= 80
                ? "Saludable"
                : cellData < 80 && cellData > 20
                  ? "Alerta"
                  : "Crítico"
            }
            icon={
              cellData >= 80
                ? "Saludable"
                : cellData < 80 && cellData > 20
                  ? "Alerta2"
                  : "Critico"
            }
            customStyle={{
              backgroundColor:
                cellData >= 80
                  ? colors.cyan
                  : cellData < 80 && cellData > 20
                    ? colors.yellowDark
                    : colors.magenta,
              width: "9rem",
            }}
          />
        )) ||
          dataObject[cellData] ||
          (typeof cellData === "object" && objectButtom(cellData))}
      </TableCell>
    );
  };
  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={0}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                  //new responsive enable
                  width={width * 0.49}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(
  MuiVirtualizedTable
);

// ---
interface Data {
  id: number;
  key: any;
  value: any;
}

function createData(id: number, key: any, value: any): Data {
  return { id, key, value };
}

export default function ReactVirtualizedTable({ gemeral_state }: any) {
  const rows: Data[] = [];
  if (!!gemeral_state) {
    for (let i = 0; i < Object.values(gemeral_state).length; i += 1) {
      if (
        Object.keys(gemeral_state)[i] !== "percentage" &&
        Object.keys(gemeral_state)[i] !== "memory_free" &&
        Object.keys(gemeral_state)[i] !== "storage_free"
      ) {
        rows.push(
          createData(
            i,
            Object.keys(gemeral_state)[i],
            Object.values(gemeral_state)[i]
          )
        );
      }
    }
  }

  rows.forEach((row: any) => {
    switch (row.key) {
      case "api_status":
        row.value.status === "alert"
          ? (row.value.description =
            "Algún servicio de Payway puede estar afectado.\n\n Pedile al comercio que realice una prueba guiada con la app de Diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Pedile al comercio que realice una prueba guiada con la app de Diagnóstico.\n\nSi el problema persiste, reportalo a un superior.")
            : (row.value.description = "Funciona correctamente.");
        break;
      case "chip":
        row.value.status === "alert"
          ? (row.value.description =
            "Podría haber inconvenientes con el lector del chip.\n\nVerificá si el comercio presenta inconvenientes físicos con la lectura de tarjetas emv (seguí el procedimiento en GPS).\n\nPedile al comercio que realice una prueba guiada con la app de diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Verificá si el comercio presenta incovenientes físicos con lectura de emv (seguí el procedimiento en GPS).")
            : (row.value.description =
              "La terminal no presenta fallas en lectura de emv.");
        break;
      case "card_reader":
        row.value.status === "alert"
          ? (row.value.description =
            "Podría haber inconvenientes con el lector de banda magnética.\n\nVerificá si el comercio presenta inconvenientes físicos con la lectura de tarjetas por banda magnética (seguí el procedimiento en GPS).\n\nPedile que realice una prueba guiada con la app de diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Verificá si el comercio presenta incovenientes físicos con lectura de banda magnética (seguí el procedimiento en GPS).")
            : (row.value.description =
              "La terminal no presenta fallas en lectura de banda magnética.");
        break;
      case "nfc":
        row.value.status === "alert"
          ? (row.value.description =
            " Podría haber inconvenientes con el lector del contactless.\n\nVerificá si el comercio presenta inconvenientes con la lectura de tarjetas por contactless (seguí el procedimiento en GPS).\n\nPedile que realice una prueba guiada con la app de diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Verificá si el comercio presenta incovenientes físicos con lectura de contactless (seguí el procedimiento en GPS).")
            : (row.value.description =
              "La terminal no presenta fallas en lectura de contactless.");
        break;
      case "print":
        row.value.status === "alert"
          ? (row.value.description =
            "Verificá si el comercio presenta fallas de impresión.\n\nPedile que realice una prueba guiada con la app de diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Verificá si presenta inconvenientes de impresión y seguí el procedimiento de GPS.")
            : (row.value.description = "La terminal no presenta fallas de impresión.");
        break;
      case "touch":
        row.value.status === "alert"
          ? (row.value.description =
            "Verificá si el comercio  presenta inconvenientes en el táctil.\n\nSeguí el procedimiento de GPS y pedile que realice una prueba guiada con la app diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Verificá si presenta inconvenientes en el táctil y seguí el procedimiento de GPS.")
            : (row.value.description = "La terminal no presenta fallas en la pantalla táctil.");
        break;
      case "wifi":
        row.value.status === "alert"
          ? (row.value.description =
            "Verificá si el comercio presenta fallas en la conexión con wifi, seguí el procedimiento de GPS y pedile que realice una prueba guiada con la app diagnóstico.")
          : row.value.status === "critical"
            ? (row.value.description =
              "Verificá si presenta inconvenientes de comunicación y seguí el procedimiento de GPS")
            : (row.value.description = "La terminal no presenta fallas en la conexión con wifi.");
        break;
    }
  });

  return (
    <Paper
      style={{
        height: 540,
        width: "100%",
        boxShadow: "none",
        background: colors.background,
        paddingLeft: ".5em",
      }}
    >
      {/* <button onClick={handleClick}>Throw Error</button> */}
      {!!gemeral_state && (
        <VirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          columns={[
            {
              //width: newWindowSize * 0.598,
              label: "",
              dataKey: "key",
            },
            {
              //width: newWindowSize * 0.29,
              label: "",
              dataKey: "value",
            },
          ]}
        />
      )}
    </Paper>
  );
}
