//takeLatest: toma el ultimo estado
import { takeLatest, call, put/* , delay */ } from "redux-saga/effects";
import * as types from "../../auth/type";
// action
import { showLoading, hideLoading } from "redux/src/actions";
//import { setUserData } from "redux/auth/actions";
//keyCloack
import keycloak from "configs/Keycloak";

function* login() {
    try {
        //const { history } = action.payload;
        yield put(showLoading());

        yield call(keycloak.login());
        //history.push("/home");
        //
        yield put(hideLoading());
    } catch (error) {
        console.log("Login Failed");
    } finally {
        yield put(hideLoading());
    }
}

export function* watchLogin() {
    yield takeLatest(types.LOGIN, login);
}
