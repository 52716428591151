//takeLatest: toma el ultimo estado
import { takeLatest, call, put /* delay */ } from "redux-saga/effects";

import * as types from "../../home/type";
// services
import TerminalsServices from "../../../services/terminal.service";

// action
import { showLoading, hideLoading, showLoadingAlert } from "../../src/actions";
import { setTerminalData } from "../../home/actions";

export function* fetchTerminals() {
  try {
    yield put(showLoading());
    //llamada api
    const response = yield call(TerminalsServices.terminal_list);
    yield put(setTerminalData(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(showLoadingAlert());
  } finally {
    yield put(hideLoading());
  }
}

export function* watchFetchTerminals() {
  yield takeLatest(types.GET_TERMINALS_DATA, fetchTerminals);
}
