import React, { useReducer } from "react";
import FilterTransactionReducer from "./FilterTransactionReducer";
import FilterTransactionContext from "./FilterTransactionContext";

const UpdateDataState = (props) => {
  //check local storage
  const initialState = {
    filterTransaction: false,
    dataTransactions: null,
    transactionSkeleton: false,
    alertErrorTransactions: false,
    errorCodeAlertTransactions: null,
  };

  const [state, dispatch] = useReducer(FilterTransactionReducer, initialState);

  const setUpdateFilter = async (data) => {
    dispatch({
      type: "SET_UPDATE_FILTER",
      payload: data,
    });
  };

  const setDataTransactions = async (data) => {
    dispatch({
      type: "SET_UPDATE_DATA_TRANSACTION",
      payload: data,
    });
  };

  const setLoadingTransactionsSkeleton = async (data) => {
    dispatch({
      type: "SET_UPDATE_TRANSACTION_SKELETON",
      payload: data,
    });
  };

  const setAlertErrorTransactions = async (data) => {
    dispatch({
      type: "SET_UPDATE_TRANSACTION_ALERT",
      payload: data,
    });
  };
  const setErrorCodeAlertTransactions = async (data) => {
    dispatch({
      type: "SET_UPDATE_TRANSACTION_ALERT_CODE",
      payload: data,
    });
  };

  return (
    <FilterTransactionContext.Provider
      value={{
        filterTransaction: state.filterTransaction,
        dataTransactions: state.dataTransactions,
        transactionSkeleton: state.transactionSkeleton,
        alertErrorTransactions: state.alertErrorTransactions,
        errorCodeAlertTransactions: state.errorCodeAlertTransactions,
        setUpdateFilter, setDataTransactions, setLoadingTransactionsSkeleton,
        setAlertErrorTransactions, setErrorCodeAlertTransactions
      }}
    >
      {props.children}
    </FilterTransactionContext.Provider>
  );
};

export default UpdateDataState;
