//las acciones consisas y tienen que espresar la intencion de hacer algo
import * as types from "./type";

export const getTerminalData = () => ({
  type: types.GET_TERMINALS_DATA,
  payload: {},
});

export const setTerminalData = (data) => ({
  type: types.SET_TERMINALS_DATA,
  payload: { data },
});
