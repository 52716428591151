import React from "react";
import Skeleton from "@mui/material/Skeleton";
import colors from "constants/colors";
import Grid from "@mui/material/Grid";

const SkeletonHome = () => {
  return (
    <Grid container spacing={2} width="99%" style={{ padding: "1rem" }}>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          style={{
            background: colors.background,
            borderRadius: "10px",
          }}
          width={"100%"}
          height={202}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          style={{
            background: colors.background,
            borderRadius: "10px",
          }}
          width={"100%"}
          height={574}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          style={{
            background: colors.background,
            borderRadius: "10px",
          }}
          width={"100%"}
          height={89}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(SkeletonHome);
