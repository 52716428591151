import React, { useEffect, useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { format /* , sub */, parseISO } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import colors from "constants/colors";
import cardsNameAux from "../../cardsNameAux";
import { ReactComponent as ArrowDownIcon } from "assets/svg/down_icon.svg";
import { ReactComponent as DefaultCard } from "assets/svg/default_card.svg";
//Context
import RoleContext from "context/userRole/RoleContext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  //selectDropdown: { color: "#fff", backgroundColor: "#1b1f38" },
  /*  menuItem: {
         "&:hover": {
             backgroundColor: "#3b3f58"
         },
     }, */
  leftIconButton: {
    color: colors.primary,
  },
  rightIconButton: {
    color: colors.primary,
  },
});

import MixPanelService from "services/mixpanel.service";
interface Column {
  id:
  | "payment_date"
  | "ticket_number"
  | "product_name"
  | "parcels"
  | "value"
  | "additional_amount"
  | "original_value"
  | "refunded_value"
  | "brand"
  | "status"
  | "authorization_number"
  | "response_code"
  | "response_message"
  | "card_input_method"
  | "card_number"
  | "card_holder"
  | "requested_password"
  | "has_signature"
  | "batch_number"
  | "wallet_id";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}
//object
const objectValues: any = {
  payment_date: "Fecha de pago",
  ticket_number: "Número de Cupón",
  product_name: "Tipo de producto",
  parcels: "Cuotas",
  value: "Monto",
  additional_amount: "Retiro de efectivo",
  original_value: "Monto original",
  refunded_value: "Monto devuelto",
  brand: "Marca de la tarjeta",
  status: "Estado",
  authorization_number: "Número de autorización",
  response_code: "Código de respuesta",
  response_message: "Mensaje de respuesta",
  card_input_method: "Entrada de la tarjeta",
  card_number: "Número de tarjeta",
  card_holder: "Nombre del titular",
  requested_password: "Captura de PIN",
  has_signature: "Firma digital",
  batch_number: "Número de lote",
  wallet_id: "Billetera",
};

const columns: readonly Column[] = [
  { id: "payment_date", label: objectValues["payment_date"], minWidth: 190 },
  { id: "ticket_number", label: objectValues["ticket_number"], minWidth: 100 },
  { id: "product_name", label: objectValues["product_name"], minWidth: 100 },
  { id: "parcels", label: objectValues["parcels"], minWidth: 100 },
  { id: "value", label: objectValues["value"], minWidth: 100 },
  {
    id: "additional_amount",
    label: objectValues["additional_amount"],
    minWidth: 100,
  },
  {
    id: "original_value",
    label: objectValues["original_value"],
    minWidth: 100,
  },
  {
    id: "refunded_value",
    label: objectValues["refunded_value"],
    minWidth: 100,
  },
  { id: "brand", label: objectValues["brand"], minWidth: 160 },
  { id: "status", label: objectValues["status"], minWidth: 100 },
  {
    id: "authorization_number",
    label: objectValues["authorization_number"],
    minWidth: 100,
  },
  { id: "response_code", label: objectValues["response_code"], minWidth: 100 },
  {
    id: "response_message",
    label: objectValues["response_message"],
    minWidth: 100,
  },
  {
    id: "card_input_method",
    label: objectValues["card_input_method"],
    minWidth: 100,
  },
  { id: "card_number", label: objectValues["card_number"], minWidth: 160 },
  { id: "card_holder", label: objectValues["card_holder"], minWidth: 160 },
  {
    id: "requested_password",
    label: objectValues["requested_password"],
    minWidth: 100,
  },
  { id: "has_signature", label: objectValues["has_signature"], minWidth: 100 },
  { id: "batch_number", label: objectValues["batch_number"], minWidth: 100 },
  { id: "wallet_id", label: objectValues["wallet_id"], minWidth: 100 },
];

interface Data {
  payment_date: string;
  ticket_number: string;
  product_name: string;
  parcels: string;
  value: string;
  additional_amount: string;
  original_value: string;
  refunded_value: string;
  brand: string;
  status: string;
  authorization_number: string;
  response_code: string;
  response_message: string;
  card_input_method: string;
  card_number: string;
  card_holder: string;
  requested_password: string;
  has_signature: string;
  batch_number: string;
  wallet_id: number;
}

function createData(
  payment_date: string,
  ticket_number: string,
  product_name: string,
  parcels: string,
  value: string,
  additional_amount: string,
  original_value: string,
  refunded_value: string,
  brand: string,
  status: string,
  authorization_number: string,
  response_code: string,
  response_message: string,
  card_input_method: string,
  card_number: string,
  card_holder: string,
  requested_password: string,
  has_signature: string,
  batch_number: string,
  wallet_id: number
): Data {
  return {
    payment_date,
    ticket_number,
    product_name,
    parcels,
    value,
    additional_amount,
    original_value,
    refunded_value,
    brand,
    status,
    authorization_number,
    response_code,
    response_message,
    card_input_method,
    card_number,
    card_holder,
    requested_password,
    has_signature,
    batch_number,
    wallet_id,
  };
}

type Props = {
  state?: any;
  selectState?: any;
  fetchPage?: any;
  setFetchPage?: any;
  total_elements: any;
  total_pages: any;
};

const estado: any = {
  0: "PENDIENTE",
  1: "CONFIRMADA",
  2: "REVERSADA",
  3: "RECHAZADA",
};

const inputMethod: any = {
  "01": "MANUAL",
  "02": "BANDA",
  "03": "CHIP",
  "04": "CONTACTLESS",
  "05": "QR",
};

const isBoolean: any = {
  "00": "NO",
  "01": "SI",
};

const wallet: any = {
  1: "TODO PAGO",
  2: "BIMO",
  3: "MODO",
  4: "CUENTA DNI",
  5: "BNA",
};

const transactionType: any = {
  Devolução: "Devolución",
  Cancelamento: "Anulación",
};

const StickyHeadTable = ({
  state,
  fetchPage,
  setFetchPage,
  total_elements = 0,
  total_pages = 0,
}: Props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  //Context
  const { role } = useContext(RoleContext);
  const rows: Data[] = [];

  for (let i = 0; i < Object.values(state).length; i += 1) {
    rows.push(
      createData(
        state[i].payment_date,
        state[i].ticket_number,
        transactionType[state[i].product_name]
          ? transactionType[state[i].product_name]
          : state[i].product_name,
        state[i].parcels,
        state[i].value,
        state[i].additional_amount,
        state[i].original_value,
        state[i].refunded_value,
        state[i].brand,
        estado[state[i].status],
        state[i].authorization_number,
        state[i].response_code,
        state[i].response_message,
        inputMethod[state[i].card_input_method],
        state[i].card_number,
        state[i].card_holder,
        isBoolean[state[i].requested_password],
        state[i].has_signature,
        state[i].batch_number,
        wallet[state[i].wallet_id]
      )
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (page < newPage) {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "next_page_button_transactions",
        "Diagnostics - Diagnostico de terminal",
        role
      );
    } else {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "previous_page_button_transactions",
        "Diagnostics - Diagnostico de terminal",
        role
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "results_per_page_button_transactions",
      "Diagnostics - Transactions Tab",
      role,
      { Results: +event.target.value }
    );
  };
  const loadPreviousPage = async () => {
    if (fetchPage > 0) {
      setFetchPage(fetchPage - 1);
    }
    MixPanelService.sendDataMixPanel(
      "previous_page_button_transactions",
      "Diagnostics - Transactions Tab",
      role
    );
  };
  const loadNextPage = async () => {
    setFetchPage(fetchPage + 1);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "next_page_button_transactions",
      "Diagnostics - Transactions Tab",
      role
    );
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        background: colors.background,
        boxShadow: "none",
        overflowY: "hidden",
        overflowX: "auto",
        boxSizing: "border-box",
      }}
    >
      <TableContainer sx={{ minHeight: "41.2rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background:
                      column.label === "Fecha de pago"
                        ? colors.background
                        : "transparent",
                    position:
                      column.label === "Fecha de pago" ? "sticky" : "static",
                    borderRight:
                      column.label === "Fecha de pago"
                        ? `1px solid ${colors.borderDiv}`
                        : "",
                    left: 0,
                    borderBottom: `1px solid ${colors.borderDiv}`,
                  }}
                >
                  <Typography
                    style={{
                      color: colors.primary,
                      fontSize: ".875rem",
                      fontWeight: 500,
                      lineHeight: "1.25rem",
                      textTransform: "uppercase",
                      verticalAlign: "top",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={uuidv4()}>
                    {columns.map((column) => {
                      const value: any = row[column.id];
                      let elemento;
                      if (column.id === "brand") {
                        elemento = cardsNameAux?.filter(
                          (element) => element.Nombre === value.toUpperCase() && element
                        );
                      }
                      let PublicUrlCards = `/cards/svg/card_default.svg`;
                      //armar url de cards
                      if (elemento) {
                        if (
                          elemento[0]?.svg != null &&
                          elemento[0]?.svg !== "default"
                        ) {
                          PublicUrlCards = `/cards/svg/${elemento[0].svg}.svg`;
                        } else if (
                          elemento[0]?.png != null &&
                          elemento[0]?.png !== "default"
                        ) {
                          PublicUrlCards = `/cards/png/${elemento[0].png}.png`;
                        }
                      }
                      //check additional_amount cuando devuelve 0
                      let value_amount = "";
                      if (
                        column.id === "value" ||
                        column.id === "additional_amount" ||
                        column.id === "original_value" ||
                        column.id === "refunded_value"
                      ) {
                        if (value.toString().length >= 2) {
                          //service two finish decimal ,00
                          value_amount =
                            "$" +
                            new Intl.NumberFormat("de-DE").format(
                              value.toString().slice(0, -2)
                            ) +
                            "," +
                            value
                              .toString()
                              .slice(
                                value.toString().length - 2,
                                value.toString().length
                              );
                        } else {
                          value_amount = "$" + value.toString();
                        }
                      }
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            background:
                              column.label === "Fecha de pago"
                                ? colors.background
                                : "transparent",
                            position:
                              column.label === "Fecha de pago"
                                ? "sticky"
                                : "static",
                            left: 0,
                            borderRight:
                              column.label === "Fecha de pago"
                                ? `1px solid ${colors.borderDiv}`
                                : "",
                            borderBottom: `1px solid ${colors.borderDiv}`,
                            padding: 6,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {elemento &&
                              PublicUrlCards !== `/cards/svg/card_default.svg` ? (
                              <img
                                src={PublicUrlCards}
                                width={"50px"}
                                height={"43px"}
                                alt="card"
                                style={{
                                  paddingRight: "0.5rem",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              column.id === "brand" && (
                                <DefaultCard
                                  style={{
                                    paddingRight: "1.2rem",
                                    width: "50px",
                                    height: "43px",
                                    objectFit: "contain",
                                  }}
                                />
                              )
                            )}

                            <Typography
                              style={{
                                color: colors.textTable,
                                fontSize: ".875rem",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {column.id === "payment_date"
                                ? format(
                                  parseISO(value),
                                  "dd/MM/yyyy - HH:mm:ss"
                                )
                                : column.id === "value" ||
                                  column.id === "additional_amount" ||
                                  column.id === "original_value" ||
                                  column.id === "refunded_value"
                                  ? value_amount
                                  : value}
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100 /* , 20, 50, 100 */]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => {
          let total: number = fetchPage * 20;
          let totalNext: number = (fetchPage + 1) * 20;
          return (
            (fetchPage === 0 ? 1 : total + 1) +
            " - " +
            (fetchPage !== 0 && total_elements <= total
              ? total_elements
              : totalNext > total_elements
                ? total_elements
                : totalNext) +
            " de " +
            total_elements
          );
        }}
        labelRowsPerPage={""}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          color: colors.primary,
        }}
        SelectProps={{
          //MenuProps: { classes: { paper: classes.selectDropdown } },
          IconComponent: ArrowDownIcon,
        }}
        //classes={{ menuItem: classes.menuItem }}
        backIconButtonProps={{
          onClick: () => loadPreviousPage(),
          className: classes.leftIconButton,
          disabled: fetchPage <= 1 ? true : false,
        }}
        nextIconButtonProps={{
          onClick: () => loadNextPage(),
          className: classes.rightIconButton,
          disabled: fetchPage + 1 >= total_pages ? true : false,
        }}
      />
    </Paper>
  );
};
export default React.memo(StickyHeadTable);
