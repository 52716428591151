import keycloak from "configs/Keycloak";
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  keycloak.init({ onLoad: "login-required", "checkLoginIframe": false })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        //doLogin()
        console.log("user is not authenticated..!");
      }
    })
    .catch(console.error);
};
/**
 * Login
 */
const doLogin = keycloak.login;
/**
 * Logout
 */
const doLogout = keycloak.logout;
/**
 * getToken
 * @returns token
 */
const getToken = () => keycloak.token;
/**
 * isLoggedIn
 * @returns isLoginIn true / false
 */
const isLoggedIn = () => !!keycloak.token;
/**
 * updateToken
 * @param {*} successCallback
 * @returns catch Login
 */
const updateToken = (successCallback) =>
  keycloak.updateToken(50)
    .then(successCallback/* , console.log("updateToken servicio", successCallback) */)
    .catch(doLogin);

const onTokenExpired = () =>
  keycloak.onTokenExpired = () => {
    keycloak.updateToken(50).success((refreshed) => {
      if (refreshed) {
        console.log('refreshed ' + new Date());
      } else {
        console.log('not refreshed ' + new Date());
      }
    }).error(() => {
      console.error('Failed to refresh token ' + new Date());
    });
  }

const getUsername = () => keycloak.tokenParsed?.preferred_username;
/**
 * hasRole
 * @param {[string]} roles
 * @returns role true / false
 */
const hasRole = (roles) => roles.some((role) => keycloak?.hasResourceRole(role));

const KeyCloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  onTokenExpired,
  getUsername,
  hasRole,
};

export default KeyCloakService;