export default [
  {
    id: 0,
    Nombre: "VISA",
    svg: "visa",
    png: "visa",
  },
  {
    id: 1,
    Nombre: "VISA DEBITO",
    svg: "visa_debito",
    png: "visa_debito",
  },
  {
    id: 2,
    Nombre: "MASTERCARD",
    svg: "mastercard",
    png: "mastercard",
  },
  {
    id: 3,
    Nombre: "MASTERCARD DEBIT",
    svg: "mastercard_debit",
    png: "mastercard_debit",
  },
  {
    id: 4,
    Nombre: "MAESTRO",
    svg: "maestro",
    png: "maestro",
  },
  {
    id: 5,
    Nombre: "AMEX PRISMA",
    svg: "amex_prisma",
    png: "amex_prisma",
  },
  {
    id: 7,
    Nombre: "BANCAT",
    svg: "bancat",
    png: "bancat",
  },
  {
    id: 8,
    Nombre: "CREDITO ARGENTINO",
    svg: "credito_argentino",
    png: "credito_argentino",
  },
  {
    id: 9,
    Nombre: "ITALCRED",
    svg: "default",
    png: "italcred",
  },
  {
    id: 10,
    Nombre: "PLATINO",
    svg: "default",
    png: "platino",
  },
  {
    id: 11,
    Nombre: "CONFIABLE",
    svg: "default",
    png: "confiable",
  },
  {
    id: 12,
    Nombre: "TARJETA NARANJA",
    svg: "naranja",
    png: "naranja",
  },
  {
    id: 14,
    Nombre: "PYME NACION",
    svg: "default",
    png: "pyme_nacion",
  },
  {
    id: 15,
    Nombre: "MILENIA",
    svg: "default",
    png: "milenia",
  },
  {
    id: 16,
    Nombre: "UNIRED",
    svg: "default",
    png: "unired",
  },
  {
    id: 17,
    Nombre: "NATIVA",
    svg: "default",
    png: "nativa",
  },
  {
    id: 18,
    Nombre: "NATIVA-MC",
    svg: "default",
    png: null,
  },
  {
    id: 19,
    Nombre: "BANCOR",
    svg: "default",
    png: "bancor",
  },
  {
    id: 20,
    Nombre: "CENTROCARD",
    svg: "default",
    png: "centrocard",
  },
  {
    id: 21,
    Nombre: "CREDICASH",
    svg: "default",
    png: "credicash",
  },
  {
    id: 22,
    Nombre: "CREDIFE",
    svg: "default",
    png: "credife",
  },
  {
    id: 23,
    Nombre: "CREDIQUEN",
    svg: "default",
    png: "crediquen",
  },
  {
    id: 24,
    Nombre: "CONSUMAX",
    svg: "default",
    png: "consumax",
  },
  {
    id: 25,
    Nombre: "MUTUAL",
    svg: "default",
    png: null,
  },
  {
    id: 26,
    Nombre: "FERTIL",
    svg: "default",
    png: "fertil",
  },
  {
    id: 27,
    Nombre: "SOY TIGRE",
    svg: "default",
    png: "soy_tigre",
  },
  {
    id: 28,
    Nombre: "IRED",
    svg: "default",
    png: "ired",
  },
  {
    id: 29,
    Nombre: "CLARIN",
    svg: "default",
    png: "clarin",
  },
  {
    id: 30,
    Nombre: "CLUB LA NACION",
    svg: "default",
    png: "club_la_nacion",
  },
  {
    id: 31,
    Nombre: "CUPONSTAR",
    svg: "default",
    png: "cuponstar",
  },
  {
    id: 32,
    Nombre: "GIFT CARD",
    svg: "default",
    png: null,
  },
  {
    id: 33,
    Nombre: "WISH GIFT",
    svg: "default",
    png: "wish_gift",
  },
  {
    id: 34,
    Nombre: "CABAL",
    svg: "cabal",
    png: "cabal",
  },
  {
    id: 35,
    Nombre: "CABAL DEBITO",
    svg: "cabal_debito",
    png: "cabal_debito",
  },
  {
    id: 36,
    Nombre: "CTC GROUP",
    svg: "default",
    png: "ctc_group",
  },
  {
    id: 37,
    Nombre: "SOY SOLAR",
    svg: "default",
    png: "soy_solar",
  },
  {
    id: 38,
    Nombre: "SOY RECOLETA",
    svg: "default",
    png: "soy_recoleta",
  },
  {
    id: 39,
    Nombre: "FINANYA",
    svg: "default",
    png: "finanya",
  },
  {
    id: 40,
    Nombre: "BBPS",
    svg: "default",
    png: "bbps",
  },
  {
    id: 41,
    Nombre: "MONTEMAR",
    svg: "default",
    png: null,
  },
  {
    id: 42,
    Nombre: "OH! GIFT CARD",
    svg: "default",
    png: null,
  },
  {
    id: 43,
    Nombre: "ADANCARD",
    svg: "default",
    png: null,
  },
  {
    id: 44,
    Nombre: "COOPEPLUS",
    svg: "default",
    png: "coopeplus",
  },
  {
    id: 45,
    Nombre: "TARJETA SOL",
    svg: "default",
    png: null,
  },
  {
    id: 46,
    Nombre: "CREDENCIAL",
    svg: "default",
    png: null,
  },
  {
    id: 47,
    Nombre: "PATAGONIA365",
    svg: "default",
    png: null,
  },
  {
    id: 48,
    Nombre: "TUYA",
    svg: "default",
    png: null,
  },
  {
    id: 49,
    Nombre: "CF SA",
    svg: "default",
    png: null,
  },
  {
    id: 50,
    Nombre: "Carrefour",
    svg: "default",
    png: null,
  },
  {
    id: 51,
    Nombre: "La Voz",
    svg: "default",
    png: null,
  },
  {
    id: 52,
    Nombre: "Los Andes",
    svg: "default",
    png: null,
  },
  {
    id: 53,
    Nombre: "CrediGuia",
    svg: "default",
    png: null,
  },
  {
    id: 54,
    Nombre: "CLUBET",
    svg: "default",
    png: null,
  },
  {
    id: 55,
    Nombre: "RIO NEGRO",
    svg: "default",
    png: null,
  },
  {
    id: 56,
    Nombre: "JERARQUICOS",
    svg: "default",
    png: null,
  },
  {
    id: 57,
    Nombre: "CLUB LA GACETA",
    svg: "default",
    png: null,
  },
  {
    id: 58,
    Nombre: "BQB CLUB",
    svg: "default",
    png: null,
  },
  {
    id: 59,
    Nombre: "T EL DIARIO",
    svg: "default",
    png: null,
  },
  {
    id: 60,
    Nombre: "TARJETA APLA",
    svg: "default",
    png: null,
  },
  {
    id: 61,
    Nombre: "MUTUAL TAIS",
    svg: "default",
    png: "mutual_tais",
  },
  {
    id: 62,
    Nombre: "RTASGLOBAL",
    svg: "default",
    png: null,
  },
  {
    id: 63,
    Nombre: "SOY NORTE",
    svg: "default",
    png: null,
  },
  {
    id: 64,
    Nombre: "SUTEBA",
    svg: "default",
    png: null,
  },
  {
    id: 65,
    Nombre: "HSBCbenefit",
    svg: "default",
    png: null,
  },
  {
    id: 66,
    Nombre: "APSA S.A.",
    svg: "default",
    png: null,
  },
  {
    id: 67,
    Nombre: "C.REGALO",
    svg: "default",
    png: null,
  },
  {
    id: 68,
    Nombre: "T.CIUDADANA",
    svg: "default",
    png: null,
  },
  {
    id: 69,
    Nombre: "CLUB BENEFICIOS",
    svg: "default",
    png: null,
  },
  {
    id: 70,
    Nombre: "MOVISTAR",
    svg: "default",
    png: null,
  },
  {
    id: 71,
    Nombre: "BENEFICIOS",
    svg: "default",
    png: null,
  },
  {
    id: 72,
    Nombre: "FAVACARD",
    svg: "default",
    png: null,
  },
  {
    id: 73,
    Nombre: "VYCARD",
    svg: "default",
    png: null,
  },
  {
    id: 74,
    Nombre: "UNICA",
    svg: "default",
    png: null,
  },
  {
    id: 75,
    Nombre: "VIP",
    svg: "default",
    png: null,
  },
  {
    id: 76,
    Nombre: "3M",
    svg: "default",
    png: null,
  },
  {
    id: 77,
    Nombre: "SU CREDITO",
    svg: "default",
    png: null,
  },
  {
    id: 78,
    Nombre: "CREDIARIO",
    svg: "default",
    png: null,
  },
  {
    id: 79,
    Nombre: "QUIERO",
    svg: "default",
    png: null,
  },
  {
    id: 80,
    Nombre: "BBVA CUPON",
    svg: "bbva",
    png: null,
  },
  {
    id: 81,
    Nombre: "CODB",
    svg: "default",
    png: null,
  },
  {
    id: 82,
    Nombre: "Gestor RPA",
    svg: "default",
    png: null,
  },
  {
    id: 83,
    Nombre: "DONCREDITO",
    svg: "default",
    png: null,
  },
  {
    id: 84,
    Nombre: "TARJ LOCAL",
    svg: "default",
    png: null,
  },
  {
    id: 85,
    Nombre: "FULLCARD",
    svg: "default",
    png: null,
  },
  {
    id: 86,
    Nombre: "CRED.MOVIL",
    svg: "default",
    png: null,
  },
  {
    id: 87,
    Nombre: "EFECTIVA MAX",
    svg: "default",
    png: null,
  },
  {
    id: 88,
    Nombre: "FARO",
    svg: "default",
    png: null,
  },
  {
    id: 89,
    Nombre: "HUILEN",
    svg: "default",
    png: null,
  },
  {
    id: 90,
    Nombre: "GRUPAR",
    svg: "default",
    png: null,
  },
  {
    id: 91,
    Nombre: "LA ZONAL",
    svg: "default",
    png: null,
  },
  {
    id: 92,
    Nombre: "SAN JUAN +",
    svg: "default",
    png: null,
  },
  {
    id: 93,
    Nombre: "OPENCARD",
    svg: "default",
    png: null,
  },
  {
    id: 94,
    Nombre: "TERRICLUB",
    svg: "default",
    png: null,
  },
  {
    id: 95,
    Nombre: "NEWRED",
    svg: "default",
    png: null,
  },
  {
    id: 96,
    Nombre: "CARDRED",
    svg: "default",
    png: null,
  },
  {
    id: 97,
    Nombre: "T VERDE",
    svg: "default",
    png: null,
  },
  {
    id: 98,
    Nombre: "DATA2000",
    svg: "default",
    png: null,
  },
  {
    id: 99,
    Nombre: "ELEBAR",
    svg: "default",
    png: null,
  },
];
