//import Button from "@mui/material/Button";
import colors from "constants/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ReactComponent as QuestIcon } from "assets/svg/quest_icon.svg";

type Props = {
  tittle?: string;
  subTittle?: string;
  customStyle?: object;
  customStyleContent?: object;
};

const InfoDanger: React.FC<Props> = ({
  tittle,
  subTittle,
  customStyle,
  customStyleContent,
}) => {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "12.5rem",
        ...customStyleContent,
      }}
    >
      <QuestIcon />
      <Typography
        style={{
          paddingTop: "2rem",
          fontFamily: "Rubik",
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "1.875rem",
          color: colors.magenta,
          ...customStyle,
        }}
      >
        {tittle}
      </Typography>
      <Typography
        style={{
          paddingTop: "1rem",
          fontFamily: "Rubik",
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "1.875rem",
          color: colors.magenta,
          ...customStyle,
        }}
      >
        {subTittle}
      </Typography>
    </Grid>
  );
};

export default InfoDanger;
