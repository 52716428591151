import * as React from "react";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TerminalService from "services/terminal.service";
import {
  Switch,
  Chip,
  Stack,
  Card,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  Collapse,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import colors from "constants/colors";
import { v4 as uuidv4 } from "uuid";
import useStyles from "./styles";
import InfoDanger from "components/elements/infoDanger";
import cardsNameAux from "../cardsNameAux";
import { ReactComponent as DefaultCard } from "assets/svg/default_card.svg";
import DialogCustom from "components/dialogCustom/DialogCustom";
import mixpanel from "mixpanel-browser";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
interface Column {
  id:
    | "productId"
    | "productName"
    | "multiMerchant"
    | "enabled"
    | "productType"
    | "pinCapture"
    | "inputMethods";
  label: string;
  minWidth?: number;
  align?: "left";
}

const columns: Column[] = [
  {
    id: "productId",
    label: "Id",
    minWidth: 10,
    align: "left",
  },
  {
    id: "productName",
    label: "Nombre",
    minWidth: 160,
    align: "left",
  },
  {
    id: "multiMerchant",
    label: "",
    minWidth: 160,
    align: "left",
  },
  {
    id: "enabled",
    label: "Estado",
    minWidth: 10,
    align: "left",
  },
  {
    id: "productType",
    label: "Tipo",
    minWidth: 20,
    align: "left",
  },
  {
    id: "pinCapture",
    label: "Solicita Pin",
    minWidth: 20,
    align: "left",
  },
  {
    id: "inputMethods",
    label: "Modo de entrada",
    minWidth: 60,
    align: "left",
  },
];

interface Data {
  productId: string;
  productName: string;
  enabled: boolean;
  productType: string;
  pinCapture: string;
  inputMethods: Array<number>;
  multiMerchant: Array<any>;
  openData: boolean;
}
function createData(
  productId: string,
  productName: string,
  enabled: boolean,
  productType: string,
  pinCapture: string,
  inputMethods: Array<number>,
  multiMerchant: Array<AnalyserOptions>
): Data {
  return {
    productId,
    productName,
    enabled,
    productType,
    pinCapture,
    inputMethods,
    multiMerchant,
    openData: false,
  };
}

type Props = {
  state?: any;
  tittle?: string;
  merchant?: string;
  terminal?: string;
  enabledCardsCounter: number;
  multiMerchantGroup: any[];
  enabledMultiMerchant: boolean;
};

type RowProp = {
  row: Data;
  cardCounter: number;
  handlePrevent: any;
  handleClickOpen: any;
  enabledMultiMerchant: boolean;
};

export const CardSettingRow: React.FC<RowProp> = ({
  row,
  cardCounter,
  handlePrevent,
  handleClickOpen,
  enabledMultiMerchant
}) => {
  const styles = useStyles();
  // console.log(row)
  const [open, setOpen] = useState(false);

  const inputMethodStyles: any = {
    1: { label: "Manual", color: colors.cardInputManual },
    2: { label: "Banda", color: colors.cardInputBanda },
    3: { label: "Chip", color: colors.cardInputChip },
    4: { label: "Contactless", color: colors.cardInputCtls },
  };

  const switchTheme = createTheme({
    palette: {
      primary: {
        main: colors.cyanDark,
      },
      secondary: {
        main: colors.cyanLight,
      },
    },
  });

  return (
    <>
      <TableRow
        role="checkbox"
        tabIndex={-1}
        key={uuidv4()}
        className={styles.TableRow}
      >
        {columns.map((column) => {
          const value = row[column.id];
          let elemento;
          if (column.id === "productName") {
            elemento = cardsNameAux?.filter(
              (element) => element.Nombre === value
            );
          }
          let PublicUrlCards = `/cards/svg/card_default.svg`;
          //armar url de cards
          if (elemento) {
            if (elemento[0]?.svg != null && elemento[0]?.svg !== "default") {
              PublicUrlCards = `/cards/svg/${elemento[0].svg}.svg`;
            } else if (
              elemento[0]?.png != null &&
              elemento[0]?.png !== "default"
            ) {
              PublicUrlCards = `/cards/png/${elemento[0].png}.png`;
            }
          }
          return (
            <TableCell
              key={uuidv4()}
              align={column.align}
              style={{
                background: colors.background,
                position: column.label === "Id" ? "sticky" : "static",
                borderBottom: `1px solid ${colors.borderDiv}`,
                padding: "6s",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {elemento &&
                PublicUrlCards !== `/cards/svg/card_default.svg` &&
                column.id === "productName" ? (
                  <img
                    src={PublicUrlCards}
                    width={"50px"}
                    height={"43px"}
                    alt="card"
                    style={{
                      paddingRight: "1.2rem",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  column.id === "productName" && (
                    <DefaultCard
                      style={{
                        paddingRight: "1.2rem",
                        width: "50px",
                        height: "43px",
                        objectFit: "contain",
                      }}
                    />
                  )
                )}
                {column.id === "enabled" ? (
                  <ThemeProvider theme={switchTheme}>
                    <Switch
                      key={row.productId}
                      checked={row.enabled}
                      onChange={
                        cardCounter === 1 && row.enabled
                          ? handlePrevent
                          : handleClickOpen
                      }
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                      name={row.productId}
                      // disabled={!EnableOnRole(['Administrador'])}
                    />
                  </ThemeProvider>
                ) : null}
                {column.id === "multiMerchant" && enabledMultiMerchant  ? (
                  row.multiMerchant.length > 0 &&
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                ) : null}
                {column.id === "inputMethods" ? (
                  <Stack direction="row" spacing={1}>
                    {row.inputMethods.map((i: number) =>
                      i !== 5 ? (
                        <Chip
                          key={i}
                          label={inputMethodStyles[i].label}
                          style={{
                            backgroundColor: inputMethodStyles[i].color,
                          }}
                        />
                      ) : null
                    )}
                  </Stack>
                ) : (
                  <Typography
                    style={{
                      color: colors.textTable,
                      fontSize: ".875rem",
                      lineHeight: "1.25rem",
                    }}
                  >
                    {column.id !== "multiMerchant" && row[column.id]}
                  </Typography>
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
      {row.multiMerchant.length > 0 && <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={0} sx={{ padding: '1.5rem 0'}}>
              <Grid item xs={8} pl={6} paddingLeft={10}>

                {row.multiMerchant.map((merchant) => (
                  <Grid container key={uuidv4()} className={styles.configGridContent}>
                    <Grid item xs={6}>{ merchant.name }</Grid>
                    <Grid item xs={6}>Comercio: { merchant.groupCode }</Grid>
                  </Grid>
                ))}              
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>}
    </>
  );
};

const StickyHeadTable: React.FC<Props> = ({
  state,
  tittle,
  merchant,
  terminal,
  enabledCardsCounter,
  multiMerchantGroup,
  enabledMultiMerchant
}) => {
  const styles = useStyles();

  const rows: Data[] = [];

  for (let i = 0; i < Object.values(state).length; i += 1) {
    rows.push(
      createData(
        state[i].productId,
        state[i].productName === "AMERICAN EXPRESS"
          ? "AMEX PRISMA"
          : state[i].productName,
        state[i].enabled,
        state[i].productType === 0 ? "Crédito" : "Débito",
        state[i].pinCapture === 1
          ? "Si"
          : state[i].pinCapture === 0
          ? "No"
          : "Según tarjeta",
        state[i].inputMethods,
        multiMerchantGroup?.filter(
          (group) => group?.productId === state[i]?.productId
        )
      )
    );
  }

  const [cards, setCards] = useState<Data[]>(rows);
  const [currentCard, setCurrentCard] = React.useState<any>({
    id: "0",
    name: "producto",
    status: false,
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [showPrevent, setShowPrevent] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [cardCounter, setCardCounter] = useState<number>(enabledCardsCounter);

  const handleClose = () => {
    setOpen(false);
  };

  const abortSwitch = () => {
    setOpen(false);
  };

  //SWITCH
  async function handleClickOpen(event: React.ChangeEvent<HTMLInputElement>) {
    const cardName = cards.filter(
      (p: Data) => p.productId == event.target.name
    );
    setCurrentCard({
      id: cardName[0].productId,
      name: cardName[0].productName,
      status: event.target.checked,
    });
    mixpanel.track("settings_tab", {
      Screen: "Open dialog - Modify card",
      Card: {
        id: cardName[0].productId,
        name: cardName[0].productName,
        status: !event.target.checked,
      },
    });
    setOpen(true);
  }

  async function handlePrevent(event: React.ChangeEvent<HTMLInputElement>) {
    setShowPrevent(true);
  }

  //Aceptar Button
  async function continueSwitch() {
    try {
      setLoading(true);
      const res = await TerminalService.toggle_products(
        merchant,
        terminal,
        currentCard.id.toString(),
        currentCard.name
      );
      res ? setLoading(false) : null;
      if (res.status === 200) {
        let cardsModified = cards.map((item: Data) =>
          item.productId == currentCard.id
            ? { ...item, enabled: res.data.currentValue }
            : item
        );
        res.data.currentValue
          ? setCardCounter(cardCounter + 1)
          : setCardCounter(cardCounter - 1);
        setCards(cardsModified);
        setLoading(false);
        mixpanel.track("settings_tab", {
          Screen: "Settings - Modify card",
          Modificación: {
            id: currentCard.id.toString(),
            name: currentCard.name,
            status: res.data.currentValue,
          },
        });
      }
    } catch (error: any) {
      console.log(error?.response?.status.toString());
      console.log(error?.message.toString());
    }
    handleClose();
  }

  const description = `Recordá validar la repro en ATER antes de realizar esta modificación.

        ¿Deseas continuar?

      `;

  return (
    <>
      <Paper
        sx={{
          //maxWidth: '875px',
          width: "100%",
          height: "1200px",
          background: colors.background,
          boxShadow: "none",
          overflowY: "hidden",
          overflowX: "hidden",
          paddingLeft: "1.4rem",
          paddingRight: "1rem",
          boxSizing: "border-box",
          paddingBottom: "12rem",
          borderRadius: "8px",
          verticalAlign: "bottom",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid className={styles.headerGrid}>
              <Typography className={styles.textCardSetting}>
                {tittle}
              </Typography>
              <Typography className={styles.textCardSetting2}>
                {Object.values(state).length} tarjetas
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.headerGrid}>
              <Card
                className={styles.cardInternalFilter}
                style={{
                  marginBottom: "3em",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <TextField
                    id="card-textfield"
                    type="text"
                    variant="standard"
                    label="Nombre de la tarjeta..."
                    className="search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setQuery(e.target.value);
                    }}
                    sx={{ verticalAlign: "bottom" }}
                  />
                  <IconButton
                    color="primary"
                    className={styles.buttomFilter}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      setQuery(query);
                      mixpanel.track("settings_tab", {
                        Screen: "Card filter",
                        Query: query,
                      });
                    }}
                  >
                    Buscar
                  </IconButton>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {!!state && (
          <>
            <TableContainer
              sx={{
                maxHeight: "100%",
                paddingRight: "5px",
                marginRight: "10px",
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ width: "100%" }}
              >
                <TableHead>
                  {!!rows.length && (
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={uuidv4()}
                          align={column.align}
                          style={{
                            background: colors.background,
                            minWidth: column.minWidth,
                            borderBottom: `1px solid ${colors.borderDiv}`,
                          }}
                        >
                          <Typography
                            style={{
                              paddingLeft:
                                column.id === "productName" ? "3.2rem" : 0,
                              fontWeight: 500,
                            }}
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {cards
                    .filter((card) =>
                      card.productName.includes(query.toUpperCase())
                    )
                    .map((row) => {
                      return (
                        <CardSettingRow 
                        
                          key={uuidv4()}
                          cardCounter={cardCounter}
                          row={row}
                          handleClickOpen={handleClickOpen}
                          handlePrevent={handlePrevent}
                          enabledMultiMerchant={enabledMultiMerchant}
                        />
                      );
                    })}
                  <DialogCustom
                    setOpen={open}
                    title={
                      currentCard?.status
                        ? `Estás a punto de habilitar ${currentCard?.name}`
                        : `Estás a punto de deshabilitar ${currentCard?.name}`
                    }
                    description={description}
                    loading={loading}
                    handleClose={abortSwitch}
                    action={continueSwitch}
                  />
                  <Dialog
                    open={showPrevent}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      color={colors.primary}
                      sx={{
                        margin: 0,
                        paddingTop: "2em",
                        paddingLeft: "2em",
                        fontSize: "20px",
                      }}
                    >
                      {"Acción no permitida"}
                    </DialogTitle>
                    <DialogContent
                      style={{
                        paddingLeft: "2.5em",
                        paddingRight: "2.5em",
                        paddingTop: "1em",
                        paddingBottom: "2.5em",
                      }}
                    >
                      No podemos avanzar, las terminales deben tener al menos
                      una tarjeta habilitada.
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                      <Button
                        onClick={() => setShowPrevent(false)}
                        sx={{
                          width: "50%",
                          margin: "0 auto",
                          padding: "1.5em",
                          color: colors.primary,
                        }}
                      >
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TableBody>
              </Table>
              {!rows.length && (
                <InfoDanger
                  tittle={"No encontramos resultados para el filtro aplicado"}
                  customStyle={{ color: colors.grayText, fontWeight: 500 }}
                />
              )}
            </TableContainer>
          </>
        )}
      </Paper>
    </>
  );
};
export default StickyHeadTable;
