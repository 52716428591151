import React, { useState, useEffect, useContext, useCallback } from "react";
import LoadRoutes from "configs/LoadRoutes";
import Box from "@mui/material/Box";
//import { useHistory } from "react-router-dom";
//redux
import { connect } from "react-redux";
//actions
import { login } from "redux/auth/actions";

//import Button from "components/elements/button";
import { useKeycloak } from "@react-keycloak/web";
import KeyCloakService from "services/KeyCloakService";
import RenderOnRole from "helpers/RenderOnRole";
import Drawer from "components/menuDrawer";
//Context
import RoleContext from "context/userRole/RoleContext";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useWindowSize } from "hooks/useWindowSize";
import Header from "./header";
import mixpanel from "mixpanel-browser";

import colors from "constants/colors";
type Props = {
  routes?: object;
  onLogin: any;
};

const LayoutHome: React.FC<Props> = ({ routes, onLogin }) => {
  const { keycloak } = useKeycloak();
  const windowSize = useWindowSize();
  //Context
  const { drawerOpen, setUpdateDrawer } = useContext(OpenDrawerContext);
  const { role, setUpdateRole } = useContext(RoleContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [sentMixpanelLogin, setSentMixpanelLogin] = React.useState(false);

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const handleCloseLogin = useCallback(() => {
    keycloak.logout();
    localStorage.removeItem("validManufacturer");
    localStorage.removeItem("validMID");
    localStorage.removeItem("validTID");
    localStorage.removeItem("mixpanelEventSent");
  }, []);

  const userInfo = keycloak.idTokenParsed;

  const handleDrawerOpen = useCallback(() => {
    setUpdateDrawer(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setUpdateDrawer(false);
  }, []);

  //check role max range
  const RolRanges: any = {
    Administrador: 1,
    SoporteL3: 2,
    Operador: 3,
    Consulta: 4,
  };

  let aux = 3;
  //ROLES
  let KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID; //var dinamic env keycloack client id
  const UserRoles = keycloak?.tokenParsed?.resource_access
    ? keycloak?.tokenParsed?.resource_access[`${KEYCLOAK_CLIENT_ID}`].roles
    : ["Consulta"];

  const [userRoleRange, setUserRoleRange] = useState<string>();
  useEffect(() => {
    for (let index = 0; index < UserRoles.length; index++) {
      const element = UserRoles[index];
      const found = Object.keys(RolRanges).find(
        (element2) => element2 === element
      ) as string;
      if (found) {
        if (aux > RolRanges[found]) {
          setUserRoleRange(found);
        }
      }
    }
    if (UserRoles.length === 1) {
      setUserRoleRange(UserRoles[0]);
    }
  }, [UserRoles]);

  useEffect(() => {
    setUpdateRole(userRoleRange);
  }, [userRoleRange]);

  useEffect(() => {
    if (userInfo !== undefined && sentMixpanelLogin === false) {
      mixpanel.track("user_login", {
        Screen: "Diagnostics - Login",
        User: userInfo.name,
      });
      setSentMixpanelLogin(true);
    }
  });

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          authenticated={keycloak.authenticated}
          role={role}
          handleMenu={handleMenu}
          given_name={userInfo?.given_name}
          family_name={userInfo?.family_name}
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleCloseLogin={handleCloseLogin}
        />
      </Box>
      {!!keycloak.authenticated && (
        <RenderOnRole roles={["Administrador", "SoporteL3"]}>
          <Drawer
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            open={drawerOpen}
          />
        </RenderOnRole>
      )}
      <div
        style={{
          bottom: 0,
          left: 0,
          right: 0,
          top: 69,
          position: "absolute",
          backgroundColor: colors.background,
        }}
      >
        {keycloak.authenticated && <LoadRoutes routes={routes} />}
        {/* <LoadRoutes routes={routes} /> */}
      </div>
    </>
  );
};
const mapStateToProps = null;

const mapDispatchToProps = {
  onLogin: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHome);
