import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      marginTop: "1rem",
      //paddingRight: "1rem",
      display: "flex",
      flexDirection: "column",
      width: "27.75rem",
    },
    textHeader: {
      fontFamily: "Rubik",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
      fontStyle: "normal",
      color: colors.primary,
      paddingTop: "0.5rem",
      paddingBottom: "1.5rem",
    },
    textHeaderSub: {
      paddingTop: "2rem",
      paddingBottom: "1.5rem",
      color: colors.primary,
    },
    textHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "3.25rem",
      width: "21.5rem",
      marginBottom: "0.2rem",
    },
    tableContent: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingLeft: "0rem",
      paddingRight: "1rem",
    },
    buttomFilter: {
      background: colors.primary,
      color: colors.white,
      borderRadius: "1.125rem",
      width: "6.125rem",
      height: "2.25rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.225rem",
      marginLeft: "1.125rem",
      paddingRight: "0.313rem",
      paddingLeft: "0.313rem",
      "&:hover": {
        backgroundColor: colors.primarydark,
      },
      "&:active": {
        backgroundColor: colors.cyan,
      },
    },
    cardLayout: {
      padding: "1.5em",
      paddingBottom: "1.5em",
      margin: 0,
      marginBottom: "1.5em",
      flexDirection: "column",
      borderRadius: "8px",
      color: colors.primary,
    },
    cardContent: {
      boxShadow: "none",
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      height: "40rem",
      background: colors.background,
      borderRadius: ".5rem",
      marginBottom: "1em",
    },
    cardFilter: {
      boxShadow: "none",
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      height: "10rem",
      width: "100%",
      background: colors.background,
      borderRadius: ".5rem",
    },
    gridInternal: {
      padding: "0.5rem",
      width: "94%",
    },
    textTittle: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "1.2rem",
      fontStyle: "normal",
      lineHeight: "1.2rem",
      color: colors.grayText,
      paddingBottom: "1rem",
    },
    textSub: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: ".8rem",
      fontStyle: "normal",
      paddingTop: "0.5em",
      lineHeight: "1.2rem",
      color: colors.grayText,
    },
    textTittleSM: {
      fontFamily: "Rubik",
      fontWeight: 600,
      fontSize: "1rem",
      fontStyle: "normal",
      lineHeight: "1rem",
      marginBottom: "1.25rem",
      color: colors.grayText,
    },
    chartContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "110%",
    },
    cardMetrics: {
      padding: "1em",
      borderRadius: "10px",
      boxShadow: "none",
      width: "100%",
      height: "7em",
      marginBottom: "1em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    modelList: {
      listStyleType: "none",
      lineHeight: "1.25rem",
      height: "80px",
      overflow: "auto",
      padding: 0,
      margin: 0,
    },
  })
);

export default useStyles;
