import React, { FC } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "constants/colors";
import { v4 as uuidv4 } from "uuid";
import useStyles from "./Styles";
import moment from "moment";
interface Column {
  id: "key" | "value";
  label: string;
  minWidth?: number;
  align?: "left";
}

const columns: Column[] = [
  {
    id: "key",
    label: "Item",
    minWidth: 400,
    align: "left",
  },
  {
    id: "value",
    label: "Valor",
    minWidth: 100,
    align: "left",
  },
];

type Props = {
  state?: any;
};
interface Data {
  key: string;
  value: any;
}
function createData(key: string, value: any): Data {
  return { key, value };
}

const StickyHeadTable: FC<Props> = ({ state }) => {
  const styles = useStyles();

  const rows: Data[] = [];

  if (!!state) {
    for (let i = 0; i < Object.values(state).length; i += 1) {
      //if change date format
      if (
        Object.keys(state)[i] !== "bootDate" &&
        Object.keys(state)[i] !== "shutdownDate"
      ) {
        rows.push(createData(Object.keys(state)[i], Object.values(state)[i]));
      } else if (
        Object.keys(state)[i] === "bootDate" ||
        Object.keys(state)[i] === "shutdownDate"
      ) {
        let dataArray: any = Object.values(state)[i];
        let auxArray: any[] = [];
        // eslint-disable-next-line array-callback-return
        dataArray.map((data: string) => {
          auxArray.push(moment(parseInt(data)).utc().format("DD/MM/yyyy"));
        });
        rows.push(createData(Object.keys(state)[i], auxArray.join(" , ")));
      }
    }
  }

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          background: colors.background,
          boxShadow: "none",
          overflowY: "hidden",
          overflowX: "auto",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          boxSizing: "border-box",
          paddingBottom: ".15rem",
        }}
      >
        {!!state && (
          <>
            <TableContainer sx={{ maxHeight: "100%", overflowX: "hidden" }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow key={uuidv4()}>
                    {columns.map((column) => (
                      <TableCell
                        key={uuidv4()}
                        align={column.align}
                        style={{
                          background: colors.background,
                          borderBottom: `1px solid ${colors.borderDiv}`,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={uuidv4()}
                        className={styles.TableRow}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={uuidv4()}
                              align={column.align}
                              style={{
                                borderBottom: `1px solid ${colors.borderDiv}`,
                                maxWidth: 200,
                              }}
                            >
                              {value.toString()}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
    </>
  );
};
export default StickyHeadTable;
