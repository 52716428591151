import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import colors from "constants/colors";
const Progress = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress size={98} style={{ color: colors.primary }} />
    </Box>
  );
};

export default Progress;
