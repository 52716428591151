import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    cardContent: {
      boxShadow: "none",
      borderRadius: "6px 6px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    cardInternal: {
      height: "20em",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      marginBottom: "0.8rem",
    },
    cardInternalFilter: {
      background: colors.background,
      borderRadius: ".5rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "end",
      boxShadow: "none",
      marginBottom: "0.8rem",
    },
    gridInternal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      height: "100px",
    },
    brandsGridContent: {
      marginTop: ".5rem",
      width: "100%",
    },
    configGridContent: {
      padding: "0.5em 1.2em",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    configGridContentButton: {
      padding: "1.5em",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    textTittle: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "1.25rem",
      fontStyle: "normal",
      lineHeight: "1.25rem",
      color: colors.grayText,
      paddingBottom: "0.5rem",
    },
    textTittle2: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "1.25rem",
      color: colors.grayText,
      marginRight: "0.5em",
    },
    textSub: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "1rem",
      fontStyle: "normal",
      lineHeight: "1.4rem",
      color: colors.grayText,
    },
    textFunction: {
      margin: 0
    },
    functionChip: {
      minWidth: "140px",
      borderRadius: "5px",
      fontSize: '0.9rem',
      fontWeight: 500
    },
    dataContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      minHeight: "2.75rem",
    },
    div: {
      borderRight: `1px solid ${colors.borderDiv}`,
      height: "50px",
    },
    TableRow: {
      "&:hover": {
        backgroundColor: colors.backgroundHover,
      },
    },
    headerGrid: {
      paddingTop: "1rem",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    textCardSetting: {
      fontSize: "20px",
      fontWeight: 500,
      color: colors.grayText,
    },
    textCardSetting2: {
      fontSize: "14px",
      fontWeight: 500,
      color: colors.grayText,
    },
    textHeaderSub: {
      paddingTop: "1.1875rem",
      paddingBottom: "1rem",
      color: colors.primary,
    },
    buttomFilter: {
      background: colors.primary,
      color: colors.white,
      borderRadius: "1.125rem",
      width: "6.125rem",
      height: "2.25rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      marginLeft: "2em",
      "&:hover": {
        backgroundColor: colors.primarydark,
      },
      "&:active": {
        backgroundColor: colors.cyan,
      },
    },
    buttomPkg: {
      background: colors.primary,
      color: colors.white,
      borderRadius: "1.125rem",
      width: "14rem",
      height: "2.25rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.225rem",
      "&:hover": {
        backgroundColor: colors.primarydark,
      },
      "&:active": {
        backgroundColor: colors.cyan,
      },
    },
  })
);

export default useStyles;
