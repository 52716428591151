import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    TableRow: {
      "&:hover": {
        backgroundColor: colors.backgroundHover,
      },
    },
    root: {
      paddingTop: "1.3rem",
      paddingLeft: "1.375rem",
      paddingRight: ".8rem",
      paddingBottom: ".5rem",
      boxShadow: "none",
      background: colors.background,
    },
    textHeaderSub: {
      paddingBottom: "1rem",
      color: colors.primary,
    },
    cardInternalTabs: {
      background: "white",
      boxShadow: "none",
      borderTopLeftRadius: ".5rem",
      borderTopRightRadius: ".5rem",
      margin: "auto",
    },
    notLatLon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "29.5rem",
      borderRadius: "12px",
      marginTop: "0.5rem",
      boxShadow: "none",
    },
  })
);

export default useStyles;
