import * as types from "./type";
import { INITIAL_STATE } from "./initialState";
//funcion puras
const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_TERMINALS_DATA:
      return {
        ...state,
        terminalsData: payload.data,
      };

    case types.SET_TERMINALS_DATA:
      return {
        ...state,
        terminalsData: payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
