import axios from "axios";
//import keycloak from "configs/Keycloak";
import KeyCloakService from "services/KeyCloakService";

let API_URL = process.env.REACT_APP_END_POINT
  ? process.env.REACT_APP_END_POINT
  : `http://localhost`;

let baseURL = "";
if (process.env.NODE_ENV === "development") {
  baseURL = `${API_URL}`;
} else baseURL = process.env.REACT_APP_END_POINT;

const _axios = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
    "x-api-key": `${process.env.REACT_APP_X_API_KEY}`
  }
});

_axios.interceptors.request.use((config) => {
  if (!!KeyCloakService.getToken()) {
    const cb = () => {
      config.headers.Authorization = `Bearer ${KeyCloakService.getToken()}`;
      return Promise.resolve(config);
    };
    return updateToken(cb);
  }
});

const updateToken = (successCallback) => (
  KeyCloakService.updateToken(successCallback))
/* .then(successCallback, console.log("updateToken", successCallback))
.catch(keycloak.login); */

export default _axios;
