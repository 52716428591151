import React, { useState, useEffect, useContext } from "react";
import colors from "constants/colors";
//import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Skeleton from "@mui/material/Skeleton";
import ErrorBoundary from "components/errorBoundary";
import AlertMessage from "components/alertMessage";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";
import useStyles from "./styles";
import RoleContext from "context/userRole/RoleContext";
import MixPanelService from "services/mixpanel.service";
import TableCustom from "./table";
import TerminalService from "services/terminal.service";
import mixpanel from "mixpanel-browser";

type Props = any;

const StickyHeadTable: React.FC<Props> = () => {
  const styles = useStyles();
  //Context
  const { role } = useContext(RoleContext);

  const monthBefore = new Date();
  monthBefore.setDate(monthBefore.getDate() - 30);
  monthBefore.setHours(0, 0, 0, 0);

  const [localErrors, setLocalErrors] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchPage, setFetchPage] = useState<number>(1);
  const [initDate, setInitDate] = useState<Date>(monthBefore);
  const [finishDate, setFinishDate] = useState<Date>(new Date());
  const [queryCode, setQueryCode] = useState<string>("");
  const [queryMessage, setQueryMessage] = useState<string>("");
  const [queryBin, setQueryBin] = useState<string>("");
  const [filtered, setFiltered] = useState<boolean>(false);

  const dayBefore = new Date(finishDate);
  dayBefore.setDate(dayBefore.getDate() - 1);
  dayBefore.setHours(23, 59, 59, 0);

  const dayAfter = new Date(initDate);
  dayAfter.setDate(dayAfter.getDate() + 1);
  dayAfter.setHours(0, 0, 0, 0);

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setFinishDate(new Date());
    setInitDate(monthBefore);
    setQueryCode("");
    setQueryMessage("");
    setQueryBin("");
  };

  const validTID = localStorage.getItem("validTID");
  const validMID = localStorage.getItem("validMID");

  const fetchLocalErrors = async (
    // queryCode: string,
    currentPage: number,
    pageSize: number,
    dateFrom: Date,
    dateTo: Date,
    errorCode: string | null,
    errorMessage: string | null,
    bin: string | null
    // queryCode: string
  ) => {
    try {
      const res = await TerminalService.local_errors({
        terminal_id: validTID,
        merchant: validMID,
        current_page: currentPage,
        page_size: pageSize,
        date_from: dateFrom.toISOString(),
        date_to: dateTo.toISOString(),
        error_code: errorCode === "" ? null : errorCode,
        error_message: errorMessage === "" ? null : errorMessage,
        bin: bin === "" ? null : bin,
      });
      if (res.status === 200) {
        setLocalErrors(res.data);
        setLoading(false);
        mixpanel.track("localErrors_tab", {
          Screen: `Local Errors - filter`,
          Filter: {
            from: dateFrom.toISOString(),
            to: dateTo.toISOString(),
            errorCode: errorCode || "",
            errorMessage: errorMessage || "",
            bin: bin || "",
            page: fetchPage,
          }
        })
        return res.data;
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchLocalErrors(fetchPage, 100, initDate, finishDate, null, null, null);
  }, [fetchPage]);

  const validateEmpty =
    queryCode == "" && queryMessage == "" && queryBin == "" ? true : false;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12}>
          <Card className={styles.cardInternal}>
            <Grid className={styles.gridInternal}>
              <ErrorBoundary>
                <Grid
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid style={{ paddingRight: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label={
                          <span style={{ color: colors.grayText }}>
                            Fecha desde
                          </span>
                        }
                        value={initDate}
                        inputFormat={"dd/MM/yyyy"}
                        minDate={new Date("2020-01-01")}
                        maxDate={dayBefore}
                        onChange={(newValue: any) => {
                          setInitDate(newValue);
                          setFiltered(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            variant="standard"
                            onKeyDown={onKeyDown}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid style={{ paddingRight: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label={
                          <span style={{ color: colors.grayText }}>
                            Fecha hasta
                          </span>
                        }
                        value={finishDate}
                        inputFormat={"dd/MM/yyyy"}
                        minDate={dayAfter}
                        maxDate={new Date()}
                        onChange={(newValue: any) => {
                          setFinishDate(newValue);
                          setFiltered(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            variant="standard"
                            onKeyDown={onKeyDown}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <TextField
                    style={{ paddingRight: "1em" }}
                    id="card-textfield-code"
                    type="text"
                    variant="standard"
                    label="Código de error"
                    className="search"
                    value={queryCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setQueryCode(e.target.value);
                      setFiltered(false);
                    }}
                  />
                  <TextField
                    style={{ paddingRight: "1em" }}
                    id="card-textfield-message"
                    type="text"
                    variant="standard"
                    label="Mensaje de error"
                    className="search"
                    value={queryMessage}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setQueryMessage(e.target.value);
                      setFiltered(false);
                    }}
                  />
                  <TextField
                    style={{ paddingRight: "1em" }}
                    id="card-textfield-bin"
                    type="text"
                    variant="standard"
                    label="Primeros 6 dígitos"
                    className="search"
                    value={queryBin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setQueryBin(e.target.value);
                      setFiltered(false);
                    }}
                  />
                  <Grid style={{ paddingRight: "3rem" }}>
                    {filtered && !validateEmpty ? (
                      <IconButton
                        color="primary"
                        style={{ width: "10em" }}
                        className={styles.buttomFilter}
                        onClick={() => (
                          handleReset(),
                          setFiltered(false),
                          mixpanel.track("localErrors_tab", {
                            Screen: `Local Errors - clear filters`
                          })
                        )}
                      >
                        Borrar filtros
                        <FilterIcon style={{ paddingLeft: ".5rem" }} />
                      </IconButton>
                    ) : !loading ? (
                      <IconButton
                        color="primary"
                        className={styles.buttomFilter}
                        onClick={() => (
                          fetchLocalErrors(
                            // queryCode,
                            fetchPage,
                            100,
                            initDate,
                            finishDate,
                            queryCode,
                            queryMessage,
                            queryBin
                          ),
                          setFiltered(true)
                        )}
                      >
                        Aplicar
                        <FilterIcon style={{ paddingLeft: ".5rem" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        className={styles.buttomFilter}
                      >
                        <div
                          className="rotateIcon"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "2rem",
                            height: "2rem",
                          }}
                        >
                          <LoadingIcon />
                        </div>
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </ErrorBoundary>
            </Grid>
          </Card>
        </Grid >
        <Grid item xs={12} xl={12}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              style={{
                background: colors.background,
                borderRadius: "10px",
              }}
              width={"100%"}
              height={"41.2rem"}
            />
          ) : localErrors.total_errors > 0 ? (
            <Card className={styles.cardTable}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <TableCustom
                    state={localErrors.errors}
                    fetchPage={fetchPage}
                    setFetchPage={setFetchPage}
                    total_elements={localErrors?.total_errors}
                    total_pages={localErrors?.total_pages}
                  />
                </ErrorBoundary>
              </Grid>
            </Card>
          ) : (
            <div
              style={{
                width: "100%",
                minHeight: "41.2rem",
              }}
            >
              <AlertMessage
                message={"¡No se encontraron eventos!"}
                customStyle={{ paddingTop: "8rem", paddingBottom: "2rem" }}
              />
            </div>
          )}
        </Grid>
      </Grid >
    </>
  );
};
export default StickyHeadTable;
