//mixpanel
import mixpanel from "mixpanel-browser";

/**
 * mixpanel service
 * @param {string} event_name name
 * @param {string} screen name
 * @param {string} role name
 * @param {object} custom_parameters {propierties: "test", propierties2: "test2"}
 * @returns
 */
const sendDataMixPanel = async (event_name, screen, role, custom_parameters = '') => {
    await mixpanel.track(event_name, {
        Screen: screen,
        Rol: role,
        ...custom_parameters
    })
}

const MixPanelService = {
    sendDataMixPanel,
};

export default MixPanelService;