import React, { useContext } from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useWindowSize } from "hooks/useWindowSize";
import useStyles from "./styles";
interface Props {
    children: JSX.Element[] | JSX.Element
}
const PagesContainer = ({ children }: Props) => {
    const styles = useStyles();
    const windowSize = useWindowSize();
    const { drawerOpen } = useContext(OpenDrawerContext);
    return (
        <Grid className={styles.root}>
            <Card className={styles.cardContent}>
                {children}
            </Card>
        </Grid >
    )
}

export default PagesContainer