import React, { forwardRef } from "react";
//import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
//import { ReactComponent as ErrorIcon } from "assets/svg/buttonIcon/errorIcon.svg";
import { ReactComponent as WarningIcon } from "assets/svg/buttonIcon/warning_white.svg";
import { ReactComponent as SuccessIcon } from "assets/svg/buttonIcon/successIcon.svg";
import Typography from "@mui/material/Typography";
import colors from "constants/colors";
import IconButton from "@mui/material/IconButton";
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface State extends SnackbarOrigin {
  open: boolean;
}
type Props = {
  severity?: string;
  message?: string;
  setCustomizedSnackbars?: any;
  onClick?: any;
};

const CustomizedSnackbars: React.FC<Props> = ({
  severity = "error",
  message = "error",
  setCustomizedSnackbars,
  onClick
}) => {
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(true);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setCustomizedSnackbars(false);
  };

  React.useEffect(() => {
    setTimeout(() => {
      handleCloseSnackbar()
    }, 10000);
  }, []);

  const alertColor: any = {
    error: {
      icon: <WarningIcon />,
      border: colors.magenta,
      backgroundColor: colors.magenta,
    },
    warning: {
      icon: <WarningIcon />,
      border: colors.magentaLight,
      backgroundColor: colors.magenta,
    },
    success: {
      icon: <SuccessIcon />,
      border: colors.cyanDark,
      backgroundColor: colors.cyanDark,
    },
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ paddingTop: "5rem" }}
      >
        <Alert
          variant="standard"
          style={{
            border: `1px solid ${alertColor[severity].border}`,
            backgroundColor: `${alertColor[severity].backgroundColor}`,
            color: "#43444C",
            width: "60rem",
            height: "3rem",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px"
          }}
          icon={alertColor[severity].icon}
          action={
            <>
              {severity !== "error" ? <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
                style={{ display: "flex", width: "2rem" }}
              >
                x
              </IconButton>
                :
                <div onClick={onClick} style={{ color: "white", fontSize: "14px", cursor: "pointer", paddingRight: "1rem" }}>
                  Volver a intentar
                </div>}
            </>
          }
        >
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "1rem",
              fontWeight: 500,
              lineHeight: "1.125rem",
              fontStyle: "normal",
              color: colors.white,
            }}
          >
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};
export default CustomizedSnackbars;
