import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ReactComponent as AlertDanger } from "assets/svg/quest_icon.svg";
import colors from "constants/colors";

type Props = {
  message?: string;
  customStyle?: object;
};

const AlertMessage: React.FC<Props> = ({
  message,
  customStyle
}) => {
  return (
    <Grid
      style={{
        paddingTop: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        ...customStyle
      }}
    >
      <AlertDanger />
      <Typography
        style={{
          paddingTop: "2.625rem",
          fontSize: "1rem",
          fontWeight: 600,
          lineHeight: "1.625rem",
          color: colors.grayText,
        }}
      >
        {message ? message : "¡Algo salió mal!"}
      </Typography>
      <Grid style={{ paddingTop: "2.625rem" }}>
      </Grid>
      <Grid style={{ paddingTop: ".5rem" }}>
      </Grid>
    </Grid>
  );
};

export default AlertMessage;
