import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { TextField, IconButton } from "@mui/material";
import { format /* , sub */, parseISO } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import colors from "constants/colors";
import { ReactComponent as ArrowDownIcon } from "assets/svg/down_icon.svg";
//Context
import RoleContext from "context/userRole/RoleContext";
import useStyles from "./styles";
import MixPanelService from "services/mixpanel.service";
interface Column {
  id: "date" | "user" | "merchant" | "terminal" | "action" | "productId";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}
//object
const objectValues: any = {
  date: "Fecha",
  user: "Usuario",
  merchant: "Comercio Paystore",
  terminal: "Terminal",
  action: "Acción",
  productId: "Producto Modificado",
};

const columns: readonly Column[] = [
  { id: "date", label: objectValues["date"], minWidth: 150 },
  { id: "user", label: objectValues["user"], minWidth: 150 },
  { id: "merchant", label: objectValues["merchant"], minWidth: 100 },
  { id: "terminal", label: objectValues["terminal"], minWidth: 100 },
  {
    id: "action",
    label: objectValues["action"],
    minWidth: 190,
  },
  {
    id: "productId",
    label: objectValues["productId"],
    minWidth: 100,
  },
];

interface Data {
  date: string;
  user: string;
  merchant: string;
  terminal: string;
  action: string;
  productId: string;
}

function createData(
  date: string,
  user: string,
  merchant: string,
  terminal: string,
  action: string,
  productId: string
): Data {
  return {
    date,
    user,
    merchant,
    terminal,
    action,
    productId,
  };
}

type Props = {
  state?: any;
  selectState?: any;
  fetchPage?: any;
  setFetchPage?: any;
  total_elements?: any;
  total_pages?: any;
};

const StickyHeadTable: React.FC<Props> = ({
  state,
  fetchPage,
  setFetchPage,
  total_elements = 0,
  total_pages = 0,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  //Context
  const { role } = useContext(RoleContext);
  const rows: Data[] = [];

  for (let i = 0; i < Object.values(state).length; i += 1) {
    let currentAction = "";
    let tefAcquirer = "";

    switch (state[i].action) {
      case "REMOTE_INITIALIZATION":
        currentAction = "Solicitud de inicialización";
        break;
      case "UPDATE_PRODUCT":
        state[i].finalStatus === "true"
          ? (currentAction = "Habilitación de tarjeta")
          : (currentAction = "Deshabilitación de tarjeta");
        break;
      case "SWITCH_CASHBACK":
        state[i].finalStatus === "true"
          ? (currentAction = "Habilitación de cobro y retiro de efectivo")
          : (currentAction = "Deshabilitación de cobro y retiro de efectivo");
        break;
      case "SWITCH_API_MODE":
        state[i].finalStatus === "true"
          ? (currentAction = "Habilitación de cobro con Modo API")
          : (currentAction = "Deshabilitación de cobro con Modo API");
        break;
      case "SWITCH_AUTOBATCHCLOSE":
        state[i].finalStatus === "true"
          ? (currentAction = "Habilitación de cierre de lote automático")
          : (currentAction = "Deshabilitación de cierre de lote automático");
        break;
      case "SET_BATCHCLOSETIME":
        currentAction = `Modificación de horario de cierre de lote automático: ${state[i].finalStatus}hs`;
        break;
      case "SWITCH_UNREFERENCED_REFUND":
        state[i].finalStatus === "true"
          ? (currentAction = "Habilitación de devoluciones no referenciadas")
          : (currentAction =
            "Deshabilitación de devoluciones no referenciadas");
        break;
      case "UPDATE_TEF_ACQUIRER":
        currentAction = "Modificación de comercio";
        tefAcquirer = `${state[i].productId === "13"
            ? "Prisma"
            : state[i].productId === "14"
              ? "Amex"
              : null
          } - ${state[i].finalStatus}`;
        break;
      default:
        currentAction = state[i].action;
        break;
    }

    rows.push(
      createData(
        format(parseISO(state[i].date), "dd/MM/yyyy - HH:mm:ss"),
        state[i].user,
        state[i].merchant,
        state[i].terminal,
        currentAction,
        !tefAcquirer ? state[i].productId : tefAcquirer
      )
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (page < newPage) {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "next_page_button_auditLog",
        "Diagnostics - Audit Log Screen",
        role
      );
    } else {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "previous_page_button_auditLog",
        "Diagnostics - Audit Log Screen",
        role
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "results_per_page_button_auditLog",
      "Diagnostics - Audit Log Screen",
      role,
      { Results: +event.target.value }
    );
  };
  const loadPreviousPage = async () => {
    if (fetchPage > 0) {
      setFetchPage(fetchPage - 1);
    }
    MixPanelService.sendDataMixPanel(
      "previous_page_button_transactions",
      "Diagnostics - Audit Log Screen",
      role
    );
  };
  const loadNextPage = async () => {
    setFetchPage(fetchPage + 1);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "next_page_button_transactions",
      "Diagnostics - Audit Log Screen",
      role
    );
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        background: colors.background,
        boxShadow: "none",
        overflowY: "hidden",
        overflowX: "auto",
        boxSizing: "border-box",
      }}
    >
      <TableContainer sx={{ minHeight: "41.2rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "transparent",
                    left: 0,
                  }}
                >
                  <Typography
                    style={{
                      color: colors.primary,
                      fontSize: ".875rem",
                      fontWeight: 500,
                      lineHeight: "1.25rem",
                      textTransform: "uppercase",
                      verticalAlign: "top",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={uuidv4()}>
                    {columns.map((column) => {
                      const value: any = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            background: colors.background,
                            position: "sticky",
                            left: 0,
                            borderBottom: `1px solid ${colors.borderDiv}`,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                color: colors.textTable,
                                fontSize: ".875rem",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {value}
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100 /* , 20, 50, 100 */]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => {
          let total: number = fetchPage * 100;
          let totalNext: number = fetchPage * 100;
          return (
            "Página " +
            fetchPage +
            " - " +
            (fetchPage !== 0 && total_elements <= total
              ? total_elements
              : totalNext > total_elements
                ? total_elements
                : totalNext) +
            " de " +
            total_elements +
            " resultados"
          );
        }}
        labelRowsPerPage={""}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          color: colors.primary,
        }}
        SelectProps={{
          //MenuProps: { classes: { paper: classes.selectDropdown } },
          IconComponent: ArrowDownIcon,
        }}
        //classes={{ menuItem: classes.menuItem }}
        backIconButtonProps={{
          onClick: () => loadPreviousPage(),
          className: classes.leftIconButton,
          disabled: fetchPage <= 1 ? true : false,
        }}
        nextIconButtonProps={{
          onClick: () => loadNextPage(),
          className: classes.rightIconButton,
          disabled: fetchPage >= total_pages ? true : false,
        }}
      />
    </Paper>
  );
};
export default React.memo(StickyHeadTable);
