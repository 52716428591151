import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import colors from "constants/colors";
import useStyles from "./TableStayles";

import { ReactComponent as StatusEnableIcon } from "assets/svg/statusEnable.svg";
import { ReactComponent as StatusDisabledIcon } from "assets/svg/statusDisabled.svg";

//Context
import UpdateDataContext from "context/update/UpdateDataContext";
import DisableTableContext from "context/disabled/DisableTableContext";

import TabsContext from "context/pages/home/diagnostics/TabsContext";

//import componenetes
import InfoDanger from "components/elements/infoDanger";
import BasicModal from "components/modalCustom";
//mixpanel
import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router-dom";
//date
import { format, parseISO } from "date-fns";
import { useWindowSize } from "hooks/useWindowSize";

type Props = {
  terminals?: [object];
  pagePosition?: number;
  totalPages?: number;
  pageSize?: number;
  totalElements?: number;
};

const CustomPaginationActionsTable: React.FC<Props> = ({
  terminals = [1, 2],
  // pagePosition = 1,
  // totalPages = 1,
  // pageSize,
  // totalElements = 1,
}) => {
  const history = useHistory();
  const windowSize = useWindowSize();
  //Context
  const { /* setUpdateData, */ updateData } =
    React.useContext(UpdateDataContext);
  const { disableTable /* setDisableTabla */ } =
    React.useContext(DisableTableContext);

  const { setDiagnosticOpen } = React.useContext(TabsContext);

  const [page, setPage] = React.useState(0);
  //cant de filas x paginas
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [indexSelect, setIndexSelect] = React.useState<number>();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - terminals.length) : 0;

  const styles = useStyles();

  /**
   * analytics
   * @param terminal
   * Conexion: En línea|Sin conexión : terminal?.status === "Instalada"
   * Status: Saludable|En Alerta|Crítico aun *sin recibir este dato*
   */
  const handleClickTerminalAnalytics = (terminal: string, merchant: string, serial: string, status: any, health: any) => {
    if (health) {
      //analytics cantidad de resultados por pagina
      return mixpanel.track("terminal_selection", {
        Terminal: terminal,
        Merchant: merchant,
        SerialNumber: serial,
        Conexion: status === "Instalada" ? "En línea" : "Sin conexión",
        Status:
          health >= 80
            ? "Saludable"
            : health < 80 && health > 20
              ? "En Alerta"
              : "Crítico",
        Screen: "Diagnostico - Listado de terminales",
      });
    }
  };

  React.useEffect(() => {
    //analytics cantidad de resultados por pagina
    return mixpanel.track("results_per_page_button", {
      Results: `${rowsPerPage}`,
      Screen: "Diagnostics - Terminals List",
    });
  }, [rowsPerPage]);

  //capture modal descarga
  const [formatFile, setFromatFile] = React.useState<string>();
  const [formatf, setFromatf] = React.useState<boolean>(false);
  //const [loadingTable, setLoadingTable] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (formatFile && !formatf) {
      setFromatf(true);
    }
    if (formatf) {
      //capture modal descarga
      mixpanel.track("download_report_button", {
        Results: `${formatFile}`,
        Screen: "Diagnostics - Terminals List",
      });
      setFromatf(false);
      setFromatFile("");
    }
  }, [formatFile, formatf]);

  const handleClickTerminalSelect = (terminal: any) => {
    //check terminal diagnostics
    if (terminal.health !== null) {
      localStorage.setItem("diagnostic_open", JSON.stringify(true));
      setDiagnosticOpen(true);
    } else {
      localStorage.setItem("diagnostic_open", JSON.stringify(false));
      setDiagnosticOpen(false);
    }
    if (terminal.health) {
      history.push(
        `/home/terminal-dashboard/${terminal?.serial_number}/${terminal?.merchant}/${terminal?.terminal}`
      );
      localStorage.setItem("validTID", terminal.terminal);
      localStorage.setItem("validMID", terminal.merchant);
      localStorage.setItem("validSN", terminal.serial_number);
      localStorage.setItem("validManufacturer", terminal.model_manufacturer);
    }
    if (terminal.status === "Instalada" && terminal?.serial_number !== null) {
      history.push(
        `/home/terminal-dashboard/${terminal?.serial_number}/${terminal?.merchant}/${terminal?.terminal}`
      );
      localStorage.setItem("validTID", terminal.terminal);
      localStorage.setItem("validMID", terminal.merchant);
      localStorage.setItem("validSN", terminal.serial_number);
      localStorage.setItem("validManufacturer", terminal.model_manufacturer);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "none", paddingTop: ".6rem" }}
      >
        <Table
          sx={{ width: "100%" }}
          aria-label="custom pagination table"
          style={{ borderCollapse: "separate" }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 100,
                  position: "sticky",
                  left: -2,
                  background: "white",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                  borderRight:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                TERMINAL
              </TableCell>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 200,
                  paddingLeft: "16px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                MERCHANT PAYSTORE
              </TableCell>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 150,
                  paddingLeft: "0px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                NÚMERO SERIAL
              </TableCell>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 100,
                  paddingLeft: "0px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                Marca
              </TableCell>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 100,
                  paddingLeft: "0px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                Modelo
              </TableCell>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 200,
                  paddingLeft: "0px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                último diagnóstico
              </TableCell>
              <TableCell
                align="left"
                className={styles.tableCell}
                style={{
                  minWidth: 90,
                  width: 220,
                  paddingLeft: "0px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                ESTADO
              </TableCell>

              <TableCell
                align="center"
                className={styles.tableCell}
                style={{
                  minWidth: 100,
                  width: 370,
                  paddingLeft: "16px",
                  borderTop:
                    windowSize.width <= 1119
                      ? `1px solid ${colors.borderDiv}`
                      : "",
                }}
              >
                Salud de la terminal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!disableTable && (
              <>
                {!updateData && (
                  <>
                    {(rowsPerPage > 0
                      ? terminals.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : terminals
                    ).map((terminal: any, index: number) => (
                      <TableRow
                        key={index}
                        hover={terminal.status === "Instalada" ? true : false}
                        onClick={() => (
                          terminal.status === "Instalada" &&
                          handleClickTerminalSelect(terminal),
                          setIndexSelect(index)
                        )}
                        style={{
                          cursor:
                            terminal.status === "Instalada" ? "pointer" : "",
                        }}
                      >
                        <TableCell
                          className={styles.tableCellData}
                          component="th"
                          scope="row"
                          onClick={() => (
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                          style={{
                            width: 100,
                            position: "sticky",
                            left: -2,
                            background:
                              windowSize.width <= 1119
                                ? "white"
                                : "transparent",
                            borderRight:
                              windowSize.width <= 1119
                                ? `1px solid ${colors.borderDiv}`
                                : "",
                            padding: "16px",
                          }}
                        >
                          {terminal.terminal || ""}
                        </TableCell>
                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 120, padding: "16px" }}
                          align="left"
                          onClick={() => (
                            /* setOpenModal(true) */
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                        >
                          {terminal.merchant || ""}
                        </TableCell>
                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 120 }}
                          align="left"
                          onClick={() => (
                            /* setOpenModal(true) */
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                        >
                          {terminal.serial_number || ""}
                        </TableCell>
                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 120 }}
                          align="left"
                          onClick={() => (
                            /* setOpenModal(true) */
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                        >
                          {terminal.model_manufacturer || ""}
                        </TableCell>
                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 120 }}
                          align="left"
                          onClick={() => (
                            /* setOpenModal(true) */
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                        >
                          {terminal.model_name || ""}
                        </TableCell>
                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 120 }}
                          align="left"
                          onClick={() => (
                            /* setOpenModal(true) */
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                        >
                          {terminal.last_diagnosis
                            ? format(
                              parseISO(terminal.last_diagnosis),
                              "dd/MM/yyyy HH:mm"
                            )
                            : ""}
                        </TableCell>
                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 150 }}
                          align="left"
                          onClick={() => (
                            /* setOpenModal(true) */
                            handleClickTerminalSelect(terminal),
                            handleClickTerminalAnalytics(
                              terminal?.terminal,
                              terminal?.merchant,
                              terminal?.serial_number,
                              terminal?.status,
                              terminal.health
                            )
                          )}
                        >
                          {terminal.status || ""}
                        </TableCell>

                        <TableCell
                          className={styles.tableCellData}
                          style={{ width: 400 }}
                          align="center"
                        >
                          {terminal.health ? (
                            <Chip
                              label={
                                terminal.health >= 80
                                  ? "Saludable"
                                  : terminal.health < 80 && terminal.health > 20
                                    ? "En Alerta"
                                    : "Crítico"
                              }
                              style={{
                                width: "141px",
                                fontSize: "14px",
                                borderRadius: "5px",
                                backgroundColor:
                                  terminal.health >= 80
                                    ? "#BFF2F5"
                                    : terminal.health < 80 &&
                                      terminal.health > 20
                                      ? "#F4E1AE"
                                      : "#FDB0B0",
                                color:
                                  terminal.health >= 80
                                    ? "#075052"
                                    : terminal.health < 80 &&
                                      terminal.health > 20
                                      ? "#4A3704"
                                      : "#921034",
                              }}
                            />
                          ) : (
                            <Chip
                              label="Sin diagnóstico"
                              style={{
                                width: "141px",
                                fontSize: "14px",
                                borderRadius: "5px",
                                backgroundColor: colors.grayBackgroudElement,
                                color: colors.primary,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} style={{ borderBottom: "none" }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!terminals.length && (
          <InfoDanger
            tittle={"No encontramos resultados para el filtro aplicado"}
          />
        )}
      </TableContainer>
      {openModal && (
        <BasicModal
          setOpenModal={setOpenModal}
          indexSelect={indexSelect}
          setFromatFile={setFromatFile}
        />
      )}
    </>
  );
};

export default React.memo(CustomPaginationActionsTable);
