import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    cardContent: {
      boxShadow: "none",
      borderRadius: "6px 6px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    cardInternal: {
      width: "100%",
      height: "36.4375rem",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      margin: "auto",
      marginBottom: "16px",
    },
    cardInternalQR: {
      width: "100%",
      height: "28rem",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      margin: "auto",
      marginBottom: "16px",
    },
    gridInternal: {
      padding: "1.5rem",
    },
    gridInternalGeneral: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    textTittle: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "1.25rem",
      fontStyle: "normal",
      lineHeight: "1.25rem",
      color: colors.grayText,
      paddingBottom: "1.25rem",
    },
    latlon: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "12px",
      fontStyle: "normal",
      lineHeight: "1.25rem",
      color: colors.grayText,
    },
    textSubBold: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "1rem",
      fontStyle: "bold",
      lineHeight: "1.25rem",
      color: colors.grayText,
      width: "20.625rem",
    },
    textSub: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: ".875rem",
      fontStyle: "normal",
      lineHeight: "1.25rem",
      color: colors.grayText,
      paddingLeft: "1rem",
      width: "20.625rem",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "2.8em",
      paddingLeft: ".5em",
    },
    div: {
      marginTop: ".875rem",
      marginBottom: ".875rem",
      marginLeft: ".5em",
      borderTop: `1px solid ${colors.borderDiv}`,
    },
    divider: {
      height: "70%",
      marginTop: ".5em",
      borderLeft: `1px solid ${colors.grayDisabled}`,
    },
    mapView: {
      height: "29.5rem",
      borderRadius: "12px",
      marginTop: "0.5rem",
      border: "none !important",
    },
    cardData: {
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      marginTop: "16px",
      marginBottom: "16px",
    },
    cardDataText: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    dataText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dataTextContent: {
      display: "flex",
      width: 200,
      justifyContent: "center",
      alignItems: "start",
      height: "120px",
      flexDirection: "column",
    },
    textSubTittle: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "14px",
      fontStyle: "normal",
      lineHeight: "1.25rem",
      color: colors.grayText,
      paddingLeft: "1rem",
    },
    iconRadius: {
      height: "1rem",
      width: "1rem",
      borderRadius: "50%",
    },
    textData: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: ".5rem",
    },
    battery: {
      backgroundColor: "grey",
      width: "100%",
      height: "0%",
      transition: "height 3s linear",
    },
    divBattery: {
      display: "flex",
      width: "44px",
      height: "96px",
      marginTop: "5px",
      position: "absolute",
      alignItems: "flex-end",
    },
    batteryContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    notLatLon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "29.5rem",
      borderRadius: "12px",
      marginTop: "0.5rem",
      boxShadow: "none",
    },
    QRDataContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "2em",
      paddingLeft: ".75em",
    },
    cardInternalFilter: {
      width: "100%",
      minHeight: "11.375rem",
      background: colors.background,
      borderRadius: ".5rem",
      boxShadow: "none",
      marginBottom: "0.8rem",
    },
    noQRalert: {
      backgroundColor: "white",
      marginBottom: "0.8em",
      height: "6em",
      minHeight: "0",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      border: "solid",
      borderWidth: "1.2px",
      borderRadius: ".5rem",
      borderColor: colors.grayDisabled,
    },
  })
);

export default useStyles;
