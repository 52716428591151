import axios from "./axios.config";

class TerminalService {
  /**
   * Filtro/primera carga
   * @param {number} pagePosition
   * @param {number} pageSize
   * @param {string} idTerminal
   * @returns void JSON lista de terminales
   */
  terminal_list(pagePosition = 1, idTerminal = "") {
    return axios.get(
      `/api/terminal-diagnostics/v1/list?page=${pagePosition}&terminal=${idTerminal}`
    );
  }

  terminal_diagnostics(serial = "", merchant = "", terminal = "") {
    return axios.get(
      `/api/terminal-diagnostics/v1/diagnostic?serial=${serial}&merchant=${merchant}&terminal=${terminal}`
    );
  }

  /**
   * terminal settings
   * @param {string} terminal_id
   * @param {string} merchant_id
   * @returns void Json terminal settings
   */
  terminal_setting(terminal_id = "", merchant_id = "") {
    return axios.get(
      `/api/terminal-diagnostics/v1/configuration?terminal_id=${terminal_id}&merchant_id=${merchant_id}`
    );
  }

  /**
   * botón de inicialización remota
   * @param {number} merchant
   * @param {number} terminal
   * @returns 202 success
   */
  remote_initialization(merchant = "", terminal = "") {
    return axios.put(
      `/api/terminal-diagnostics/v1/remote-initialization/init?merchant_id=${merchant}&terminal_id=${terminal}`
    );
  }
  /**
   * search transaction
   * @param {date} date 2021-12-15
   * @param {dateTime} init_time 00:00:00
   * @param {dateTime} finish_time 23:59:59
   * @param {string} merchant 000370
   * @param {string} terminal 38010704
   * @param {number} page 0 
   * @returns void default 1 pages 20 elements
   */

  terminals_metrics() {
    return axios.get(
      `/api/terminal-diagnostics/v1/meters/globals`
    )
  }

  terminal_transaction(date, init_time, finish_time, merchant, terminal, page = "") {
    return axios.get(
      `/api/terminal-diagnostics/v1/transaction/list?date=${date}&init_time=${init_time}&finish_time=${finish_time}&merchant=${merchant}&terminal=${terminal}&page=${page}&page_size=20`
    );
  }

  terminal_transfers(terminal, merchant, page, dateFrom, dateTo) {
    return axios.get(
      `/api/terminal-diagnostics/v1/qr-transfers/transfers?terminal_id=${terminal}&merchant_id=${merchant}&page_size=100&page=${page}&from=${dateFrom}&to=${dateTo}`
    );
  }

  terminal_intents(terminal, merchant, page, dateFrom, dateTo) {
    return axios.get(
      `/api/terminal-diagnostics/v1/qr-transfers/intents?terminal_id=${terminal}&merchant_id=${merchant}&page_size=100&page=${page}&from=${dateFrom}&to=${dateTo}`
    );
  }

  toggle_products(merchant, terminal, prod_id, prod_name) {
    return axios.put(`api/terminal-diagnostics/v1/update_product?acquirer_id=32&merchant_id=${merchant}&terminal_id=${terminal}&product_id=${prod_id}&product_name=${prod_name}`);
  }

  switch_cashback(merchant, terminal) {
    return axios.put(`api/terminal-diagnostics/v1/switch_cashback?acquirer_id=32&merchant_id=${merchant}&terminal_id=${terminal}`);
  }

  get_apimode(merchant, terminal) {
    return axios.get(`api/terminal-diagnostics/v1/apimode?merchant_id=${merchant}&terminal_id=${terminal}`);
  }

  switch_apimode(merchant, terminal) {
    return axios.put(`api/terminal-diagnostics/v1/switch_apimode?merchant_id=${merchant}&terminal_id=${terminal}`);
  }

  switch_unreferenced(merchant, terminal) {
    return axios.put(`api/terminal-diagnostics/v1/switch_unreferenced_refund?acquirer_id=32&merchant_id=${merchant}&terminal_id=${terminal}`);
  }

  fetch_tef_acquirers(merchant, terminal) {
    return axios.get(`api/terminal-diagnostics/v1/configuration/tef-acquirers?terminal_id=${terminal}&merchant_id=${merchant}`)
  }

  update_tef_acquirers(data) {
    return axios.post(`api/terminal-diagnostics/v1/configuration/tef-acquirers`, data);
  }

  local_errors(data) {
    return axios.post(`api/terminal-diagnostics/v1/errors`, data);
  }

  audit_log(data) {
    return axios.post(`/api/terminal-diagnostics/v1/config/auditory`, data);
  }

  version_status() {
    return axios.get(`api/terminal-diagnostics/v1/configuration/semver`);
  }

  autogen_specific_package(terminal, merchant) {
    return axios.put(`api/terminal-diagnostics/v1/configuration/set-specific-payment-parameters?terminal_id=${terminal}&merchant_id=${merchant}`);
  }

  fetch_specific_package(terminal, merchant) {
    return axios.get(`api/terminal-diagnostics/v1/configuration/get-specific-payment-parameters?terminal_id=${terminal}&merchant_id=${merchant}`);
  }

  fetch_qr_data(terminal, merchant){
    return axios.get(`api/terminal-diagnostics/v1/qr-config?merchant_id=${merchant}&terminal_id=${terminal}`)
  }

  security_token_get() {
    return axios.get(`api/terminal-diagnostics/v1/config/token`)
  }

  security_token_post(data) {
    return axios.post(`api/terminal-diagnostics/v1/config/token`, data)
  }

  business_config_get() {
    return axios.get(`api/terminal-diagnostics/v1/params`)
  }
}
export default new TerminalService();