import React, { useState, useEffect, useContext } from 'react'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, differenceInDays } from "date-fns";
import ButtonGuardar from "components/elements/button"
import { ReactComponent as WarningRed } from "assets/svg/warning/warning_red.svg";
import { ReactComponent as WarningGreen } from "assets/svg/warning/warning_green.svg";
import { ReactComponent as WarningOrange } from "assets/svg/warning/warning_orange.svg";
import { ReactComponent as WarningMagenta } from "assets/svg/warning/warning_magenta.svg";
//Context
import RoleContext from "context/userRole/RoleContext";
import useStyles from "./styles";
import colors from "constants/colors";
import rules from "constants/rules";

import { useForm, Controller } from "react-hook-form";
//mixPanel
import MixPanelService from "services/mixpanel.service";

interface Props {
    fetchSecurityTokenPost?: any;
    securityToken?: any;
    valueDate?: any;
    setValueDate?: any;
    emailPrimary?: string | undefined;
    setIsBlocking?: any;
    isBlocking?: boolean | undefined;
}
function TokenScreen({ securityToken, emailPrimary, fetchSecurityTokenPost, setIsBlocking, isBlocking, valueDate, setValueDate }: Props) {
    const styles = useStyles();
    const { role } = useContext(RoleContext);

    const [token, setToken] = useState<string>(securityToken?.token ?? "");

    const [email_primary, setEmail_primary] = useState<string | undefined>(securityToken?.primary_admin_email ?? emailPrimary);
    const [email_secondary, setEmail_secondary] = useState<string>(securityToken?.secondary_admin_email ?? "");

    const [detectChange, setDetectChange] = useState<boolean>(false);
    const [auxValueDate] = useState<Date | null>(valueDate);

    const datos = {
        token: token,
        expiration_token_date: valueDate,
        primary_admin_email: email_primary,
        secondary_admin_email: email_secondary,
    };

    const {
        control,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({ defaultValues: datos });

    const sendData = async (data: any) => {
        fetchSecurityTokenPost(data)
    };

    //reset value initial
    const resetState = () => {
        setToken(securityToken?.token ?? "")
        setValueDate(auxValueDate)
        setEmail_primary(securityToken?.primary_admin_email ?? emailPrimary)
        setEmail_secondary(securityToken?.secondary_admin_email ?? "")
        setDetectChange(false)
        setIsBlocking(false)
        clearErrors()
    }

    useEffect(() => {
        if (!isBlocking) {
            resetState()
        }
    }, [isBlocking])

    useEffect(() => {
        setToken(securityToken?.token ?? "")
        setValue("token", securityToken?.token)

        setEmail_primary(securityToken?.primary_admin_email ?? emailPrimary)
        setValue("primary_admin_email", securityToken?.primary_admin_email)

        setEmail_secondary(securityToken?.secondary_admin_email ?? "")
        setValue("secondary_admin_email", securityToken?.secondary_admin_email)

        setDetectChange(false)
        setIsBlocking(false)

    }, [securityToken])

    //diff
    let diffDate = valueDate && differenceInDays(valueDate, new Date(new Date().setDate(new Date().getDate() - 1)))

    const valueDateEnabled = valueDate && valueDate >= new Date(new Date().setDate(new Date().getDate()))

    const AlertElements = (
        <>
            {diffDate && diffDate > 7 ?
                <Grid style={{ display: "flex", alignItems: "center" }}>
                    <WarningGreen />
                    <Typography color={colors.cyanText} style={{ paddingLeft: "1rem" }}>
                        El API caduca en {diffDate} días
                    </Typography>
                </Grid> :
                diffDate && diffDate <= 7 && diffDate > 2 ?
                    <Grid style={{ display: "flex", alignItems: "center" }}>
                        <WarningOrange />
                        <Typography color={colors.orangeText} style={{ paddingLeft: "1rem" }}>
                            El API caduca en {diffDate} días
                        </Typography>
                    </Grid> :
                    diffDate && diffDate <= 2 && diffDate > 0 ?
                        <Grid style={{ display: "flex", alignItems: "center" }}>
                            <WarningMagenta />
                            <Typography color={colors.magentaDark} style={{ paddingLeft: "1rem" }}>
                                El API caduca en {diffDate} días, actualizá el codigo del API
                            </Typography>
                        </Grid> :
                        <Grid style={{ display: "flex", alignItems: "center" }}>
                            <WarningRed />
                            <Typography color={colors.magenta} style={{ paddingLeft: "1rem" }}>
                                El API ha caducado, actualizá el codigo del API
                            </Typography>
                        </Grid>
            }
        </>
    )

    return (
        <div style={{ minHeight: "100%", display: "flex", alignItems: "space-between", flexDirection: "column" }}>
            <Grid style={{ padding: "2rem", height: "100%", }} container spacing={3}>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: 500, fontSize: "18px", color: colors.primary, lineHeight: "41px" }}>
                    Token de seguridad
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: 400, fontSize: "14px", color: colors.grayText }}>
                    Asegurate que esté actualizado para que el rescate de la información de las terminales funcione correctamente
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="token"
                    control={control}
                    rules={rules.token}
                    render={({ field }) => (
                        <TextareaAutosize
                            {...field}
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Ingresa el código del Token aquí"
                            onChange={(e) => {
                                setToken(e.target.value)
                                field.onChange(e)
                                setDetectChange(true)
                                setIsBlocking(true)
                            }}
                            onClick={() =>
                                //mixpanel Token de Seguridad
                                MixPanelService.sendDataMixPanel(
                                    "token_field",
                                    "Diagnostics - Token de Seguridad",
                                    role,
                                )
                            }
                            style={{
                                width: "100%",
                                height: "8rem",
                                padding: "1rem",
                                boxSizing: "border-box"
                            }}
                            className={styles.textArea}
                            defaultValue={token}
                            value={token}
                        />
                    )}
                />
                {errors["token"] && (
                    <Typography color="error">
                        {errors["token"].message}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px", color: colors.primary }}>
                    Indicá la fecha de expiración para notificarte antes de que suceda.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: colors.primary }}>
                    Los E-mails ingresados recibirán dos notificaciones antes de la fecha de expiración. La primera 1 semana antes y la segunda 2 días antes de esa fecha.
                </Typography>
            </Grid>
            <Grid item xs={12}  >
                <Grid container spacing={2}>
                    <Grid item>
                        <Controller
                            control={control}
                            name='expiration_token_date'
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        {...field}
                                        label={<span style={{ color: colors.grayText }}>Fecha</span>}
                                        value={valueDate}
                                        inputFormat={"dd/MM/yyyy"}
                                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                        //maxDate={new Date()}
                                        onChange={(newValue) => {
                                            setValueDate(newValue);
                                            field.onChange(newValue)
                                            setDetectChange(true)
                                            setIsBlocking(true)
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                inputProps={
                                                    {
                                                        ...params.inputProps,
                                                        placeholder: "dd/mm/aaaa",
                                                    }
                                                }
                                                variant="standard" />
                                        }
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        {valueDate && valueDate < new Date(new Date().setDate(new Date().getDate())) && (
                            <Typography color="error">
                                Indicá la nueva fecha de expiración
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Controller
                            name="primary_admin_email"
                            control={control}
                            rules={rules.email}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    // eslint-disable-next-line no-prototype-builtins
                                    error={errors.hasOwnProperty("primary_admin_email")}
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => {
                                        setEmail_primary(e.target.value)
                                        field.onChange(e)
                                        setDetectChange(true)
                                        setIsBlocking(true)
                                    }}
                                    id="standard-number"
                                    label="E-mail principal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={email_primary}
                                    value={email_primary}
                                    autoComplete="new-password"
                                    inputProps={{ autoComplete: 'new-password' }}
                                />
                            )}
                        />
                        {errors["primary_admin_email"] && (
                            <Typography color="error">
                                {errors["primary_admin_email"].message}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Controller
                            name="secondary_admin_email"
                            control={control}
                            rules={rules.email}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    // eslint-disable-next-line no-prototype-builtins
                                    error={errors.hasOwnProperty("secondary_admin_email")}
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => {
                                        setEmail_secondary(e.target.value)
                                        field.onChange(e)
                                        setDetectChange(true)
                                        setIsBlocking(true)
                                    }}
                                    id="standard-number-2"
                                    label="E-mail secundario"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    defaultValue={email_secondary}
                                    value={email_secondary}
                                    autoComplete="new-password"
                                    inputProps={{
                                        autoComplete: "new-password"
                                    }}
                                />
                            )}
                        />
                        {errors["secondary_admin_email"] && (
                            <Typography color="error">
                                {errors["secondary_admin_email"].message}
                            </Typography>
                        )}
                            {email_primary === email_secondary && email_primary !== "" && (
                            <Typography color="error">
                                Los E-mails son iguales
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {AlertElements}
            </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", paddingBottom: 20 }}>
                <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                    <Grid item>
                        <ButtonGuardar
                            tittle={"Guardar"}
                            customStyle={{
                                height: "3rem",
                                width: "328px",
                                fontWeight: 400, fontSize: "16px"
                            }}
                            onClick={handleSubmit(sendData)}
                            disabled={
                                valueDateEnabled &&
                                    token !== ""
                                    && email_primary !== "" && !errors["primary_admin_email"]
                                    && email_secondary !== "" && !errors["secondary_admin_email"]
                                    && detectChange && email_primary !== email_secondary ?
                                    false : true
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="outlined"
                            style={{
                                height: "3rem",
                                width: "328px",
                                border: "2px solid #023F80",
                                borderRadius: "24px",
                                textTransform: "capitalize",
                                fontWeight: 400, fontSize: "16px"
                            }}
                            onClick={() => (resetState(),
                                //mixpanel Token de Seguridad
                                MixPanelService.sendDataMixPanel(
                                    "cancel_button_token",
                                    "Diagnostics - Token de Seguridad",
                                    role,
                                ))
                            }
                            disabled={
                                valueDateEnabled &&
                                    token !== "" && detectChange ?
                                    false : true
                            }
                        >
                            Deshacer cambios
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default TokenScreen