import React, { useState, useEffect, useCallback, useContext } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//Context
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import RoleContext from "context/userRole/RoleContext";

import { ReactComponent as Menu } from "assets/svg/drawer/menu.svg";
import { ReactComponent as Home } from "assets/svg/drawer/home.svg";
import { ReactComponent as HomeWhite } from "assets/svg/drawer/home_white.svg";
import { ReactComponent as Magnifier } from "assets/svg/drawer/magnifier.svg";
import { ReactComponent as MagnifierWhite } from "assets/svg/drawer/magnifier_white.svg";
import { ReactComponent as Token } from "assets/svg/drawer/token.svg";
import { ReactComponent as TokenWhite } from "assets/svg/drawer/token_white.svg";
import { ReactComponent as Health } from "assets/svg/drawer/health.svg";
import { ReactComponent as HealthWhite } from "assets/svg/drawer/health_white.svg";
import { ReactComponent as Ayuda } from "assets/svg/drawer/ayuda.svg";
import { ReactComponent as AyudaWhite } from "assets/svg/drawer/ayuda_white.svg";

import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useStyles from "./styles";
import colors from "constants/colors";

const drawerWidth = 252;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  //width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7.5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiPaper-root": {
    //position: "absolute",
    borderTopRightRadius: 8,
    border: "1px solid #C3CCDD",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  "& svg": {
    fontSize: 40,
    color: colors.cyanDark,
  },
}));

interface TabPanelProps {
  //children?: React.ReactNode;
  handleDrawerOpen?: any;
  handleDrawerClose?: any;
  open?: boolean;
}
//route variiable al selecionar
let routeSelect = "Listado de Terminales";

export default function MiniDrawer({
  handleDrawerOpen,
  handleDrawerClose,
  open,
}: TabPanelProps) {
  const theme = useTheme();
  //Context
  const { drawerOpen, setUpdateDrawer } = useContext(OpenDrawerContext);
  const { role } = useContext(RoleContext);
  const styles = useStyles();
  const history = useHistory();

  /**
   * all cases in Drawer for login users
   * @typedef {Object} list name Drawer for role
   */
  let ListNavBarRol = [""];
  // eslint-disable-next-line no-constant-condition
  if (role === "Administrador") {
    ListNavBarRol = [
      "Listado de Terminales",
      "Reportes",
      "Administración" /* , 'Reportes', "Fórmula de Salud", 'Gestor de inicialización', 'Ayuda' */,
    ];
  } else if (role === "SoporteL3") {
    ListNavBarRol = [
      "Listado de Terminales",
      "Reportes" /* , 'Reportes', "Fórmula de Salud", 'Gestor de inicialización', 'Ayuda' */,
    ];
  } else {
    ListNavBarRol = ["Listado de Terminales"];
  }

  /**
   * objeto de iconos que va a armar el Drawer
   * @param {String} text text is name selected to Drawer
   * @returns List icons
   */
  const listIcon: any = {
    "Listado de Terminales":
      routeSelect !== "Listado de Terminales" ? <Home /> : <HomeWhite />,
    Reportes: routeSelect !== "Reportes" ? <Magnifier /> : <MagnifierWhite />,
    "Fórmula de Salud":
      routeSelect !== "Fórmula de Salud" ? <Health /> : <HealthWhite />,
    "Administración":
      routeSelect !== "Administración" ? <Token /> : <TokenWhite />,
    "Gestor de inicialización":
      routeSelect !== "Gestor de inicialización" ? (
        <Magnifier />
      ) : (
        <MagnifierWhite />
      ),
    Ayuda: routeSelect !== "Ayuda" ? <Ayuda /> : <AyudaWhite />,
  };

  //re direccion dinamica del Drawer
  const listRoutes: any = {
    "Listado de Terminales": "/home",
    Reportes: "/reports",
    "Administración": "/admin-panel",
    //"Fórmula de Salud": "/home/terminal-dashboard",
  };

  const redirectNavBar = useCallback(
    (text) => {
      //asigno nueva ruta para que el backgroundColor marque el Drawer
      if (text.includes("Listado de Terminales")) {
        //check change url Prompt modal in token
        if (window.location.pathname === "/home") {
          routeSelect = "Listado de Terminales";
        }
      } else if (text.includes("Reportes")) {
        //check change url Prompt modal in token
        if (window.location.pathname === "/reports") {
          routeSelect = "Reportes";
        }
      } else if (text.includes("Administración")) {
        //check change url Prompt modal in token
        if (window.location.pathname === "/admin-panel") {
          routeSelect = "Administración";
        }
      } else {
        routeSelect = text;
      }
      return history.push(listRoutes[text]);
    },
    [history]
  );

  //capture direction url from others pages
  useEffect(() => {
    if (window.location.pathname === "/home") {
      redirectNavBar("Listado de Terminales");
    } else if (window.location.pathname === "/reports") {
      redirectNavBar("Reportes");
    } else if (window.location.pathname === "/admin-panel") {
      redirectNavBar("Administración");
    }
    if (window.location.pathname.includes("/home/terminal-dashboard")) {
      redirectNavBar(" ");
    }
  }, [window.location.pathname, redirectNavBar]);

  //pasar a hooks detected scroll
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiPaper-root": {
            top: scrollPosition - 90.2 < 0 ? 90.2 : 0,
          },
        }}
      >
        <DrawerHeader>
          {!open && (
            <IconButton onClick={handleDrawerOpen}>
              <Menu />
            </IconButton>
          )}
          {open && (
            <IconButton onClick={handleDrawerClose}>
              {/* <Menu /> */}
              <ChevronLeftIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <List>
          {ListNavBarRol.map((text, index) => (
            <ListItem
              button
              key={uuidv4()}
              selected={text === routeSelect ? true : false}
              style={
                text === routeSelect
                  ? {
                    backgroundColor: `${colors.primary}`,
                    color: `${colors.primary}`,
                    borderTop: drawerOpen ? "1px solid #B6C5D5" : "",
                    borderBottom: drawerOpen ? "1px solid #B6C5D5" : "",
                  }
                  : {
                    borderTop: drawerOpen ? "1px solid #B6C5D5" : "",
                    borderBottom: drawerOpen ? "1px solid #B6C5D5" : "",
                  }
              }
              onClick={() => redirectNavBar(text)}
            >
              <ListItemIcon sx={{ minWidth: 20, paddingRight: ".85rem" }}>
                {listIcon[text] || ""}
              </ListItemIcon>
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  fontSize: "14px",
                  color: colors.drawerText,
                }}
                style={
                  text === routeSelect
                    ? { color: "rgba(255, 255, 255, 1)" }
                    : { fontSize: "0.875rem" }
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
