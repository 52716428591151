import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "configs/routes";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { Provider } from "react-redux";
import { redux } from "redux/store";
//Context
import { combineComponents } from "helpers/combineComponents";
import UpdateDataState from "context/update/UpdateDataState";
import DisableTableState from "context/disabled/DisableTableState";
import OpenDrawerState from "context/pages/OpenDrawerState";
import RoleState from "context/userRole/RoleState";

import FilterTransactionState from "context/pages/home/diagnostics/tabTransactions/FilterTransactionState";
import TabsState from "context/pages/home/diagnostics/TabsState";
//mixpanel
import mixpanel from "mixpanel-browser";
//ThemeProvider
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Rubik",
  },
});

//Context
const AppContextProvider = combineComponents(
  ...[
    UpdateDataState,
    DisableTableState,
    OpenDrawerState,
    RoleState,
    TabsState,
    FilterTransactionState /* , TokenScreenLeave */,
  ]
);

function App() {
  const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
  /* console.log(MIXPANEL_TOKEN); */
  mixpanel.init(MIXPANEL_TOKEN, { debug: true, ignore_dnt: true });

  return (
    <Provider store={redux}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              {routes.map((route, index) => (
                <RouterWithSubRoutes key={index} {...route} />
              ))}
            </Switch>
          </Router>
        </ThemeProvider>
      </AppContextProvider>
    </Provider>
  );
}

function RouterWithSubRoutes(route: any) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props: any) => (
        <route.component routes={route.routes} {...props} />
      )}
    />
  );
}

export default App;
