import React, { useState, useEffect, useContext, useCallback } from "react";
import colors from "constants/colors";
//import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import ErrorBoundary from "components/errorBoundary";
import AlertMessage from "components/alertMessage";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";
import useStyles from "./styles";
import RoleContext from "context/userRole/RoleContext";
import RenderOnRole from "helpers/RenderOnRole";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, Prompt } from "react-router-dom";
import TableCustom from "./table";
import TerminalService from "../../services/terminal.service";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useWindowSize } from "hooks/useWindowSize";
import "./hideArrows.css";
import mixpanel from "mixpanel-browser";

type Props = any;

const style = {
  bgcolor: "background.paper",
  border: `1px solid #E9EDF5`,
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const StickyHeadTable: React.FC<Props> = () => {
  const windowSize = useWindowSize();

  const { drawerOpen, setUpdateDrawer } = useContext(OpenDrawerContext);

  const handleDrawerOpen = React.useCallback(() => {
    setUpdateDrawer(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setUpdateDrawer(false);
  }, []);

  //open Drawer in width 1366
  useEffect(() => {
    if (windowSize.width >= 1366) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [windowSize.width]);

  const styles = useStyles();

  const weekBefore = new Date();
  weekBefore.setDate(weekBefore.getDate() - 7);
  weekBefore.setHours(0, 0, 0, 0);

  const [logData, setLogData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // valor inicial para página de logs = 0
  const [fetchPage, setFetchPage] = useState<number>(1);
  const [initDate, setInitDate] = useState<Date>(weekBefore);
  const [finishDate, setFinishDate] = useState<Date>(new Date());
  const [queryUser, setQueryUser] = useState<string>("");
  const [queryTerminal, setQueryTerminal] = useState<string>("");
  const [filtered, setFiltered] = useState<boolean>(false);

  const dayBefore = new Date(finishDate);
  dayBefore.setDate(dayBefore.getDate() - 1);
  dayBefore.setHours(23, 59, 59, 0);

  const dayAfter = new Date(initDate);
  dayAfter.setDate(dayAfter.getDate() + 1);
  dayAfter.setHours(0, 0, 0, 0);

  const fetchAuditLogs = async (
    currentPage: number,
    dateFrom: Date,
    dateTo: Date,
    userName: string | null,
    terminal: string | null
  ) => {
    try {
      setLoading(true);
      const res = await TerminalService.audit_log({
        current_page: currentPage,
        page_size: 100,
        date_from: dateFrom,
        date_to: dateTo.setHours(23, 59, 59, 0),
        user: userName === "" ? null : userName,
        terminal_id: terminal === "" ? null : terminal,
      });
      if (res.status === 200) {
        setLoading(false);
        setLogData(res.data);
        mixpanel.track("audit_log_screen", {
          Screen: "Diagnostics - Audit Log",
          "Hora inicio": initDate.toISOString(),
          "Hora fin": finishDate.toISOString(),
          Usuario: userName || null,
          Terminal: terminal || null,
        });
      }
    } catch (error: any) {
      setLoading(false);
      setLogData(null);
      console.log(error.message);
    }
  };

  const history = useHistory();
  const { role } = useContext(RoleContext);

  const { keycloak } = useKeycloak();
  useEffect(() => {
    if (role !== "Administrador" && role !== "SoporteL3") {
      history.push("/home");
    }
  }, [role]);

  useEffect(() => {
    setLoading(true);
    fetchAuditLogs(fetchPage, initDate, finishDate, queryUser, queryTerminal);
  }, [fetchPage]);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const validateEmpty = queryUser == "" && queryTerminal == "" ? true : false;

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setFinishDate(new Date());
    setInitDate(weekBefore);
    setQueryUser("");
    setQueryTerminal("");
  };

  return (
    <Grid
      className={styles.root}
      style={{
        paddingLeft: drawerOpen
          ? windowSize.width < 1200
            ? "5rem"
            : "17rem"
          : "5rem",
      }}
    >
      <Card
        elevation={0}
        sx={{
          ...style,
          flexGrow: 1,
          boxShadow: "none"
        }}
      >
        <Typography
          style={{
            fontSize: "30px",
            paddingTop: "0.5em",
            color: colors.primary,
          }}
        >
          Reportes de Auditoría
        </Typography>
        <Grid container spacing={2} paddingTop=".8rem">
          <Grid item xs={12} xl={12}>
            <Card className={styles.cardInternal}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <Grid
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid style={{ paddingRight: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={
                            <span style={{ color: colors.grayText }}>
                              Fecha desde
                            </span>
                          }
                          value={initDate}
                          inputFormat={"dd/MM/yyyy"}
                          minDate={new Date("2020-01-01")}
                          maxDate={dayBefore}
                          onChange={(newValue: any) => {
                            setInitDate(newValue);
                            setFiltered(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              variant="standard"
                              onKeyDown={onKeyDown}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid style={{ paddingRight: "1rem" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={
                            <span style={{ color: colors.grayText }}>
                              Fecha hasta
                            </span>
                          }
                          value={finishDate}
                          inputFormat={"dd/MM/yyyy"}
                          minDate={dayAfter}
                          maxDate={new Date()}
                          onChange={(newValue: any) => {
                            setFinishDate(newValue);
                            setFiltered(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              variant="standard"
                              onKeyDown={onKeyDown}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <TextField
                      id="card-textfield-user"
                      type="text"
                      variant="standard"
                      label="Nombre de usuario"
                      className="search"
                      value={queryUser}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryUser(e.target.value);
                        setFiltered(false);
                      }}
                    />
                    <TextField
                      id="card-textfield-terminal"
                      type="tel"
                      inputProps={{ maxLength: 8 }}
                      variant="standard"
                      label="Número de terminal"
                      className="search"
                      value={queryTerminal}
                      style={{ marginLeft: "3rem" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setQueryTerminal(e.target.value);
                        setFiltered(false);
                      }}
                    />
                    <Grid style={{ paddingRight: "3rem" }}>
                      {filtered && !validateEmpty ? (
                        <IconButton
                          color="primary"
                          style={{ width: "10em" }}
                          className={styles.buttomFilter}
                          onClick={() => (
                            handleReset(),
                            setFiltered(false),
                            //mixpanel Carga de Apps install
                            mixpanel.track("apply_filter_audit_log", {
                              Screen:
                                "Diagnostics - Audit Logs - Clear filters",
                              "Hora inicio": initDate.toISOString(),
                              "Hora fin": finishDate.toISOString(),
                              Usuario: null,
                              Terminal: null,
                            })
                          )}
                        >
                          Borrar filtros
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : !loading ? (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                          onClick={() => (
                            setFetchPage(1),
                            fetchAuditLogs(
                              fetchPage,
                              initDate,
                              finishDate,
                              queryUser,
                              queryTerminal
                            ),
                            setFiltered(true)
                          )}
                        >
                          Aplicar
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                        >
                          <div
                            className="rotateIcon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "2rem",
                              height: "2rem",
                            }}
                          >
                            <LoadingIcon />
                          </div>
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </ErrorBoundary>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} xl={12}>
            <ErrorBoundary>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  style={{
                    background: colors.background,
                    borderRadius: "10px",
                    minWidth: "1000px",
                    height: "1000px",
                  }}
                  width="100%"
                  height="98%"
                ></Skeleton>
              ) : logData ? (
                <Card className={styles.cardTable}>
                  <Grid className={styles.gridInternal}>
                    <ErrorBoundary>
                      <TableCustom
                        state={logData?.events}
                        fetchPage={fetchPage}
                        setFetchPage={setFetchPage}
                        total_elements={logData?.totalEvents}
                        total_pages={logData?.totalPages}
                      />
                    </ErrorBoundary>
                  </Grid>
                </Card>
              ) : (
                <AlertMessage
                  message={"¡No se encontraron eventos!"}
                  customStyle={{
                    paddingTop: "8rem",
                    paddingBottom: "2rem",
                  }}
                />
              )}
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
export default StickyHeadTable;
