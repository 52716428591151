import {
  SET_DIAGNOSTICS_OPEN,

  SET_UPDATE_TABS,
  SET_UPDATE_FILTER_APPS,
  SET_UPDATE_SETTING_SKELETON,
  SET_UPDATE_DIAGNOSTICS_SKELETON,
  SET_UPDATE_LOADCOMPONENTS,

  SET_UPDATE_ALERT_DIAGNOSTICS,
  SET_UPDATE_ALERT_DIAGNOSTICS_CODE,

  SET_UPDATE_ALERT_SETTINGS,
  SET_UPDATE_ALERT_SETTINGS_CODE
} from "context/types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_DIAGNOSTICS_OPEN:
      return {
        ...state,
        diagnosticsOpen: payload,
      };
    case SET_UPDATE_TABS:
      return {
        ...state,
        tab: payload,
      };
    case SET_UPDATE_FILTER_APPS:
      return {
        ...state,
        apps_install: payload,
      };
    case SET_UPDATE_DIAGNOSTICS_SKELETON:
      return {
        ...state,
        diagnosticsSkeleton: payload,
      };
    case SET_UPDATE_SETTING_SKELETON:
      return {
        ...state,
        settingsSkeleton: payload,
      };
    case SET_UPDATE_LOADCOMPONENTS:
      return {
        ...state,
        loadComponents: payload,
      };
    case SET_UPDATE_ALERT_SETTINGS:
      return {
        ...state,
        alertErrorSettings: payload,
      };
    case SET_UPDATE_ALERT_SETTINGS_CODE:
      return {
        ...state,
        errorCodeAlertSettings: payload,
      };
    case SET_UPDATE_ALERT_DIAGNOSTICS:
      return {
        ...state,
        alertErrorDiagnostics: payload,
      };
    case SET_UPDATE_ALERT_DIAGNOSTICS_CODE:
      return {
        ...state,
        errorCodeAlertDiagnostics: payload,
      };
    default:
      return state;
  }
};
