import { SET_UPDATE_DATA } from "context/types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_UPDATE_DATA:
      return {
        ...state,
        updateData: payload,
      };
    default:
      return state;
  }
};
