import React, { useEffect, useContext, useState, useCallback } from "react";
import PagesContainer from "components/pagesContainer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

import ButtonGuardar from "components/elements/button";
import colors from "constants/colors";
import SkeletonToken from "components/skeletons/skeletonToken";
import AlertCustom from "components/alert";
//
import RenderOnRole from "helpers/RenderOnRole";
//Context
import RoleContext from "context/userRole/RoleContext";
//Service
import TerminalService from "services/terminal.service";
import { useHistory, Prompt } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import TokenScreen from "../token";
import { format } from "date-fns";
import Progress from "components/elements/progress";
import CustomizedSnackbars from "components/elements/customizedSnackbars";
import { ReactComponent as WarningPrimary } from "assets/svg/warningPrimary.svg";
//mixPanel
import MixPanelService from "services/mixpanel.service";
import mixpanel from "mixpanel-browser";

const ApiToken = (props: any) => {

  const history = useHistory();
  const { role } = useContext(RoleContext);

  //const { showExitPrompt, setShowExitPrompt } = useContext(TokenScreenContext);

  const { keycloak } = useKeycloak();
  useEffect(() => {
    if (role !== "Administrador") {
      history.push("/home");
    }
  }, [role]);

  const [securityToken, setSecurityToken] = useState<object>();
  const [loadSecurityToken, setLoadSecurityToken] = useState<boolean>(true);
  const [alertSecurityToken, setAlertLoadSecurityToken] =
    useState<boolean>(false);
  const [errorCodeAlert, setErrorCodeAlert] = useState<string>("");
  const [loadSecurityTokenPost, setLoadSecurityTokenPost] =
    useState<boolean>(false);

  const [valueDate, setValueDate] = useState<Date | null>();

  const [customizedSnackbar, setCustomizedSnackbar] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<string>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const [dataLoadRetry, setDataLoadRetry] = useState<any>();

  const userInfo = keycloak?.idTokenParsed;

  //SecurityToken get
  const fetchSecurityTokenGet = async () => {
    setAlertLoadSecurityToken(false);
    setLoadSecurityToken(true);
    try {
      const res = await TerminalService.security_token_get();
      if (res.status === 200) {
        setLoadSecurityToken(false);
        setSecurityToken(res.data);
        let datePartsAux = res.data.expiration_token_date.split("-");
        let dateParts =
          datePartsAux &&
          new Date(+datePartsAux[2], datePartsAux[1] - 1, +datePartsAux[0]);
        setValueDate(dateParts);
      }
    } catch (error: any) {
      console.log("error", error);
      setLoadSecurityToken(false);
      setAlertLoadSecurityToken(true);
      setErrorCodeAlert(error?.response?.status || error?.message);
    }
  };

  //SecurityToken post
  const fetchSecurityTokenPost = async (data: any) => {
    //mixpanel Token de Seguridad
    MixPanelService.sendDataMixPanel(
      "save_button_token",
      "Diagnostics - Token de Seguridad",
      role
    );

    setDataLoadRetry(data);
    setLoadSecurityTokenPost(true);
    const sendData = {
      ...data,
      expiration_token_date: format(data?.expiration_token_date, "dd-MM-yyyy"),
    };

    try {
      const res = await TerminalService.security_token_post(sendData);
      if (res.status === 200) {
        setValueDate(null);
        setSnackbarState("success");
        setSnackbarMessage("Los cambios han sido guardados con éxito");
        setLoadSecurityTokenPost(false);
        setCustomizedSnackbar(true);
        setIsBlocking(false);
        //load token
        return fetchSecurityTokenGet();
      }
    } catch (error: any) {
      setSnackbarState("error");
      setSnackbarMessage(
        `No se pudo guardar los cambios por ${error?.response?.status || error?.message
        }`
      );
      setLoadSecurityTokenPost(false);
      setCustomizedSnackbar(true);
    }
  };

  useEffect(() => {
    fetchSecurityTokenGet();
    //mixpanel Token de Seguridad
    mixpanel.track("security_token_screen", {
      Screen: "Diagnostics - Paystore Token Screen",
    });
  }, []);

  const initRetry = () => {
    fetchSecurityTokenPost(dataLoadRetry);
  };

  const [isBlocking, setIsBlocking] = useState(false);
  let [showModal, setShowModal] = useState(false);

  //ocultar modal
  const handleClose = () => {
    setShowModal(false);
    setIsBlocking(false);
    //history.push("/home")
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Grid>
        {loadSecurityTokenPost && (
          <div
            style={{
              background: "rgba(255, 255, 255, 0.8)",
              top: 0,
              borderTopRightRadius: "8px",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Progress />
          </div>
        )}
        <Prompt
          when={isBlocking}
          message={(location, action) => {
            action === "PUSH" && isBlocking && setShowModal(true);
            return false;
          }}
        />
        <Modal
          hideBackdrop
          open={showModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          sx={{
            background: "rgba(67,68,76,  0.8)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <WarningPrimary style={{ paddingTop: "3rem" }} />
            <Typography
              style={{
                paddingTop: "2rem",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "23px",
                color: colors.primary,
              }}
            >
              No guardaste los cambios, al salir se perderán
            </Typography>
            <Grid
              style={{
                paddingTop: "10rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ButtonGuardar
                onClick={handleClose}
                tittle="Deshacer cambios"
                customStyle={{
                  width: "328px",
                  height: "48px",
                  fontWeight: 400,
                  fontSize: "1rem",
                }}
              />
              <Button
                style={{
                  marginTop: "1rem",
                  width: "328px",
                  height: "48px",
                  borderRadius: "24px",
                  border: `2px solid ${colors.primary}`,
                  textTransform: "capitalize",
                  color: colors.primary,
                  fontWeight: 400,
                  fontSize: "1rem",
                }}
                onClick={handleCloseModal}
                variant="outlined"
              >
                Volver
              </Button>
            </Grid>
          </Box >
        </Modal>
        <PagesContainer>
          <RenderOnRole roles={["Administrador"]}>
            {customizedSnackbar && (
              <CustomizedSnackbars
                severity={snackbarState}
                message={snackbarMessage}
                setCustomizedSnackbars={setCustomizedSnackbar}
                onClick={initRetry}
              />
            )}
            <Grid style={{ minHeight: "100%", width: "100%", display: "flex" }}>
              {loadSecurityToken ? (
                <SkeletonToken />
              ) : alertSecurityToken ? (
                <Grid style={{ paddingBottom: "15rem", width: "100%" }}>
                  <AlertCustom
                    message_error={errorCodeAlert}
                    message_error_description={errorCodeAlert}
                    onClick2={() => fetchSecurityTokenGet()}
                  />
                </Grid>
              ) : (
                valueDate && (
                  <TokenScreen
                    securityToken={securityToken}
                    valueDate={valueDate}
                    setValueDate={setValueDate}
                    emailPrimary={userInfo && userInfo?.email}
                    fetchSecurityTokenPost={fetchSecurityTokenPost}
                    setIsBlocking={setIsBlocking}
                    isBlocking={isBlocking}
                  />
                )
              )}
            </Grid>
          </RenderOnRole>
        </PagesContainer>
      </Grid >
    </>
  );
};

export default ApiToken;

