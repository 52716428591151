/* eslint-disable no-constant-condition */
import React, { useState, useEffect, useContext, useCallback } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//import TextField from "@mui/material/TextField";
//Prevented Error Component
import ErrorBoundary from "components/errorBoundary";

import { ReactComponent as BackButton } from "assets/svg/back_button.svg";
import Resumen from "../tabResumen/TabResumen";
import Settings from "../tabSetting/TabSettings";
import Transactions from "../tabTransactions/TabTransactions";
import LocalErrors from "../tabLocalErrors/TabLocalErrors";
import Devices from "../tabDevices/TabDevices";
import TestTab from "../tabTest/TabTest";
import Apps from "../tabApps/TabApps";
import TabScrollButton from "@mui/material/TabScrollButton";
import Button from "components/elements/button";
import Progress from "components/elements/progress";
import SnackBar from "components/elements/customizedSnackbars";
import RenderOnRole from "helpers/RenderOnRole";

import useStyles from "./DiagnosticsStyles";
import colors from "constants/colors";
import { withStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
//mixpanel
import mixpanel from "mixpanel-browser";
import { format /* , sub */, max, parseISO } from "date-fns";
import moment from "moment";
//Context
import RoleContext from "context/userRole/RoleContext";
import TabsContext from "context/pages/home/diagnostics/TabsContext";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import FilterTransactionContext from "context/pages/home/diagnostics/tabTransactions/FilterTransactionContext";

import AlertCustom from "components/alert";
//Service
import TerminalService from "services/terminal.service";
import MixPanelService from "services/mixpanel.service";

import Skeleton from "components/skeletons/skeletonTabTransactions";
import SkeletonTab from "components/skeletonTab";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import { useWindowSize } from "hooks/useWindowSize";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className={classes.TabPanelStyles}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight: "100%", height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

function tabsProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
    color: colors.cyanDark,
    "& svg": {
      fontSize: 40,
    },
  },
}))(TabScrollButton);

type Props = {
  terminalData?: object;
  params_merchant: string;
  params_terminal: string;
  firstCallback?: any;
  fetchQrData?: any;
  fetchVersionStatus?: any;
  fetchLocalErrors?: any;
};
const Diagnostics: React.FC<Props> = ({
  terminalData,
  params_merchant,
  params_terminal,
  firstCallback,
}) => {
  const history = useHistory();
  const classes = useStyles();
  //Context
  const { role } = useContext(RoleContext);
  const {
    diagnosticsOpen,
    setUpdateTab,
    tabPosition,
    loadComponents,
    alertErrorSettings,
    errorCodeAlertSettings,
    alertErrorDiagnostics,
    errorCodeAlertDiagnostics,
  } = useContext(TabsContext);
  const { drawerOpen } = useContext(OpenDrawerContext);
  const {
    transactionSkeleton,
    alertErrorTransactions,
    errorCodeAlertTransactions,
  } = useContext(FilterTransactionContext);

  const windowSize = useWindowSize();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setUpdateTab(newValue);
  };
  const [progressState, setProgressState] = useState<boolean>(false);
  const [customizedSnackbar, setCustomizedSnackbar] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<string>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const [diagnostics, setDiagnostics] = useState<any>(null);
  const [settings, setSettings] = useState<any>(null);
  const [terminalsQrData, setTerminalsQrData] = useState<any>({});
  const [versionStatus, setVersionStatus] = useState<any>({});

  const validTID: any = localStorage.getItem("validTID") || null;
  const validMID: any = localStorage.getItem("validMID") || null;
  const validSN: any = localStorage.getItem("validSN") || null;

  const actualUrl = window.location.pathname;
  const correctTerminalId = actualUrl.includes(validTID) ? true : false;
  const correctMerchantId = actualUrl.includes(validMID) ? true : false;
  const correctSN = actualUrl.includes(validSN) ? true : false;

  useEffect(() => {
    if (localStorage.getItem("terminals_diagnostics")) {
      setDiagnostics(
        JSON.parse(localStorage.getItem("terminals_diagnostics") || "")
      );
    }
  }, [localStorage.getItem("terminals_diagnostics")]);

  useEffect(() => {
    if (localStorage.getItem("terminals_settings")) {
      setSettings(JSON.parse(localStorage.getItem("terminals_settings") || ""));
    }
  }, [localStorage.getItem("terminals_settings")]);

  useEffect(() => {
    if (localStorage.getItem("terminals_qr_data")) {
      const qrData = JSON.parse(
        localStorage.getItem("terminals_qr_data") || ""
      );
      setTerminalsQrData(qrData);
    }
  }, [localStorage.getItem("terminals_qr_data")]);

  useEffect(() => {
    if (localStorage.getItem("version_status")) {
      const apps = JSON.parse(localStorage.getItem("version_status") || "");
      setVersionStatus(apps);
    }
  }, [localStorage.getItem("version_status")]);

  //ROLES
  const userRole = role;
  const [terminalDataAux] = useState<any>(terminalData);
  const remoteInitialization = async () => {
    setProgressState(true);
    MixPanelService.sendDataMixPanel(
      "remote_init_button",
      "Diagnostico - Diagnostico de terminal",
      userRole as string
    );
    try {
      ///terminal msj error
      if (terminalDataAux?.terminal === "12000001") throw new Error();

      const res = await TerminalService.remote_initialization(
        terminalDataAux.merchant as number,
        terminalDataAux.terminal as number
      );
      if (res.status === 202) {
        setSnackbarState("success");
        setSnackbarMessage(
          "Solicitud enviada. La terminal se inicializará luego de la siguiente venta aprobada."
        );
        setCustomizedSnackbar(true);
        MixPanelService.sendDataMixPanel(
          "remote_init_success",
          "Diagnostics - Diagnostico de terminal",
          userRole as string
        );
      } else {
        setSnackbarState("error");
        setSnackbarMessage(
          "Algo salío mal y no podemos mostrar la información en este momento."
        );
        setCustomizedSnackbar(true);
        MixPanelService.sendDataMixPanel(
          "remote_init_error",
          "Diagnostics - Diagnostico de terminal",
          userRole as string
        );
      }
      setProgressState(false);
    } catch (error) {
      console.log(":", error);
      setSnackbarState("error");
      setSnackbarMessage(
        "Algo salío mal y no podemos mostrar la información en este momento."
      );
      setCustomizedSnackbar(true);
      MixPanelService.sendDataMixPanel(
        "remote_init_error",
        "Diagnostics - Diagnostico de terminal",
        userRole as string
      );
      setProgressState(false);
    }
  };
  const remoteInitRetry = () => {
    remoteInitialization();
    mixpanel.track("remote_init_retry", {
      Screen: "Diagnostics - Diagnostico de terminal",
    });
  };
  /**
   * switch analytics tab navigation
   */
  useEffect(() => {
    //
    // value is tab position number
    switch (tabPosition) {
      case 0:
        if (!diagnostics) {
          //mixpanel Carga de Resumen
          mixpanel.track("resumen_tab", {
            Screen: "Diagnostics - Terminal Diagnostics",
          });
        } else {
          mixpanel.track("configuracion_tab", {
            Screen: "Configuration - Terminal Diagnostics",
          });
        }
        break;
      default:
        break;
    }
  }, [tabPosition]);

  //pasar a hooks detected scroll
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const weekBefore = new Date();
  weekBefore.setDate(weekBefore.getDate() - 7);
  weekBefore.setHours(0, 0, 0, 0);

  const [terminalUserEvents, setTerminalUserEvents] = useState<any>([]);

  const fetchAuditLogs = async (dateFrom: Date, dateTo: Date) => {
    try {
      const res = await TerminalService.audit_log({
        current_page: 1,
        page_size: 50,
        date_from: dateFrom,
        date_to: dateTo.setHours(23, 59, 59, 0),
        user: null,
        terminal_id: validTID,
        merchant: validMID,
      });
      if (res.status === 200) {
        setTerminalUserEvents(res.data);
      }
    } catch (error: any) {
      setTerminalUserEvents([]);
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchAuditLogs(weekBefore, new Date());
  }, []);

  const specificPkgMessage =
    "La terminal no tiene configuración específica.\n ¿Deseas aplicarle la configuración por defecto?";

  const sendSpecificConfig = async () => {
    try {
      setProgressState(true);
      const res = await TerminalService.autogen_specific_package(
        validTID,
        validMID
      );
      if (res) {
        history.push("/home");
        history.push(
          `/home/terminal-dashboard/${validSN}/${validMID}/${validTID}`
        );
        //window.location.reload();
        setProgressState(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const retryButton = () => {
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "retry_button",
      "Diagnostics - Diagnostico de terminal",
      role
    );
  };

  return (
    <>
      {!correctTerminalId || !correctMerchantId || !correctSN ? (
        <Route path="/">
          <Redirect to="/home" />
        </Route>
      ) : null}
      {progressState && (
        <div
          style={{
            position: "fixed",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 10,
            //defaul top 91px
            top: !scrollPosition
              ? 91
              : 91 - scrollPosition >= 0
                ? 91 - scrollPosition
                : 0,
            border: "1px solid #C3CCDD",
            borderTopRightRadius: "8px",
            borderTopLeftRadius: "8px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Progress />
        </div>
      )}
      {customizedSnackbar && (
        <SnackBar
          severity={snackbarState}
          message={snackbarMessage}
          setCustomizedSnackbars={setCustomizedSnackbar}
          onClick={remoteInitRetry}
        ></SnackBar>
      )}
      <Grid
        className={classes.rootHeader}
        style={{
          paddingLeft: drawerOpen
            ? windowSize.width < 1200
              ? "4.5rem"
              : "16.5rem"
            : "4.5rem",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography className={classes.headerText}>
            <BackButton
              className={classes.backButton}
              onClick={() => {
                mixpanel.track("back_to_home_button", {
                  Screen: "Diagnostics - Resumen Tab",
                });
                history.push("/home");
              }}
            />
          </Typography>
          <Grid
            style={{
              height: 62,
              width: 3000,
              borderRadius: 44,
              backgroundColor: "rgba(216, 235, 255, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-beetween",
            }}
          >
            <Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-beetween",
                  flexDirection: "row",
                }}
              >
                <Grid
                  style={{
                    padding: "2em",
                  }}
                >
                  <Typography className={classes.textTittle}>
                    Terminal
                  </Typography>
                  <Typography className={classes.textValue}>
                    {terminalDataAux?.terminal ?? ""}
                  </Typography>
                </Grid>
                {terminalsQrData.branch ? (
                  <Grid
                    style={{
                      padding: "2em",
                    }}
                  >
                    <Typography className={classes.textTittle}>
                      Comercio
                    </Typography>
                    <Typography className={classes.textValue}>
                      {terminalsQrData.branch ?? ""}
                    </Typography>
                  </Grid>
                ) : null}
                {terminalsQrData.branch ? (
                  <Grid
                    style={{
                      padding: "2em",
                    }}
                  >
                    <Typography className={classes.textTittle}>CUIT</Typography>
                    <Typography className={classes.textValue}>
                      {terminalsQrData.cuit ?? ""}
                    </Typography>
                  </Grid>
                ) : null}

                <Grid
                  style={{
                    padding: "1.5em",
                  }}
                >
                  <Typography className={classes.textTittle}>
                    Merchant Paystore
                  </Typography>
                  <Typography className={classes.textValue}>
                    {terminalDataAux?.merchant ?? ""}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    padding: "2em",
                  }}
                >
                  <Typography className={classes.textTittle}>
                    Número serial
                  </Typography>
                  <Typography className={classes.textValue}>
                    {terminalDataAux?.serial ?? ""}
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            style={{
              padding: "0em 1em",
            }}
          >
            <RenderOnRole roles={["Administrador", "SoporteL3", "Operador"]}>
              <Button
                tittle={"Inicialización Remota"}
                customStyle={{
                  width: "12.875rem",
                  height: "3.5rem",
                  fontSize: "1rem",
                  fontWeight: 400,
                }}
                onClick={() => remoteInitialization()}
              />
            </RenderOnRole>
          </Grid>
        </Grid>
      </Grid>

      <div
        className={classes.rootTabs}
        style={{
          paddingLeft: drawerOpen
            ? windowSize.width < 1200
              ? "4.5rem"
              : "16.5rem"
            : "4.5rem",
        }}
      >
        <Tabs
          value={tabPosition}
          onChange={handleChange}
          variant="scrollable" //responsive scroll
          ScrollButtonComponent={MyTabScrollButton}
          //scrollButtons={true}
          //allowScrollButtonsMobile
          aria-label="simple tabs example"
          style={{ marginBottom: "-0.063rem", maxWidth: "100%" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <Tab
            className={classes.tabStyles}
            style={
              tabPosition === 0
                ? { backgroundColor: "" }
                : {
                  backgroundColor: "#afbcd5",
                  color: "#FFFFFF",
                }
            }
            label={
              <span
                style={
                  tabPosition === 0
                    ? { color: colors.primary }
                    : { color: colors.white }
                }
              >
                Resumen
              </span>
            }
            onClick={() =>
              //mixpanel Carga de Dispositivo
              mixpanel.track("resumen_tab", {
                Screen: "Diagnostics - Device Tab",
              })
            }
            {...tabsProps(tabPosition)}
          />
          <Tab
            className={classes.tabStyles}
            style={
              tabPosition === 1
                ? { backgroundColor: "" }
                : {
                  backgroundColor: "#afbcd5",
                  color: "#FFFFFF",
                }
            }
            label={
              <span
                style={
                  tabPosition === 1
                    ? { color: colors.primary }
                    : { color: colors.white }
                }
              >
                Configuración
              </span>
            }
            onClick={() =>
              //mixpanel Carga de Dispositivo
              mixpanel.track("configuraciones_tab", {
                Screen: "Diagnostics - Device Tab",
              })
            }
            {...tabsProps(tabPosition)}
          />
          <Tab
            className={classes.tabStyles}
            style={
              tabPosition === 2
                ? { backgroundColor: "" }
                : {
                  backgroundColor: "#afbcd5",
                  color: "#FFFFFF",
                }
            }
            label={
              <span
                style={
                  tabPosition === 2
                    ? { color: colors.primary }
                    : { color: colors.white }
                }
              >
                Transacciones
              </span>
            }
            onClick={() =>
              //mixpanel Carga de Dispositivo
              mixpanel.track("transacciones_tab", {
                Screen: "Diagnostics - Device Tab",
              })
            }
            {...tabsProps(tabPosition)}
          />
          <Tab
            className={classes.tabStyles}
            style={
              tabPosition === 3
                ? { backgroundColor: "" }
                : {
                  backgroundColor: "#afbcd5",
                  color: "#FFFFFF",
                }
            }
            label={
              <span
                style={
                  tabPosition === 3
                    ? { color: colors.primary }
                    : { color: colors.white }
                }
              >
                Historial de errores
              </span>
            }
            onClick={() =>
              //mixpanel Carga de Dispositivo
              mixpanel.track("errores_locales_tab", {
                Screen: "Diagnostics - Device Tab",
              })
            }
            {...tabsProps(tabPosition)}
          />
          {role === "Administrador"
            ? diagnosticsOpen && (
              <Tab
                className={classes.tabStyles}
                style={
                  tabPosition === 4
                    ? { backgroundColor: "" }
                    : {
                      backgroundColor: "#afbcd5",
                      color: "#FFFFFF",
                    }
                }
                label={
                  <span
                    style={
                      tabPosition === 4
                        ? { color: colors.primary }
                        : { color: colors.white }
                    }
                  >
                    Dispositivo
                  </span>
                }
                onClick={() =>
                  //mixpanel Carga de Dispositivo
                  mixpanel.track("device_tab", {
                    Screen: "Diagnostics - Device Tab",
                  })
                }
                {...tabsProps(tabPosition)}
              />
            )
            : null}
          {role === "Administrador"
            ? diagnosticsOpen && (
              <Tab
                className={classes.tabStyles}
                style={
                  tabPosition === 5
                    ? { backgroundColor: "" }
                    : {
                      backgroundColor: "#afbcd5",
                      color: "#FFFFFF",
                    }
                }
                label={
                  <span
                    style={
                      tabPosition === 5
                        ? { color: colors.primary }
                        : { color: colors.white }
                    }
                  >
                    Test
                  </span>
                }
                onClick={() =>
                  //mixpanel Carga de Test
                  mixpanel.track("test_tab", {
                    Screen: "Diagnostics - Test Tab",
                  })
                }
                {...tabsProps(tabPosition)}
              />
            )
            : null}
          {role === "Administrador"
            ? diagnosticsOpen && (
              <Tab
                className={classes.tabStyles}
                style={
                  tabPosition === 6
                    ? { backgroundColor: "" }
                    : {
                      backgroundColor: "#afbcd5",
                      color: "#FFFFFF",
                    }
                }
                label={
                  <span
                    style={
                      tabPosition === 6
                        ? { color: colors.primary }
                        : { color: colors.white }
                    }
                  >
                    Apps instaladas
                  </span>
                }
                onClick={() =>
                  //mixpanel Carga de Apps install
                  mixpanel.track("installed_apps_tab", {
                    Screen: "Diagnostics - Installed Apps Tab",
                  })
                }
                {...tabsProps(tabPosition)}
              />
            )
            : null}
        </Tabs>
        <TabPanel value={tabPosition} index={0}>
          {!loadComponents ? (
            <SkeletonTab />
          ) : diagnostics == null &&
            Object.keys(terminalsQrData).length === 0 ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertDiagnostics}
                message_error_description={
                  "No podemos mostrarte esta información. La terminal no realizó envío de diagnóstico y no tiene QR habilitado."
                }
                onClick2={() => firstCallback()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <Resumen
                terminalDiagnostics={diagnostics || null}
                initialState={terminalData || null}
                qrData={terminalsQrData || null}
                versionStatus={versionStatus || null}
                terminalEvents={terminalUserEvents || null}
              />
            </ErrorBoundary>
          )}
        </TabPanel>
        <TabPanel value={tabPosition} index={1}>
          {!loadComponents ? (
            <Skeleton />
          ) : alertErrorSettings ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertSettings}
                message_specific_pkg={specificPkgMessage}
                onClick2={() => sendSpecificConfig()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <Settings
                state={settings}
                merchant={params_merchant}
                terminal={params_terminal}
              />
            </ErrorBoundary>
          )}
        </TabPanel>
        <TabPanel value={tabPosition} index={2}>
          {!loadComponents ? (
            <Skeleton />
          ) : alertErrorTransactions ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertTransactions}
                message_error_description={errorCodeAlertTransactions}
                onClick2={() => firstCallback()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <Transactions />
            </ErrorBoundary>
          )}
        </TabPanel>
        <TabPanel value={tabPosition} index={3}>
          {!loadComponents ? (
            <Skeleton />
          ) : alertErrorDiagnostics ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertDiagnostics}
                message_error_description={errorCodeAlertDiagnostics}
                onClick2={() => firstCallback()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <LocalErrors />
            </ErrorBoundary>
          )}
        </TabPanel>

        <TabPanel value={tabPosition} index={4}>
          {!loadComponents ? (
            <Skeleton />
          ) : alertErrorDiagnostics ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertDiagnostics}
                message_error_description={errorCodeAlertDiagnostics}
                onClick2={() => firstCallback()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <RenderOnRole roles={["Administrador"]}>
                <Devices state={diagnostics?.device_information} />
              </RenderOnRole>
            </ErrorBoundary>
          )}
        </TabPanel>

        <TabPanel value={tabPosition} index={5}>
          {!loadComponents ? (
            <Skeleton />
          ) : alertErrorDiagnostics ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertDiagnostics}
                message_error_description={errorCodeAlertDiagnostics}
                onClick2={() => firstCallback()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <RenderOnRole roles={["Administrador"]}>
                <TestTab state={diagnostics?.communication_test} />
              </RenderOnRole>
            </ErrorBoundary>
          )}
        </TabPanel>
        <TabPanel value={tabPosition} index={6}>
          {!loadComponents ? (
            <Skeleton />
          ) : alertErrorDiagnostics ? (
            <div style={{ marginBottom: "10rem", marginTop: "-6rem" }}>
              <AlertCustom
                message_error={errorCodeAlertDiagnostics}
                message_error_description={errorCodeAlertDiagnostics}
                onClick2={() => firstCallback()}
              />
            </div>
          ) : (
            <ErrorBoundary>
              <RenderOnRole roles={["Administrador"]}>
                <Apps state={diagnostics?.apps_installed} />
              </RenderOnRole>
            </ErrorBoundary>
          )}
        </TabPanel>
        <div
          style={{
            marginTop: "auto",
            margin: "3em",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Rubik",
              fontSize: "0.85rem",
              fontWeight: 100,
              lineHeight: "1.25rem",
              fontStyle: "light",
              color: colors.gray3,
            }}
          >
            Dashboard Diagnóstico - Versión 1.4.2.2
          </Typography>
        </div>
      </div>
    </>
  );
};

export default React.memo(Diagnostics, (prevProps, nextProps) => {
  return prevProps.params_terminal === nextProps.params_terminal;
});
