import * as React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, rgbToHex, Switch, Divider } from "@mui/material";
import colors from "constants/colors";

type modalProps = {
  setOpen: boolean;
  title: string;
  description: any;
  loading: boolean;
  handleClose: any;
  action: any;
};

export default function DialogCustom(props: modalProps) {
  const longText = <div style={{
    whiteSpace: 'pre-line',
    fontSize: "16px",
    letterSpacing: "0.4px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    color: "#000000",
    padding: "0em 2em",
    textAlign: 'center'
  }}> {props.description} </div>;

  return (
    <Dialog
      open={props.setOpen}
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description">
      {!props.loading ? <DialogTitle color={colors.primary} sx={{ margin: "0 auto", padding: "1em 2em", fontSize: "20px" }}>{props.title}</DialogTitle> : null}
      {!props.loading ? <div>
        <DialogContent sx={{ margin: '0 auto' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {longText}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={props.handleClose} sx={{ width: "50%", margin: "1em", color: colors.primary }}>Cancelar</Button>
          <Button onClick={props.action} sx={{ width: "50%", margin: "1em", color: colors.primary }}>Aceptar</Button>
        </DialogActions>
      </div> :
        <div style={{ margin: "0 auto", padding: "4rem" }}>
          <CircularProgress />
        </div>
      }
    </Dialog>
  );
}
