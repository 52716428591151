import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        TableRow: {
            "&:hover": {
                backgroundColor: colors.backgroundHover,
            },
        },
    })
);

export default useStyles;
