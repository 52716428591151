import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Button from "components/elements/button";

import { ReactComponent as AlertDanger } from "assets/svg/alert_danger.svg";
import colors from "constants/colors";

type Props = {
  message?: string;
  message_error?: string;
  message_error_description?: string;
  message_specific_pkg?: string;
  onClick2?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickBack?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customStyle?: object;
};

const Progress: React.FC<Props> = ({
  message,
  message_error,
  message_error_description,
  message_specific_pkg,
  onClick2,
  onClickBack,
  customStyle,
}) => {
  return (
    <Grid
      style={{
        paddingTop: "10rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        ...customStyle,
      }}
    >
      <AlertDanger />
      <Typography
        style={{
          paddingTop: "2.625rem",
          fontSize: "1rem",
          fontWeight: 600,
          lineHeight: "1.625rem",
          fontFamily: "Rubik",
          color: colors.grayText,
        }}
      >
        {message ? message : "¡Algo salió mal!"}
      </Typography>
      <Typography
        style={{
          paddingTop: "2rem",
          fontSize: "1rem",
          fontWeight: 400,
          fontFamily: "Rubik",
          lineHeight: "1.625rem",
          color: colors.grayText,
        }}
      >
        Error {message_error}
      </Typography>
      <Typography
        style={{
          paddingTop: "1.625rem",
          fontSize: "1rem",
          fontWeight: 400,
          fontFamily: "Rubik",
          lineHeight: "1.625rem",
          color: colors.grayText,
        }}
      >
        {message_specific_pkg
          ? message_specific_pkg
          : `${message_error_description}`}
      </Typography>
      <Grid style={{ paddingTop: "2.625rem" }}>
        <Button
          tittle={
            message_specific_pkg ? "Enviar configuración" : "Intentar de nuevo"
          }
          onClick={onClick2}
          customStyle={{
            width: "20.5rem",
          }}
        />
      </Grid>
      <Grid style={{ paddingTop: ".5rem" }}>
        {onClickBack && (
          <Button
            tittle="Volver"
            onClick={onClickBack}
            customStyle={{
              width: "20.5rem",
              backgroundColor: colors.white,
              color: colors.primary,
              border: `3px solid ${colors.primary}`,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Progress;
