import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import useStyles from "./ResumenStyles";
import { ReactComponent as NotLatLon } from "assets/svg/not_lat_lon.svg";
import { compareVersions } from "compare-versions";
import AppsInstalled from "./AppsInstalled";
import UserEvents from "./UserEvents";
import GeneralState from "./GeneralState";
import QrData from "./QRData/QrData";
import { format /* , sub */, parseISO } from "date-fns";
//Prevented Error Component
import ErrorBoundary from "components/errorBoundary";
import General from "./general";
import AlertMessage from "components/alertMessage";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import mixpanel from "mixpanel-browser";

interface Provider {
  terminalDiagnostics: any;
  initialState: any;
  qrData: any;
  versionStatus: any;
  terminalEvents: any;
}
const Resumen = ({
  terminalDiagnostics,
  qrData,
  versionStatus,
  terminalEvents,
}: Provider) => {
  const styles = useStyles();

  //lat and log undefined values for default replace for 0
  const latitude = terminalDiagnostics?.device_information?.latitude || 0;
  const longitude = terminalDiagnostics?.device_information?.longitude || 0;
  const bbox = `${longitude - 0.1}%2C${latitude - 0.1}%2C${longitude + 0.1}%2C${
    latitude + 0.1
  }`;
  const url = `https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&layer=mapnik&marker=${latitude}%2C${longitude}`;

  //almacenamiento
  let storageFree = parseFloat(
    terminalDiagnostics?.device_information?.storageFree
      .split("GB")[0]
      .replace(",", ".")
  );

  let storageSize = parseFloat(
    terminalDiagnostics?.device_information?.storageSize
      .split("GB")[0]
      .replace(",", ".")
  );

  //memoria
  let memoryFree = parseFloat(
    terminalDiagnostics?.device_information?.memoryFree
      .split("MB")[0]
      .replace(",", ".")
  );
  let memoryTotal = parseFloat(
    terminalDiagnostics?.device_information?.memorySize
      .split("MB")[0]
      .replace(",", ".")
  );

  //batteryLevel
  let batteryLevel =
    terminalDiagnostics?.device_information?.batteryLevel.toString() || 0;

  let apps_installed = terminalDiagnostics?.apps_installed;
  let gemeral_state = terminalDiagnostics?.health;

  let firmware = terminalDiagnostics?.device_information?.firmware || "-";

  if (gemeral_state != undefined) {
    delete gemeral_state.camera;
    delete gemeral_state.app_store;
    delete gemeral_state.paystore;
    delete gemeral_state.percentage;
  }

  const cardBrands = [
    {
      id: "1",
      brand: "VISA",
    },
    { id: "24", brand: "Naranja Crédito" },
    { id: "31", brand: "VISA DEBITO" },
    { id: "44", brand: "Carrefour Crédito" },
    { id: "63", brand: "Cabal Prisma" },
    { id: "65", brand: "Amex" },
    { id: "104", brand: "MASTERCARD" },
    { id: "105", brand: "MASTERCARD Débito Prisma" },
    { id: "106", brand: "Maestro Prisma" },
    { id: "108", brand: "Cabal Débito Prisma" },
    { id: "111", brand: "Amex Crédito Prisma" },
    { id: "112", brand: "Transferencia 3.0" },
    { id: "139", brand: "Discover Crédito" },
  ];

  if (qrData.payment_method_data && cardBrands) {
    for (let i = 0; i < qrData.payment_method_data.length; i++) {
      for (let j = 0; j < cardBrands.length; j++) {
        if (
          qrData.payment_method_data[i].payment_method_id === cardBrands[j].id
        ) {
          qrData.payment_method_data[i] = {
            ...qrData.payment_method_data[i],
            cardBrand: cardBrands[j].brand,
          };
        }
      }
    }
  }

  interface objectData {
    name: string;
    value: number;
  }

  const matched: any = [];

  if (apps_installed != undefined) {
    versionStatus.published != undefined
      ? apps_installed.forEach((app: any) => {
          versionStatus.published.forEach((v: any) => {
            if (app.packageName === v.packageName) {
              const versStatus: number = compareVersions(
                app.version,
                v.version
              );
              switch (versStatus) {
                case 0:
                  matched.push({ ...app, status: 1 });
                  break;
                case -1:
                  matched.push({ ...app, status: 0 });
                  break;
                case 1:
                  versionStatus.pilots.forEach((pilotsToIterate: any) => {
                    if (app.packageName === pilotsToIterate.packageName) {
                      for (
                        let i = 0;
                        i < pilotsToIterate.versions.length;
                        i++
                      ) {
                        if (
                          compareVersions(
                            app.version,
                            pilotsToIterate.versions[i]
                          ) === 0
                        ) {
                          matched.push({ ...app, status: 2 });
                          break; // Sí se encuentra coincidencia se interrumpe el ciclo pilots versions
                        } else if (i === pilotsToIterate.versions.length - 1) {
                          matched.push({ ...app, status: 3 }); // Si es el último item del cliclo pilots versionsssss
                        }
                      }
                    }
                  });
                  break;
              }
            }
          });
        })
      : apps_installed.forEach((app: any) => {
          if (
            app.packageName.includes("phoebus") ||
            app.packageName.includes("prisma") ||
            app.packageName.includes("smartlapos")
          ) {
            matched.push({ ...app, status: 4 });
          }
        });
  }

  const [openDiagnosticAlert, setOpenDiagnosticAlert] = React.useState(true);
  const [openQrAlert, setOpenQrAlert] = React.useState(true);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDiagnosticAlert(false);
    setOpenQrAlert(false);
  };

  useEffect(() => {
    mixpanel.track("terminal_data", {
      TerminalID: localStorage.getItem("validTID") || "No disponible",
      SerialNumber: localStorage.getItem("validSN") || "No disponible",
      Geolocalización: {
        lat: latitude || "No disponible",
        lon: longitude || "No disponible",
      },
      Carrier:
        terminalDiagnostics.device_information.networkOperator ||
        "No disponible",
      ICC: terminalDiagnostics.device_information.iccid || "No disponible",
      Diagnóstico: terminalDiagnostics?.createdAt || "No disponible",
      Modelo:
        terminalDiagnostics?.device_information?.deviceManufacturer.toUpperCase() +
          " " +
          terminalDiagnostics?.device_information?.deviceName ||
        "No disponible",
    });
  }, []);

  return (
    <>
      {terminalDiagnostics != undefined ? null : openDiagnosticAlert ? (
        <Card className={styles.noQRalert} elevation={0}>
          <div
            style={{
              backgroundColor: "#FF0F0F",
              width: "8px",
              height: "100px",
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={10} xl={10}>
              <p
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <InfoIcon style={{ padding: "0em 1em", color: "#FF0F0F" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <Typography
                    fontWeight={600}
                    style={{ marginBottom: "0.5em" }}
                  >
                    Diagnóstico de terminal no disponible
                  </Typography>
                  <Typography>
                    No podemos mostrarte la información ya que la terminal no
                    realizó envió diagnóstico.
                  </Typography>
                </div>
              </p>
            </Grid>
            <Grid item xs={2} xl={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "start",
                  padding: "0.5em",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Card>
      ) : null}
      {qrData.branch != undefined ? null : openQrAlert ? (
        <Card className={styles.noQRalert} elevation={0}>
          <div
            style={{
              backgroundColor: "#2186EB",
              width: "8px",
              height: "100px",
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={10} xl={10}>
              <p
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <InfoIcon style={{ padding: "0em 1em", color: "#2186EB" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <Typography
                    fontWeight={600}
                    style={{ marginBottom: "0.5em" }}
                  >
                    Información de QR no disponible
                  </Typography>
                  <Typography>
                    No podemos mostrarte la información de QR para esta
                    terminal. Si necesitas habilitarlo, enviá un ticket al área
                    correspondiente.
                  </Typography>
                </div>
              </p>
            </Grid>
            <Grid item xs={2} xl={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "start",
                  padding: "0.5em",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Card>
      ) : null}
      {terminalDiagnostics != undefined ? (
        <Card className={styles.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={4} xl={4}>
              <Card className={styles.cardInternal}>
                <Grid className={styles.gridInternal}>
                  <General
                    deviceManufacturer={
                      terminalDiagnostics?.device_information
                        ?.deviceManufacturer
                    }
                    deviceName={
                      terminalDiagnostics?.device_information?.deviceName
                    }
                    createdAt={terminalDiagnostics?.createdAt}
                    ICC={terminalDiagnostics?.device_information?.iccid}
                    operadora={
                      terminalDiagnostics?.device_information?.networkOperator
                    }
                    storageSize={storageSize}
                    storageFree={storageFree}
                    memorySize={memoryTotal}
                    memoryFree={memoryFree}
                    battery={batteryLevel}
                    firmware={firmware}
                  />
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4} xl={4}>
              <Card className={styles.cardInternal}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "1em",
                    paddingLeft: "2em",
                  }}
                >
                  <Typography
                    className={styles.textTittle}
                    style={{
                      paddingTop: ".8em",
                      paddingLeft: ".4em",
                    }}
                  >
                    Estado general
                  </Typography>
                </div>
                <Grid className={styles.gridInternalGeneral}>
                  {gemeral_state && (
                    <ErrorBoundary>
                      <GeneralState gemeral_state={gemeral_state} />
                    </ErrorBoundary>
                  )}
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4} xl={4}>
              <Card className={styles.cardInternal}>
                <Grid className={styles.gridInternal}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      className={styles.textTittle}
                      style={{
                        paddingTop: ".4em",
                        paddingLeft: ".4em",
                      }}
                    >
                      Ubicación
                    </Typography>
                  </div>
                  <Typography className={styles.latlon}>
                    Latitud: {latitude} - Longitud: {longitude}
                  </Typography>
                  {longitude !== 0 && latitude !== 0 ? (
                    <iframe
                      title="map"
                      className={styles.mapView}
                      width="100%"
                      src={url}
                      style={{ left: "0px", paddingBottom: ".5em" }}
                    ></iframe>
                  ) : (
                    <Card className={styles.notLatLon}>
                      <NotLatLon />
                    </Card>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
      ) : null}

      {qrData.branch != undefined ? (
        <Card className={styles.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <Card className={styles.cardInternalQR}>
                <Grid className={styles.gridInternal}>
                  <QrData
                    branch={qrData.branch}
                    checkout={qrData.checkout}
                    terminalNumber={qrData.terminal_number}
                    cbu={qrData.cbu}
                    fieldOfActivity={qrData.field_of_activity}
                    paymentMethodData={qrData.payment_method_data}
                    fechaAlta={
                      qrData.fecha_alta
                        ? format(
                            parseISO(qrData.fecha_alta),
                            "dd/MM/yyyy - HH:mm"
                          )
                        : "-"
                    }
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
      ) : null}

      {terminalDiagnostics != undefined ? (
        <Card className={styles.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={6} xl={6}>
              <Card className={styles.cardInternal}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "2em",
                    paddingLeft: "2em",
                  }}
                >
                  <Typography className={styles.textTittle}>
                    Apps Instaladas
                  </Typography>
                </div>
                <Grid className={styles.gridInternal}>
                  <ErrorBoundary>
                    {apps_installed != undefined ? (
                      <AppsInstalled
                        apps={{ installed: apps_installed, matched: matched }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AlertMessage />
                        <Typography className={styles.cardDataText}>
                          Lo sentimos, no podemos mostrar esta información
                          ahora.
                        </Typography>
                      </div>
                    )}
                  </ErrorBoundary>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={6} xl={6}>
              <Card className={styles.cardInternal}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "2em",
                    paddingLeft: "2em",
                  }}
                >
                  <Typography className={styles.textTittle}>
                    Acciones de usuarios
                  </Typography>
                </div>
                <Grid className={styles.gridInternal}>
                  {terminalEvents && (
                    <ErrorBoundary>
                      <UserEvents events={terminalEvents} />
                    </ErrorBoundary>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
      ) : null}
    </>
  );
};

export default React.memo(Resumen);
