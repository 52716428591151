/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { useContext } from "react";
import clsx from "clsx";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { format /* , sub */, parseISO } from "date-fns";

import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import colors from "constants/colors";
//Context
import TabsContext from "context/pages/home/diagnostics/TabsContext";

const styles = (theme: Theme) =>
({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px",
    color: colors.grayText,
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      ...(theme.direction === "rtl" && {
        paddingLeft: "0 !important",
      }),
      ...(theme.direction !== "rtl" && {
        paddingRight: undefined,
      }),
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: colors.backgroundHover,
      maxWidth: "98.5%",
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
} as const);

interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width?: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: readonly ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 30,
    rowHeight: 58,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{
          height: rowHeight,
        }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        <p>{cellData}</p>
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span
          style={
            label === "Eventos de usuarios"
              ? {
                fontSize: "1.25rem",
                fontFamily: "Rubik",
                fontWeight: 500,
                lineHeight: "1.75rem",
              }
              : { fontFamily: "Rubik", fontSize: ".875rem", fontWeight: 500 }
          }
        >
          {label}
        </span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                  //new responsive enable
                  width={width}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(
  MuiVirtualizedTable
);
// ---
interface Data {
  id: number;
  date: string;
  user: string;
  action: string;
  product: string;
}
function createData(
  id: number,
  date: string,
  user: string,
  action: string,
  product: string
): Data {
  return { id, date, user, action, product };
}

const ReactVirtualizedTable = ({ events }: any) => {
  const rows: Data[] = [];
  const userEvents = events.events;

  if (userEvents.length != 0) {
    for (let i = 0; i < userEvents.length; i += 1) {
      let currentAction = "";
      let tefAcquirer = "";

      switch (userEvents[i].action) {
        case "REMOTE_INITIALIZATION":
          currentAction = "Solicitud de inicialización";
          break;
        case "UPDATE_PRODUCT":
          userEvents[i].finalStatus === "true"
            ? (currentAction = "Habilitación de tarjeta")
            : (currentAction = "Deshabilitación de tarjeta");
          break;
        case "SWITCH_CASHBACK":
          userEvents[i].finalStatus === "true"
            ? (currentAction =
              "Habilitación de cobro y retiro de efectivo")
            : (currentAction =
              "Deshabilitación de cobro y retiro de efectivo");
          break;
        case "SWITCH_API_MODE":
          userEvents[i].finalStatus === "true"
            ? (currentAction = "Habilitación de cobro con Modo API")
            : (currentAction = "Deshabilitación de cobro con Modo API");
          break;
        case "SWITCH_AUTOBATCHCLOSE":
          userEvents[i].finalStatus === "true"
            ? (currentAction = "Habilitación de cierre de lote automático")
            : (currentAction = "Deshabilitación de cierre de lote automático");
          break;
        case "SET_BATCHCLOSETIME":
          currentAction = `Modificación de horario de cierre de lote automático: ${userEvents[i].finalStatus}hs`;
          break;
        case "SWITCH_UNREFERENCED_REFUND":
          userEvents[i].finalStatus === "true"
            ? (currentAction = "Habilitación de devoluciones no referenciadas")
            : (currentAction =
              "Deshabilitación de devoluciones no referenciadas");
          break;
        case "UPDATE_TEF_ACQUIRER":
          currentAction = "Modificación de comercio";
          tefAcquirer = `${userEvents[i].productId === "13"
              ? "Prisma"
              : userEvents[i].productId === "14"
                ? "Amex"
                : null
            } - ${userEvents[i].finalStatus}`;
          break;
        default:
          currentAction = userEvents[i].action;
          break;
      }

      rows.push(
        createData(
          i,
          format(parseISO(userEvents[i].date), "dd/MM/yyyy - HH:mm"),
          userEvents[i].user,
          currentAction,
          !tefAcquirer ? userEvents[i].productId : tefAcquirer
        )
      );
    }
  }
  return (
    <Paper
      style={{
        height: 430,
        width: "100%",
        paddingBottom: "10em",
        boxShadow: "none",
        background: colors.background,
      }}
    >
      {!!events && (
        <VirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          // onRowClick={() => {
          //   setUpdateTab(3);
          // }}
          columns={[
            {
              label: "Fecha",
              dataKey: "date",
            },
            {
              label: "Usuario",
              dataKey: "user",
            },
            {
              label: "Evento",
              dataKey: "action",
            },
            {
              label: "ID Tarjeta",
              dataKey: "product",
            },
          ]}
        />
      )}
    </Paper>
  );
};
export default React.memo(ReactVirtualizedTable);
