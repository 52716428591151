import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            paddingRight: ".8rem",
            paddingBottom: ".5rem",
            boxShadow: "none",
            background: colors.background,
            height: "100%"
        },
        cardContent: {
            minHeight: "100%",
            height: "100%",
            flexDirection: "column",
            color: colors.primary,
            elevation: 0,
            boxShadow: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
        },
    })
);

export default useStyles;
