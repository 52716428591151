import React, { useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import RoleContext from "context/userRole/RoleContext";
import SecurityToken from "./security-token/main";
import Parameters from "./parameters";
import mixpanel from "mixpanel-browser";
import OpenDrawerContext from "context/pages/OpenDrawerContext";
import { useWindowSize } from "hooks/useWindowSize";

type Props = any;

const AdministrationPanel: React.FC<Props> = () => {
  const styles = useStyles();
  const windowSize = useWindowSize();

  const { role } = useContext(RoleContext);
  const { drawerOpen, setUpdateDrawer } = useContext(OpenDrawerContext);

  const [tabPosition, setTabPosition] = React.useState("tokenPaystore");

  const handleTabPosition = (event: React.SyntheticEvent, newValue: string) => {
    setTabPosition(newValue);
    mixpanel.track("Administration-Panel", {
      Screen: `Administration-Panel - ${tabPosition}`,
    });
  };

  return (
    <Grid
      className={styles.root}
      style={{
        paddingLeft: drawerOpen
          ? windowSize.width < 1200
            ? "5rem"
            : "17rem"
          : "5rem",
      }}
    >
      <Box sx={{ width: "max-content" }}>
        <Tabs
          value={tabPosition}
          onChange={handleTabPosition}
          className={styles.cardInternalTabs}
          sx={{ height: "3em", width: "max-content" }}
          aria-label="secondary tabs example"
        >
          <Tab
            value="tokenPaystore"
            label="Token Paystore"
            className={styles.textHeaderSub}
          />
           <Tab
            value="parameters"
            label="Parametría"
            className={styles.textHeaderSub}
          />
          {/*<Tab value="grid" label="Grilla" className={styles.textHeaderSub} /> */}
        </Tabs>
      </Box>
      {tabPosition === "tokenPaystore" ? (
        <SecurityToken />
      ) : tabPosition === "parameters" ? (
        <Parameters />
      ) : (
        <Parameters />
      )}
    </Grid>
  );
};
export default AdministrationPanel;
