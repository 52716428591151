import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//keyClock
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "configs/Keycloak";

const keycloakProviderInitConfig = { onLoad: "login-required" };

ReactDOM.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakProviderInitConfig}
    >
        <App />
    </ReactKeycloakProvider>,
    document.getElementById("root")
);
