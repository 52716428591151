import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    leftIconButton: {
      color: colors.primary,
    },
    rightIconButton: {
      color: colors.primary,
    },
    buttomFilter: {
      background: colors.primary,
      color: colors.white,
      borderRadius: "1.125rem",
      width: "6.125rem",
      height: "2.25rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: ".875rem",
      fontWeight: 400,
      lineHeight: "1.225rem",
      marginLeft: "1.125rem",
      paddingRight: "0.313rem",
      paddingLeft: "0.313rem",
      "&:hover": {
        backgroundColor: colors.primarydark,
      },
      "&:active": {
        backgroundColor: colors.cyan,
      },
    },
    filterAlign: {
      display: "flex",
      height: "100px",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default useStyles;
