import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
// ...
//import { helloSaga } from './validationTokenSagas'

import initSagas from "./sagas/initSagas";
//imports reducers
import src from "./src/reducers";
import home from "./home/reducers";
import auth from "./auth/reducers";

//combineReducers toma todos los reductores separaods y genera uno solo

const reducers = combineReducers({ src, home, auth });

const sagaMiddleware = createSagaMiddleware();

export const redux = createStore(reducers, applyMiddleware(sagaMiddleware));

initSagas(sagaMiddleware);

//
//sagaMiddleware.run(helloSaga)
