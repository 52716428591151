import React, { useEffect, useState, useContext, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
//Service
import TerminalService from "services/terminal.service";
import useStyles from "./TerminalDashboardStyles";
import Diagnostics from "./diagnostics";
//Context
import FilterTransactionContext from "context/pages/home/diagnostics/tabTransactions/FilterTransactionContext";
//test Data
//import dummyDataState from "./dummyData";
import MixPanelService from "services/mixpanel.service";
//mixpanel
import mixpanel from "mixpanel-browser";
//context
import RoleContext from "context/userRole/RoleContext";
import TabsContext from "context/pages/home/diagnostics/TabsContext";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className={classes.TabPanelStyles}
      {...other}
    >
      {value === index && (
        <Box sx={{ minHeight: "100%", height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

function tabsProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TerminalDashboard = (id_terminal: object) => {
  const { match }: any = id_terminal;
  const styles = useStyles();
  //context
  const { role } = useContext(RoleContext);

  const {
    diagnosticsOpen,
    setUpdateSettingsSkeleton,
    setUpdateDiagnosticsSkeleton,
    // setUpdateLocalErrorsSkeleton,
    setUpdateTab,
    setLoadComponents,
    //DIAGNOSTICS Alert
    setAlertErrorDiagnostics,
    setErrorCodeAlertDiagnostics,
    //Settings Alert
    setAlertErrorSettings,
    // setAlertLocalErrors,
    setErrorCodeAlertSettings,
    // setErrorCodeAlertLocalErrors,
  } = useContext(TabsContext);

  const validTID: any = localStorage.getItem("validTID") || null;
  const actualUrl = window.location.pathname;
  const correctTerminalId = actualUrl.includes(validTID) ? true : false;

  const terminalData = {
    serial: match?.params?.serial,
    merchant: match?.params?.merchant,
    terminal: match?.params?.id_terminal,
  };
  //ROLES
  const userRole = role;
  //Diagnostics
  const fetchTerminalDiagnostics = useCallback(async () => {
    if (correctTerminalId) {
      try {
        localStorage.setItem("terminals_diagnostics", JSON.stringify({}));
        const res = await TerminalService.terminal_diagnostics(
          terminalData.serial,
          terminalData.merchant,
          terminalData.terminal
        );
        if (res.status === 200) {
          localStorage.setItem(
            "terminals_diagnostics",
            JSON.stringify(res.data)
          );
          setUpdateDiagnosticsSkeleton(true);
        }
      } catch (error: any) {
        setAlertErrorDiagnostics(true);
        setErrorCodeAlertDiagnostics(error?.response?.status || error?.message);
        localStorage.setItem("terminals_diagnostics", JSON.stringify({}));
        setUpdateDiagnosticsSkeleton(false);
      }
    }
  }, []);

  const fetchQrData = useCallback(async () => {
    try {
      localStorage.setItem("terminals_qr_data", JSON.stringify({}));
      const res = await TerminalService.fetch_qr_data(
        terminalData.terminal,
        terminalData.merchant
      );
      if (res.status === 200) {
        localStorage.setItem("terminals_qr_data", JSON.stringify(res.data));
      }
    } catch (error: any) {
      localStorage.setItem("terminals_qr_data", JSON.stringify({}));
    }
  }, []);

  //Setting
  const fetchTerminalSetting = useCallback(async () => {
    setAlertErrorSettings(false);
    try {
      localStorage.setItem("terminals_settings", JSON.stringify({}));
      const res = await TerminalService.terminal_setting(
        terminalData.terminal,
        terminalData.merchant
      );
      const cardsConcat = res.data.enabledProducts.concat(
        res.data.availableProducts
      );
      const cardData: any[] = [];
      cardsConcat.forEach((card: any) => {
        cardData.push({
          productId: card.productId,
          productName: card.productName,
        });
      });
      if (res.status === 200) {
        localStorage.setItem("terminals_settings", JSON.stringify(res.data));
        setUpdateSettingsSkeleton(true);
      }
    } catch (error: any) {
      setAlertErrorSettings(true);
      setErrorCodeAlertSettings(error?.response?.status || error?.message);
      localStorage.setItem("terminals_settings", JSON.stringify({}));
      setUpdateSettingsSkeleton(false);
    }
  }, []);

  //VersionStatus
  const fetchVersionStatus = useCallback(async () => {
    try {
      localStorage.setItem("version_status", JSON.stringify({}));
      const res = await TerminalService.version_status();

      if (res.status === 200) {
        localStorage.setItem("version_status", JSON.stringify(res.data));
        //setUpdateSettingsSkeleton(true)
      }
    } catch (error: any) {
      localStorage.setItem("version_status", JSON.stringify({}));
      //setUpdateSettingsSkeleton(true)
    }
  }, []);

  //Transactions
  const {
    setLoadingTransactionsSkeleton,
    //Transactions Alert
    setErrorCodeAlertTransactions,
  } = useContext(FilterTransactionContext);

  const firstCallback = async () => {
    setAlertErrorSettings(false);
    // setAlertErrorDiagnostics(false);
    // setAlertLocalErrors(false);
    setErrorCodeAlertDiagnostics(null);
    setErrorCodeAlertSettings(null);
    setErrorCodeAlertTransactions(null);
    // setErrorCodeAlertLocalErrors(null);
    setLoadComponents(false);
    setUpdateTab(0);
    setUpdateDiagnosticsSkeleton(false);
    setUpdateSettingsSkeleton(false);
    setLoadingTransactionsSkeleton(false);
    try {
      await fetchTerminalSetting()
        .then(
          //diagnosticsOpen false no llama al servico
          () => diagnosticsOpen && fetchTerminalDiagnostics()
        )
        .then(() =>
          fetchQrData().then(() =>
            fetchVersionStatus().then(() => setLoadComponents(true))
          )
        );
    } catch (error: any) {
      MixPanelService.sendDataMixPanel(
        "config_error",
        "Diagnostics - Error fetching terminal configuration",
        userRole as string,
        {
          "Error Number":
            error.message === "Network Error"
              ? "500"
              : error?.response?.status || error?.message,
          "Error Message": error?.message,
        }
      );
      setUpdateSettingsSkeleton(true);
      setUpdateDiagnosticsSkeleton(true);
      setLoadingTransactionsSkeleton(true);
    }
  };

  useEffect(() => {
    firstCallback();
    //capture modal descarga
    mixpanel.track("sc_diagnostico", {
      Screen: "Diagnostics - Terminal Diagnostics",
    });
  }, [terminalData.serial, terminalData.merchant, terminalData.terminal]);

  return (
    <Grid
      style={{
        height: "100%",
        minHeight: "100%",
      }}
    >
      <div className={styles.terminalDiagnostic}>
        <Diagnostics
          terminalData={terminalData}
          params_merchant={match?.params?.merchant}
          params_terminal={match?.params?.id_terminal}
          firstCallback={firstCallback}
          fetchQrData={fetchQrData}
          fetchVersionStatus={fetchVersionStatus}
        />
      </div>
    </Grid>
  );
};

export default TerminalDashboard;
