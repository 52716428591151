import * as types from "./type";
import { INITIAL_STATE } from "./initialState";
//funcion pura
const reducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  switch (type) {
    case types.SHOW_LOADING:
      return { ...state, showLoading: true };

    case types.HIDE_LOADING:
      return { ...state, showLoading: false };

    case types.SHOW_LOADING_ALERT:
      return { ...state, loadingAlert: true };

    case types.HIDE_LOADING_ALERT:
      return { ...state, loadingAlert: false };

    default:
      return state;
  }
};

export default reducer;
