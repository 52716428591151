//las acciones consisas y tienen que espresar la intencion de hacer algo
import * as types from "./type";

export const showLoading = () => ({
  type: types.SHOW_LOADING,
  payload: {},
});

export const hideLoading = () => ({
  type: types.HIDE_LOADING,
  payload: {},
});

export const showLoadingAlert = () => ({
  type: types.SHOW_LOADING_ALERT,
  payload: {},
});

export const hideLoadingAlert = () => ({
  type: types.HIDE_LOADING_ALERT,
  payload: {},
});
