import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    rootHeader: {
      display: "flex",
      flexDirection: "column",
      paddingTop: ".8125rem",
    },
    headerText: {
      textTransform: "uppercase",
      fontFamily: "Rubik",
      fontSize: "1.5rem",
      color: colors.primary,
      lineHeight: "1.75rem",
      fontWeight: 400,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    textTittle: {
      fontFamily: "Rubik",
      fontSize: ".875rem",
      color: colors.primary,
      fontWeight: 400,
    },
    textValue: {
      fontFamily: "Rubik",
      fontSize: "1rem",
      color: colors.primary,
      fontWeight: 500,
    },
    headerButton: {
      paddingTop: "1rem",
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    dateStyle: {
      marginLeft: "2rem",
      width: "360px",
      color: colors.grayText,
    },
    rootTabs: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "1rem",
      padding: "1rem",
      background: colors.background,
      /* height: "100%",
      minHeight: "100%", */
    },
    tabStyles: {
      maxWidth: "15rem",
      width: "23.2%",
      border: `1px solid ${colors.background}`,
      marginRight: "1rem",
      borderTopLeftRadius: "0.625rem",
      borderTopRightRadius: "0.625rem",
      textTransform: "none",
      fontSize: "1rem",
      color: "rgba(74, 144, 226, 1)",
      background: colors.white,
      borderBottom: `0.063rem solid ${colors.white}`,
    },
    TabPanelStyles: {
      border: `1px solid ${colors.background}`,
      borderTopRightRadius: "0.625rem",
      borderBottomRightRadius: "0.625rem",
      borderBottomLeftRadius: "0.625rem",
      background: colors.white,
      boxSizing: "content-box",
      width: "100%",
      height: "100%",
      minHeight: "100%",
    },
    backButton: {
      paddingRight: ".6875rem",
      cursor: "pointer",
      borderRadius: "50%",
    },
  })
);
export default useStyles;
