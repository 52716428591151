import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import { useHistory } from "react-router-dom";
import colors from "constants/colors";

interface Props {
  authenticated: any;
  role?: string;
  handleMenu: any;
  given_name: string;
  family_name: string;
  anchorEl?: any;
  open: boolean;
  handleClose: any;
  handleCloseLogin: any;
}

const HeaderComponent = ({
  authenticated,
  role,
  handleMenu,
  given_name,
  family_name,
  anchorEl,
  open,
  handleClose,
  handleCloseLogin,
}: Props) => {
  const history = useHistory();

  return (
    <AppBar
      position="static"
      style={{ background: colors.primary, height: "4.3125rem" }}
    >
      <Toolbar>
        <LogoIcon
          onClick={() => history.push("/home")}
          style={{ cursor: "pointer" }}
        />
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        {authenticated && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: "0.2rem",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  fontSize: "1rem",
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {family_name || ""} {given_name || ""}
              </p>
              <p
                style={{
                  color: colors.white,
                  marginTop: 0,
                  fontSize: "0.875rem",
                }}
              >
                {role || ""}
              </p>
            </div>
            <div
              style={{
                width: "2rem",
                height: "2rem",
                backgroundColor: colors.primaryLight,
                borderRadius: "50%",
                color: colors.white,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                margin: "0.5rem",
              }}
              onClick={handleMenu}
            >
              <p
                style={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                  fontFamily: "Roboto",
                }}
              >
                {family_name.charAt(0) || ""}
                {given_name.charAt(0) || ""}
              </p>
            </div>
            <Menu
              style={{
                marginTop: "2.5rem",
              }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleCloseLogin}
                style={{
                  height: "2.5rem",
                  marginBottom: "-8px",
                  marginTop: "-8px",
                }}
              >
                <p style={{ color: colors.grayText }}>Cerrar sesión</p>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(HeaderComponent);
