import React, { useEffect, useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { format /* , sub */, parseISO } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as ArrowDownIcon } from "assets/svg/down_icon.svg";
//Context
import RoleContext from "context/userRole/RoleContext";
import useStyles from "./styles";

import MixPanelService from "services/mixpanel.service";
import Button from "@mui/material/Button";
import { Dialog, DialogTitle } from "@mui/material/";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import colors from "constants/colors";
import { withStyles, WithStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

interface Column {
  id:
    | "errorDate"
    | "errorCode"
    | "errorMessage"
    | "breadcrumbs"
    | "productId"
    | "bin"
    | "amount";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}
//object
const objectValues: any = {
  errorDate: "Fecha",
  errorCode: "Código de Error",
  errorMessage: "Mensaje de Error",
  breadcrumbs: "Pasos en terminal",
  product_id: "ID Tarjeta",
  bin: "Primeros 6 dígitos",
  amount: "Monto",
};

const columns: readonly Column[] = [
  { id: "errorDate", label: objectValues["errorDate"], minWidth: 160 },
  { id: "errorCode", label: objectValues["errorCode"], minWidth: 100 },
  { id: "errorMessage", label: objectValues["errorMessage"], minWidth: 180 },
  { id: "breadcrumbs", label: objectValues["breadcrumbs"], minWidth: 180 },
  { id: "productId", label: objectValues["product_id"], minWidth: 100 },
  { id: "bin", label: objectValues["bin"], minWidth: 100 },
  { id: "amount", label: objectValues["amount"], minWidth: 100 },
];

interface Data {
  errorDate: string;
  errorCode: string;
  errorMessage: string;
  breadcrumbs: string;
  amount: number;
  productId: number;
  bin: number;
  installments: number;
  ticketNumber: number;
  transactionId: string;
  captureType: string;
  cardServiceCode: number;
  connectionType: string;
  wifiLevel: number;
  errorType: string;
  isCharging: boolean;
  kernelVersion: string;
  lastBootDate: string;
  osVersion: string;
  paymentType: string;
  paymentsAppVersion: string;
  batteryLevel: number;
  serial: string;
  subAcquirerId: number;
}

function createData(
  errorDate: string,
  errorCode: string,
  errorMessage: string,
  breadcrumbs: string,
  amount: number,
  productId: number,
  bin: number,
  installments: number,
  ticketNumber: number,
  transactionId: string,
  captureType: string,
  cardServiceCode: number,
  connectionType: string,
  wifiLevel: number,
  errorType: string,
  isCharging: boolean,
  batteryLevel: number,
  kernelVersion: string,
  lastBootDate: string,
  osVersion: string,
  paymentType: string,
  paymentsAppVersion: string,
  serial: string,
  subAcquirerId: number
): Data {
  return {
    errorDate,
    errorCode,
    errorMessage,
    breadcrumbs,
    amount,
    productId,
    bin,
    installments,
    ticketNumber,
    transactionId,
    captureType,
    cardServiceCode,
    connectionType,
    wifiLevel,
    errorType,
    isCharging,
    batteryLevel,
    kernelVersion,
    lastBootDate,
    osVersion,
    paymentType,
    paymentsAppVersion,
    serial,
    subAcquirerId,
  };
}

type Props = {
  state?: any;
  selectState?: any;
  fetchPage?: any;
  setFetchPage?: any;
  total_elements: any;
  total_pages: any;
};

const TextOnlyTooltip = withStyles({
  arrow: {
    color: "#373E45",
  },
  tooltip: {
    backgroundColor: "#373E45",
    width: "103px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 400,
    "&.MuiTooltip-tooltip": {
      marginBottom: "8px !important",
    },
  },
})(Tooltip);

const StickyHeadTable: React.FC<Props> = ({
  state,
  fetchPage,
  setFetchPage,
  total_elements = 0,
  total_pages = 0,
}) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  //Context
  const { role } = useContext(RoleContext);
  const rows: Data[] = [];

  for (let i = 0; i < Object.values(state).length; i += 1) {
    rows.push(
      createData(
        format(parseISO(state[i].error_date), "dd/MM/yyyy - HH:mm:ss"),
        state[i].error_code,
        state[i].error_message,
        state[i].breadcrumbs,
        state[i].amount,
        state[i].product_id,
        state[i].bin,
        state[i].installments,
        state[i].ticket_number,
        state[i].transaction_id,
        state[i].capture_type,
        state[i].card_service_code,
        state[i].connection_type,
        state[i].wifi_level,
        state[i].error_type,
        state[i].isCharging,
        state[i].battery_level,
        state[i].kernel_version,
        state[i].last_boot_date,
        state[i].os_version,
        state[i].payment_type,
        state[i].payment_app_version,
        state[i].serial,
        state[i].subAcquirerId
      )
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (page < newPage) {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "next_page_button_errors",
        "Diagnostics - Local Errors Tab",
        role
      );
    } else {
      //mixpanel Carga de Apps install
      MixPanelService.sendDataMixPanel(
        "previous_page_button_errors",
        "Diagnostics - Local Errors Tab",
        role
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "results_per_page_button_errors",
      "Diagnostics - Local Errors Tab",
      role,
      { Results: +event.target.value }
    );
  };
  const loadPreviousPage = async () => {
    if (fetchPage > 0) {
      setFetchPage(fetchPage - 1);
    }
    MixPanelService.sendDataMixPanel(
      "previous_page_button_errors",
      "Diagnostics - Local Errors Tab",
      role
    );
  };
  const loadNextPage = async () => {
    setFetchPage(fetchPage + 1);
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "next_page_button_errors",
      "Diagnostics - Local Errors Tab",
      role
    );
  };

  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [currentError, setCurrentError] = React.useState([]);

  const handleClickOpen = (cError: any) => {
    setOpenErrorDialog(true);
    setCurrentError(cError);
  };

  const handleClose = () => {
    setOpenErrorDialog(false);
    setCurrentError([]);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const copyErrorToClipboard = () => {
    setOpenSnackbar(true);
    navigator.clipboard.writeText(JSON.stringify(currentError, null, 2));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        background: colors.background,
        boxShadow: "none",
        overflowY: "hidden",
        overflowX: "auto",
        boxSizing: "border-box",
      }}
    >
      <TableContainer sx={{ minHeight: "41.2rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "transparent",
                    paddingLeft: 8,
                    left: 0,
                  }}
                >
                  <Typography
                    style={{
                      color: colors.primary,
                      fontSize: ".875rem",
                      fontWeight: 500,
                      lineHeight: "1.5rem",
                      textTransform: "uppercase",
                      verticalAlign: "top",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover={true}
                    role="checkbox"
                    tabIndex={-1}
                    key={uuidv4()}
                    // onClick={() => handleClickOpen(row)}
                  >
                    {/* {" "} */}
                    {columns.map((column) => {
                      const value: any = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            background: colors.background,
                            position: "sticky",
                            left: 0,
                            borderBottom: `1px solid ${colors.borderDiv}`,
                            padding: 8,
                            height: "3em",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                color: colors.textTable,
                                fontSize: ".875rem",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {column.id === "amount"
                                ? value != null
                                  ? "$ " + value
                                  : ""
                                : value}
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      style={{ width: "3em" }}
                      onClick={() => handleClickOpen(row)}
                    >
                      <TextOnlyTooltip
                        title={"Datos del error"}
                        arrow
                        placement="bottom"
                      >
                        <IconButton color="info">
                          <InfoOutlinedIcon sx={{ color: "#023F80" }} />
                        </IconButton>
                      </TextOnlyTooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            <Dialog
              open={openErrorDialog}
              onClose={handleClose}
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                color={colors.primary}
                sx={{
                  margin: 0,
                  paddingTop: "2em",
                  paddingLeft: "2em",
                  fontSize: "20px",
                }}
              >
                {"Datos del error"}
              </DialogTitle>
              <p
                style={{
                  paddingLeft: "2.5em",
                  paddingRight: "2.5em",
                  fontSize: "16px",
                }}
              >
                Copiá esta información si necesitás derivar el error a otra área
                de soporte.
              </p>
              <Divider />
              <DialogContent>
                <pre
                  className="layout__item u-1/2-lap-and-up"
                  style={{ margin: ".5em", lineHeight: 1.8 }}
                >
                  {JSON.stringify(currentError, null, 2)}
                </pre>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{ width: "50%", margin: "1em", color: colors.primary }}
                >
                  Cerrar
                </Button>
                <Button
                  onClick={copyErrorToClipboard}
                  sx={{ width: "50%", margin: "1em", color: colors.primary }}
                  autoFocus
                >
                  Copiar información
                </Button>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000}
                  onClose={handleCloseSnackbar}
                  message="Copiado al portapapeles"
                  action={action}
                />
              </DialogActions>
            </Dialog>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100 /* , 20, 50, 100 */]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => {
          let total: number = fetchPage * 100;
          let totalNext: number = (fetchPage + 1) * 100;
          return (
            "Página " +
            fetchPage +
            " - " +
            (fetchPage !== 0 && total_elements <= total
              ? total_elements
              : totalNext > total_elements
              ? total_elements
              : totalNext) +
            " de " +
            total_elements +
            " resultados"
          );
        }}
        labelRowsPerPage={""}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          color: colors.primary,
        }}
        SelectProps={{
          //MenuProps: { classes: { paper: classes.selectDropdown } },
          IconComponent: ArrowDownIcon,
        }}
        //classes={{ menuItem: classes.menuItem }}
        backIconButtonProps={{
          onClick: () => loadPreviousPage(),
          className: classes.leftIconButton,
          disabled: fetchPage <= 1 ? true : false,
        }}
        nextIconButtonProps={{
          onClick: () => loadNextPage(),
          className: classes.rightIconButton,
          disabled: fetchPage >= total_pages ? true : false,
        }}
      />
    </Paper>
  );
};
export default React.memo(StickyHeadTable);
