import * as types from "./type";
import { INITIAL_STATE } from "./initialState";
//funcion pura
const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USER_DATA:
      console.log("SET_USER_DATA => ", payload.data);
      return { ...state, isLoggedIn: true, user: payload.data };
    default:
      return state;
  }
};

export default reducer;
