import colors from "constants/colors";
//styles
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    listText: {
      fontSize: "14px",
    },
  })
);

export default useStyles;
