import React, { useReducer } from "react";
import OpenDrawerReducer from "./OpenDrawerReducer";
import OpenDrawerContext from "./OpenDrawerContext";

const UpdateDataState = (props) => {
  //check local storage
  const initialState = {
    drawerOpen: false,
  };

  const [state, dispatch] = useReducer(OpenDrawerReducer, initialState);

  const setUpdateDrawer = async (data) => {
    dispatch({
      type: "SET_UPDATE_DRAWER",
      payload: data,
    });
  };

  return (
    <OpenDrawerContext.Provider
      value={{ drawerOpen: state.drawerOpen, setUpdateDrawer }}
    >
      {props.children}
    </OpenDrawerContext.Provider>
  );
};

export default UpdateDataState;
