import Button from "@mui/material/Button";
import colors from "constants/colors";
import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  tittle?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customStyle?: object;
  variant?: string;
  disabled?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    buttomFilter: {
      border: "none",
      width: "8.5rem",
      borderRadius: "1.75rem",
      height: "3.5rem",
      textTransform: "none",
      fontFamily: "Rubik",
      fontSize: "1.125rem",
      backgroundColor: colors.primary,
      "&:hover": {
        backgroundColor: colors.primarydark,
        color: colors.white,
      },
      "&:active": {
        backgroundColor: colors.cyan,
        color: colors.white,
      },
    },
  })
);

const Buttom: React.FC<Props> = ({ tittle, onClick, customStyle, variant, disabled }) => {
  const styles = useStyles();
  return (
    <Button
      color="inherit"
      variant="outlined"
      className={styles.buttomFilter}
      style={{
        color: colors.white,
        backgroundColor: disabled ? colors.grayDisabled : "",
        ...customStyle,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {tittle}
    </Button>
  );
};

export default Buttom;
