import React, { useState, useEffect, useContext } from "react";
import colors from "constants/colors";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Skeleton from "@mui/material/Skeleton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Select, { SelectChangeEvent } from "@mui/material/Select";
//Prevented Error Component
import ErrorBoundary from "components/errorBoundary";
import AlertMessage from "components/alertMessage";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";
import useStyles from "./styles";
import RoleContext from "context/userRole/RoleContext";
import MixPanelService from "services/mixpanel.service";
import TerminalService from "services/terminal.service";
import IntentsTable from "./table/IntentsTable";
import mixpanel from "mixpanel-browser";

type Props = any;

const Intents: React.FC<Props> = () => {
  const styles = useStyles();
  //Context
  const { role } = useContext(RoleContext);

  const retryButton = () => {
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "retry_button",
      "Diagnostics - Diagnostico de terminal",
      role
    );
  };

  const twoDigits = (value: number) => {
    if (value.toString().length == 1) {
      return "0" + value;
    }
    return value;
  };
  const format = (date: Date) => {
    return (
      date.getFullYear() +
      "-" +
      twoDigits(date.getMonth() + 1) +
      "-" +
      twoDigits(date.getDate()) +
      "T" +
      twoDigits(date.getHours()) +
      ":" +
      twoDigits(date.getMinutes()) +
      ":" +
      twoDigits(date.getSeconds())
    );
  };

  const todayStart = format(new Date()).toString().slice(0, 11) + "00:00:01";
  const todayNow = format(new Date()).toString();

  // valor inicial para página de transacciones = 0
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchPage, setFetchPage] = useState<number>(0);
  const [intents, setIntents] = useState<any>(null);
  const [initDate, setInitDate] = useState<any>(todayStart);
  const [finishDate, setFinishDate] = useState<any>(todayNow);

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setFinishDate(new Date());
    setInitDate(new Date());
  };

  const validTID = localStorage.getItem("validTID");
  const validMID = localStorage.getItem("validMID");

  const fetchIntents = async (
    page: number,
    initTime: string,
    finishTime: string
  ) => {
    try {
      const res = await TerminalService.terminal_intents(
        validTID || "",
        validMID || "",
        page,
        initTime,
        finishTime
      );
      if (res.status === 200) {
        setLoading(false);
        setIntents(res.data.content);
        mixpanel.track("intents_tab", {
          Screen: `Transactions - filter Intents`,
          Filter: {
            from: initTime,
            to: finishTime,
            page: page
          }
        });
        return res.data.content;
      }
    } catch (error: any) {
      console.log(error.message);
      mixpanel.track("intents_tab", {
        Screen: `Transactions - filter Intents`,
        Error: error.message
      });
      setLoading(false);
    }
  };

  const offset = new Date().getTimezoneOffset();
  const todayFrom = new Date();
  todayFrom.setHours(0);
  todayFrom.setMinutes(-offset);
  const todayTo = new Date();
  todayTo.setMinutes(-offset);

  function updateDateFrom(val: any) {
    setInitDate(val);
  }

  function updateDateTo(val: any) {
    setFinishDate(val);
  }
  useEffect(() => {
    fetchIntents(fetchPage, initDate, finishDate);
  }, [fetchPage]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12}>
          <Card
            className={styles.cardInternal}
            sx={{ height: "7em", width: "100%" }}
          >
            <Grid className={styles.gridInternal}>
              <ErrorBoundary>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                >
                  <Grid style={{ paddingRight: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <form className={styles.container} noValidate>
                        <TextField
                          id="datefrom"
                          label="Fecha desde"
                          type="datetime-local"
                          defaultValue={todayStart}
                          className={styles.dateTextField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            sx: {
                              color: colors.primarydark,
                              borderColor: colors.primarydark,
                              wordSpacing: 6,
                            },
                          }}
                          InputProps={{
                            inputProps: {
                              max: finishDate
                            }
                          }}
                          onChange={(newValue) =>
                            updateDateFrom(newValue.target.value)
                          }
                        />
                      </form>
                    </LocalizationProvider>
                  </Grid>
                  <Grid style={{ paddingRight: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <form className={styles.container} noValidate>
                        <TextField
                          id="dateto"
                          label="Fecha hasta"
                          type="datetime-local"
                          defaultValue={todayNow}
                          className={styles.dateTextField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            sx: {
                              color: colors.primarydark,
                              borderColor: colors.primarydark,
                              wordSpacing: 6,
                            },
                          }}
                          InputProps={{
                            inputProps: {
                              min: initDate,
                              max: todayNow
                            }
                          }}
                          onChange={(newValue) =>
                            updateDateTo(newValue.target.value)
                          }
                        />
                      </form>
                    </LocalizationProvider>
                  </Grid>
                  <Grid style={{ paddingRight: "3rem" }}>
                    {!loading ? (
                      <IconButton
                        color="primary"
                        className={styles.buttomFilter}
                        onClick={() => (
                          fetchIntents(fetchPage, initDate, finishDate),
                          setLoading(true),
                          //mixpanel Carga de Apps install
                          MixPanelService.sendDataMixPanel(
                            "apply_filter_intents",
                            "Diagnostics - Transactions - Intents Tab",
                            role,
                            {
                              "Init Date": initDate,

                              "Finish Date": finishDate,
                            }
                          )
                        )}
                      >
                        Aplicar
                        <FilterIcon style={{ paddingLeft: ".5rem" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        className={styles.buttomFilter}
                      >
                        <div
                          className="rotateIcon"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "2rem",
                            height: "2rem",
                          }}
                        >
                          <LoadingIcon />
                        </div>
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </ErrorBoundary>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} xl={12}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              style={{
                background: colors.background,
                borderRadius: "10px",
              }}
              width={"100%"}
              height={"41.2rem"}
            />
          ) : !intents?.length ? (
            <div
              style={{
                width: "100%",
                minHeight: "41.2rem",
              }}
            >
              <AlertMessage
                message={"¡No se encontraron transacciones!"}
                customStyle={{ paddingTop: "8rem", paddingBottom: "2rem" }}
              />
            </div>
          ) : (
            <Card className={styles.cardTable}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <IntentsTable
                    state={intents}
                    fetchPage={fetchPage}
                    setFetchPage={setFetchPage}
                    total_elements={intents?.total_elements}
                    total_pages={intents?.total_pages}
                  />
                </ErrorBoundary>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Intents;
