import React from "react";
import {
    Card,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { createStyles, makeStyles } from "@mui/styles";
import colors from "constants/colors";

const InfoAlert = () => {

    const useStyles = makeStyles(() =>
        createStyles({
            infoAlert: {
                backgroundColor: "white",
                marginBottom: "0.8em",
                height: "6em",
                minHeight: "0",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                border: "solid",
                borderWidth: "1.2px",
                borderRadius: ".5rem",
                borderColor: colors.grayDisabled,
            },
        })
    );

    const styles = useStyles();

    const [openAlert, setOpenAlert] = React.useState(true);

    const handleCloseAlert = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    return (
        <>
            {
                openAlert ? (
                    <Card className={styles.infoAlert} elevation={0} >
                        <div
                            style={{
                                backgroundColor: "#FFCC00",
                                width: "8px",
                                height: "100px",
                            }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={10} xl={10}>
                                <p
                                    style={{
                                        display: "flex",
                                        alignItems: "start",
                                    }}
                                >
                                    <WarningIcon style={{ padding: "0em 1em", color: "#FFCC00" }} />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                            justifyContent: "start",
                                        }}
                                    >
                                        <Typography>
                                            Antes de realizar una modificación, recordá validar el
                                            estado de la reprogramación.
                                        </Typography>
                                    </div>
                                </p>
                            </Grid>
                            <Grid item xs={2} xl={2}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "end",
                                        alignItems: "start",
                                        padding: "0.5em",
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={handleCloseAlert}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Card >
                ) : null}
        </>)
};

export default InfoAlert;
