import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { v4 as uuidv4 } from "uuid";
import colors from "constants/colors";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import { ReactComponent as ArrowDown } from "assets/svg/table/arrow_down.svg";
import { ReactComponent as ArrowUp } from "assets/svg/table/arrow_up.svg";
import moment from "moment";
import InfoDanger from "components/elements/infoDanger";


function createData(name: string, datos: object) {
  return {
    name,
    datos,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState<boolean>(
    localStorage.getItem("openedAccordion" + row.name) === "true"
  );

  const changeStateAndSaveInLocalStorage = (value: boolean) => {
    localStorage.setItem("openedAccordion" + row.name, value.toString()); // localstorage solo guarda strings
    setOpen(value);
  };

  interface Data {
    id: any;
    key: any;
    value: any;
  }

  function createDataRow(id: any, key: any, value: any): Data {
    return { id, key, value };
  }

  const tableCellRows: Data[] = [];
  const tableCellRowsDeviceTest: Data[] = [];

  if (
    row?.name !== "date" &&
    row?.name !== "device_test" &&
    row?.name !== "acquirers"
  ) {
    for (let i = 0; i < Object.keys(row.datos).length; i += 1) {
      tableCellRows.push(
        createDataRow(
          uuidv4(),
          Object.keys(row.datos)[i],
          Object.values(row.datos)[i] === true
            ? "true"
            : Object.values(row.datos)[i] === false
            ? "false"
            : Object.values(row.datos)[i]
        )
      );
    }
  } else if (row?.name === "device_test") {
    for (let i = 0; i < Object.keys(row.datos).length; i += 1) {
      tableCellRowsDeviceTest.push(
        createDataRow(
          uuidv4(),
          Object.keys(row.datos)[i],
          Object.values(row.datos)[i] === true
            ? "true"
            : Object.values(row.datos)[i] === false
            ? "false"
            : Object.values(row.datos)[i]
        )
      );
    }
  } else if (row?.name === "acquirers") {
    for (
      let i = 0;
      i <
      Object.keys(Object.values(row.datos)[Object.keys(row.datos).length - 1]) //tomar el ultimo elemento del array para mostar
        .length;
      i += 1
    ) {
      tableCellRows.push(
        createDataRow(
          uuidv4(),
          Object.keys(
            Object.values(row.datos)[Object.keys(row.datos).length - 1]
          )[i],
          Object.values(
            Object.values(row.datos)[Object.keys(row.datos).length - 1]
          )[i]
        )
      );
    }
  }

  return (
    <React.Fragment>
      {row.name !== "date" && (
        <TableRow sx={{ "& > *": { margin: 0 } }} hover>
          <TableCell
            component="th"
            scope="row"
            onClick={() => changeStateAndSaveInLocalStorage(!open)}
            style={{ fontFamily: "Rubik" }}
          >
            {row.name}
          </TableCell>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={() => changeStateAndSaveInLocalStorage(!open)}
          >
            <IconButton aria-label="expand row" size="small">
              {open ? <ArrowUp /> : <ArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell
          style={{
            display: !open ? "none" : "",
            padding: 16,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableCellRows.map((dato) => (
                    <TableRow key={uuidv4()} hover>
                      <TableCell component="th" scope="row">
                        {dato?.key}
                      </TableCell>
                      <TableCell>{dato?.value}</TableCell>
                    </TableRow>
                  ))}
                  {tableCellRowsDeviceTest.map((dato) => (
                    <TableRow key={uuidv4()}>
                      <TableCell component="th" scope="row">
                        {dato?.key}
                      </TableCell>
                      <TableCell>
                        date:
                        {moment(parseInt(dato?.value?.date))
                          .utc()
                          .format("DD/MM/yyyy") || ""}
                      </TableCell>
                      <TableCell>
                        status:
                        {dato?.value?.status === true ? "true" : "false"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {row.name !== "date" && (
        <TableCell
          style={{
            paddingTop: 0,
            backgroundColor: colors.white,
            borderBottom: "none",
          }}
        ></TableCell>
      )}
    </React.Fragment>
  );
}

export default function CollapsibleTable({ state }: any) {
  const styles = useStyles();
  let rows = [];
  try {
    if (!!state) {
      for (let i = 0; i < Object.values(state).length; i += 1) {
        rows.push(
          createData(Object.keys(state)[i], state[Object.keys(state)[i]])
        );
      }
    }
    let data: any = rows[3]?.datos;
    return (
      <div>
        {!!state ? (
          <>
            <Typography className={styles.textDate}>
              Fecha del análisis:
              {!!state
                ? ` ${moment(parseInt(data)).utc().format("DD/MM/yyyy")}`
                : ""}
            </Typography>
            {!!state && (
              <TableContainer className={styles.scrollInternal}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow></TableRow>
                  </TableHead>
                  <TableBody style={{ backgroundColor: colors.background }}>
                    {rows.map((row) => (
                      <Row key={uuidv4()} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <InfoDanger
            tittle={`No tenemos datos disponibles.`}
            subTittle={
              "Para obtener información, es necesario que solicites el diagnóstico de la terminal."
            }
            customStyle={{
              color: colors.grayText,
              fontWeight: 500,
            }}
            customStyleContent={{
              paddingTop: "0rem",
              minHeight: 800,
            }}
          />
        )}
      </div>
    );
  } catch (error) {
    return <></>;
  }
}
