import Skeleton from "@mui/material/Skeleton";
import colors from "../../constants/colors";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import useStyles from "./styles";

const SkeletonHome = () => {

  const styles = useStyles();

  return (
    <Grid container spacing={2} width="100%">
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            style={{
              background: colors.background,
              borderRadius: "10px",
              minWidth: "100%",
              height: "31.25rem",
            }}
            width="100%"
            height="98%"
          /></Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            style={{
              background: colors.background,
              borderRadius: "10px",
              minWidth: "100%",
              height: "20.25rem",
            }}
            width="100%"
            height="98%"
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rectangular"
            style={{
              background: colors.background,
              borderRadius: "10px",
              minHeight: 606,
            }}
            width="100%"
            height="98%"
          />
        </Grid>
        <Grid item xs={6} >
          <Skeleton
            variant="rectangular"
            style={{
              background: colors.background,
              borderRadius: "10px",
              minHeight: 606,
            }}
            width="100%"
            height="98%"
        />
      </Grid>
    </Grid>
  );
};

export default SkeletonHome;
