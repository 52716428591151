import React, { useReducer } from "react";
import TabsReducer from "./TabsReducer";
import TabsContext from "./TabsContext";

const TabsState = (props) => {
  const initialState = {
    tab: 0,
    apps_install: false,

    diagnosticsOpen: localStorage.getItem("diagnostic_open") ?? false,

    loadComponents: false,
    diagnosticsSkeleton: false,
    settingsSkeleton: false,
    ////Settings Alert
    alertErrorSettings: false,
    errorCodeAlertSettings: null,
    ////DIAGNOSTICS Alert
    alertErrorDiagnostics: false,
    errorCodeAlertDiagnostics: null,
  };

  const [state, dispatch] = useReducer(TabsReducer, initialState);

  const setUpdateTab = async (data) => {
    dispatch({
      type: "SET_UPDATE_TABS",
      payload: data,
    });
  };

  const setUpdateApps = async (data) => {
    dispatch({
      type: "SET_UPDATE_FILTER_APPS",
      payload: data,
    });
  };

  const setUpdateDiagnosticsSkeleton = async (data) => {
    dispatch({
      type: "SET_UPDATE_DIAGNOSTICS_SKELETON",
      payload: data,
    });
  };

  const setUpdateSettingsSkeleton = async (data) => {
    dispatch({
      type: "SET_UPDATE_SETTING_SKELETON",
      payload: data,
    });
  };

  const setLoadComponents = async (data) => {
    dispatch({
      type: "SET_UPDATE_LOADCOMPONENTS",
      payload: data,
    });
  };
  //Settings Alert
  const setAlertErrorSettings = async (data) => {
    dispatch({
      type: "SET_UPDATE_ALERT_SETTINGS",
      payload: data,
    });
  };
  const setErrorCodeAlertSettings = async (data) => {
    dispatch({
      type: "SET_UPDATE_ALERT_SETTINGS_CODE",
      payload: data,
    });
  };

  //Diagnostics Alert
  const setAlertErrorDiagnostics = async (data) => {
    dispatch({
      type: "SET_UPDATE_ALERT_DIAGNOSTICS",
      payload: data,
    });
  };
  const setErrorCodeAlertDiagnostics = async (data) => {
    dispatch({
      type: "SET_UPDATE_ALERT_DIAGNOSTICS_CODE",
      payload: data,
    });
  };

  //Diagnostics Alert
  const setDiagnosticOpen = async (data) => {
    dispatch({
      type: "SET_DIAGNOSTICS_OPEN",
      payload: data,
    });
  };

  return (
    <TabsContext.Provider
      value={{
        diagnosticsOpen: state.diagnosticsOpen,

        tabPosition: state.tab,
        appsInstall: state.apps_install,
        settingsSkeleton: state.settingsSkeleton,
        diagnosticsSkeleton: state.diagnosticsSkeleton,
        loadComponents: state.loadComponents,

        alertErrorSettings: state.alertErrorSettings,
        errorCodeAlertSettings: state.errorCodeAlertSettings,

        alertErrorDiagnostics: state.alertErrorDiagnostics,
        errorCodeAlertDiagnostics: state.errorCodeAlertDiagnostics,

        setUpdateTab,
        setUpdateApps,
        setUpdateSettingsSkeleton,
        setUpdateDiagnosticsSkeleton,
        setLoadComponents,

        setAlertErrorDiagnostics,
        setErrorCodeAlertDiagnostics,

        setAlertErrorSettings,
        setErrorCodeAlertSettings,

        setDiagnosticOpen
      }}
    >
      {props.children}
    </TabsContext.Provider>
  );
};

export default TabsState;
