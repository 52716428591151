export const SET_DIAGNOSTICS_OPEN = "SET_DIAGNOSTICS_OPEN";

export const SET_UPDATE_DATA = "SET_UPDATE_DATA";
export const SET_DISABLE_TABLA = "SET_DISABLE_TABLA";
//diagnostics
export const SET_UPDATE_TABS = "SET_UPDATE_TABS";
export const SET_UPDATE_LOADCOMPONENTS = "SET_UPDATE_LOADCOMPONENTS";

export const SET_UPDATE_FILTER_APPS = "SET_UPDATE_FILTER_APPS";

export const SET_UPDATE_DIAGNOSTICS_SKELETON = "SET_UPDATE_DIAGNOSTICS_SKELETON";
export const SET_UPDATE_SETTING_SKELETON = "SET_UPDATE_SETTING_SKELETON";

export const SET_UPDATE_ALERT_DIAGNOSTICS = "SET_UPDATE_ALERT_DIAGNOSTICS";
export const SET_UPDATE_ALERT_DIAGNOSTICS_CODE = "SET_UPDATE_ALERT_DIAGNOSTICS_CODE";

export const SET_UPDATE_ALERT_SETTINGS = "SET_UPDATE_ALERT_SETTINGS";
export const SET_UPDATE_ALERT_SETTINGS_CODE = "SET_UPDATE_ALERT_SETTINGS_CODE";
SET_UPDATE_ALERT_SETTINGS
//Transaction
export const SET_UPDATE_FILTER = "SET_UPDATE_FILTER";
export const SET_UPDATE_DATA_TRANSACTION = "SET_UPDATE_DATA_TRANSACTION";
export const SET_UPDATE_TRANSACTION_SKELETON = "SET_UPDATE_TRANSACTION_SKELETON";
export const SET_UPDATE_TRANSACTION_ALERT = "SET_UPDATE_TRANSACTION_ALERT";
export const SET_UPDATE_TRANSACTION_ALERT_CODE = "SET_UPDATE_TRANSACTION_ALERT_CODE";

//Pages
export const SET_UPDATE_DRAWER = "SET_UPDATE_DRAWER";

//ROLE
export const SET_UPDATE_ROLE = "SET_UPDATE_ROLE";

//LEAVE TOKEN_SCREEN
export const SET_TOKEN_SCREEN = "SET_TOKEN_SCREEN_LEAVE";
