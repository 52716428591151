import React, { useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useStyles from "./styles";
import RoleContext from "context/userRole/RoleContext";
import MixPanelService from "services/mixpanel.service";
import Transactions from "./Transactions";
import Transfers from "./Transfers";
import Intents from "./Intents";
import mixpanel from "mixpanel-browser";

type Props = any;

const TransactionsTab: React.FC<Props> = () => {
  const styles = useStyles();
  //Context
  const { role } = useContext(RoleContext);

  const retryButton = () => {
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "retry_button",
      "Diagnostics - Diagnostico de terminal",
      role
    );
  };

  const [tabPosition, setTabPosition] = React.useState("transactions");

  const handleTabPosition = (event: React.SyntheticEvent, newValue: string) => {
    setTabPosition(newValue);
    mixpanel.track("transactions_tab", {
      Screen: `Transactions - ${tabPosition}`,
    });
  };

  return (
    <>
      <Box sx={{ width: "max-content" }}>
        <Tabs
          value={tabPosition}
          onChange={handleTabPosition}
          className={styles.cardInternalTabs}
          sx={{ height: "3em", width: "max-content" }}
          aria-label="secondary tabs example"
        >
          <Tab
            value="transactions"
            label="Transacciones"
            className={styles.textHeaderSub}
          />
          <Tab
            value="transfers"
            label="Transferencias"
            className={styles.textHeaderSub}
          />
          <Tab
            value="intents"
            label="Intenciones de pago"
            className={styles.textHeaderSub}
          />
        </Tabs>
      </Box>
      {tabPosition === "transfers" ? (
        <Transfers />
      ) : tabPosition === "intents" ? (
        <Intents />
      ) : (
        <Transactions />
      )}
    </>
  );
};
export default TransactionsTab;
