export default function Erro404() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "6rem",
      }}
    >
      <div>
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        ></div>
        <span
          style={{
            fontSize: "20px",
          }}
        >
          ERROR 404
        </span>
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        ></div>
      </div>
    </div>
  );
}
