import React, { useReducer } from "react";
import RoleReducer from "./RoleReducer";
import RoleContext from "./RoleContext";

const RoleState = (props) => {
  const initialState = {
    role: "",
  };

  const [state, dispatch] = useReducer(RoleReducer, initialState);

  const setUpdateRole = async (data) => {
    dispatch({
      type: "SET_UPDATE_ROLE",
      payload: data,
    });
  };

  return (
    <RoleContext.Provider
      value={{
        role: state.role,
        setUpdateRole,
      }}
    >
      {props.children}
    </RoleContext.Provider>
  );
};

export default RoleState;
