import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
//import List from "@mui/material/List";
//import StarBorder from "@mui/icons-material/StarBorder";
import Grid from "@mui/material/Grid";
import colors from "constants/colors";
import { ReactComponent as WarningPrimary } from "assets/svg/warningPrimary.svg";
import Button from "components/elements/button";

type openModalData = {
  setOpenModal: any;
  indexSelect?: number;
  onClickModal?: any;
  setFromatFile?: any;
};
export default function BasicModal(props: openModalData) {
  const { setOpenModal, indexSelect/* , onClickModal */, setFromatFile } = props;

  const [open, setOpen] = React.useState<boolean>(true);

  const handleClose = () => setOpenModal(!open);

  const [openSelect, setOpenSelect] = React.useState(false);

  const handleClick = () => {
    setOpenSelect(!openSelect);
  };
  const onClickHandler: any = () => {
    //definir los tipos de formatos
    setFromatFile("XLS");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 515,
            height: 539,
            borderRadius: "8px",
            border: "1px solid #E9EDF5",
            background: "#FFFFFF",
            opacity: 0.9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "360px",
            }}
          >
            <WarningPrimary />
            <Typography
              style={{
                paddingTop: "24px",
                paddingBottom: "20px",
                color: colors.primary,
                fontFamily: "Rubik",
                fontSize: "1.25rem",
                fontWeight: 500,
                lineHeight: "1.25rem",
              }}
            >
              Descargar informe
            </Typography>
            <TextField
              id="standard-basic"
              label="Fecha"
              variant="standard"
              value={"01/09/2021"}
              fullWidth
            />
            <Grid
              style={{
                marginTop: "21px",
                width: "100%",
                background: colors.background,
              }}
            >
              <ListItemButton onClick={handleClick}>
                <ListItemText
                  primary="Seleccionar formato"
                  style={{
                    color: colors.primary,
                    fontFamily: "Rubik",
                    fontSize: "1rem",
                    fontWeight: "normal",
                    lineHeight: "1.25rem",
                  }}
                />
                <ListItemIcon>
                  <Typography
                    style={{
                      color: colors.primary,
                      fontFamily: "Rubik",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "1.25rem",
                    }}
                  >
                    XLS
                  </Typography>
                </ListItemIcon>
                {openSelect ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openSelect} timeout="auto" unmountOnExit>
                <ListItemButton>
                  <ListItemText
                    primary="CSV"
                    style={{
                      color: colors.primary,
                      fontFamily: "Rubik",
                      fontSize: "1rem",
                      fontWeight: "normal",
                      lineHeight: "1.25rem",
                    }}
                  />
                  <ListItemIcon>
                    <Typography
                      style={{
                        color: colors.primary,
                        fontFamily: "Rubik",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "1.25rem",
                      }}
                    >
                      CSV
                    </Typography>
                  </ListItemIcon>
                </ListItemButton>
              </Collapse>
            </Grid>
            <div style={{ paddingTop: "80px" }}>
              <Button
                tittle={"Descargar"}
                onClick={onClickHandler}
                customStyle={{ width: "163px" }}
              />
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
