import React, { useState, useEffect, useContext, useCallback } from "react";
import colors from "constants/colors";
//import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Skeleton from "@mui/material/Skeleton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ErrorBoundary from "components/errorBoundary";
import AlertMessage from "components/alertMessage";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as LoadingIcon } from "assets/svg/buttonIcon/loading.svg";
import useStyles from "./styles";
import { format } from "date-fns";
import TimePicker from "@mui/lab/TimePicker";
import RoleContext from "context/userRole/RoleContext";
import MixPanelService from "services/mixpanel.service";
import TerminalService from "services/terminal.service";
import TransactionsTable from "./table/TransactionsTable";
import FilterTransactionContext from "context/pages/home/diagnostics/tabTransactions/FilterTransactionContext";
import moment from "moment";
import mixpanel from "mixpanel-browser";

type Props = any;

const Transactions: React.FC<Props> = () => {
  const styles = useStyles();
  //Context
  const { role } = useContext(RoleContext);

  const retryButton = () => {
    //mixpanel Carga de Apps install
    MixPanelService.sendDataMixPanel(
      "retry_button",
      "Diagnostics - Diagnostico de terminal",
      role
    );
  };

  const { filterTransaction } = useContext(FilterTransactionContext);

  const userRole = role;

  const [value, setValue] = useState<Date | null>(new Date());
  const [init, setInit] = useState<Date | null>(
    new Date(value ? value.setHours(0, 0, 0, 0) : new Date())
  );
  const [finish, setFinish] = useState<Date | null>(
    new Date(value ? value.setHours(23, 59, 0, 0) : new Date())
  );
  const [dateTimeNow, setDateTimeNow] = useState<any>(
    moment(parseInt(Date.now().toString())).utc().format("yyyy-MM-DD")
  );
  const [initTimeNow, setInitTimeNow] = useState<any>("00:00:00");
  const [finishTimeNow, setFinishTimeNow] = useState<any>("23:59:59");

  const auxTimeInit = init && new Date(init);

  // valor inicial para página de transacciones = 0
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchPage, setFetchPage] = useState<number>(0);
  const [transactions, setTransactions] = useState<any>(null);

  //aux startDate < endDate
  let startDate = moment(init);
  let endDate = moment(finish);

  const validTID = localStorage.getItem("validTID");
  const validMID = localStorage.getItem("validMID");

  const {
    setUpdateFilter,
    setDataTransactions,
    setLoadingTransactionsSkeleton,
    //Transactions Alert
    setAlertErrorTransactions,
    setErrorCodeAlertTransactions,
  } = useContext(FilterTransactionContext);

  const fetchTerminalTransactions = useCallback(
    async (
      date: string = dateTimeNow,
      init_time: string = initTimeNow,
      finish_time: string = finishTimeNow,
      page = 0
    ) => {
      setUpdateFilter(true);
      setAlertErrorTransactions(false);
      const merchant: string = validMID || "";
      const terminal: string = validTID || "";
      try {
        ///terminal msj error
        if (terminal === "12000001") throw new Error("Not Found");
        setDataTransactions(null);
        const res = await TerminalService.terminal_transaction(
          date,
          init_time,
          finish_time,
          merchant,
          terminal,
          page
        );

        if (res.status === 200) {
          setLoadingTransactionsSkeleton(true);
          setTransactions(res.data);
          setUpdateFilter(false);
          setLoading(false);
          mixpanel.track("transactions_tab", {
            Screen: `Transactions - filter Transactions`,
            Filter: {
              day: date,
              from: init_time,
              to: finish_time,
              page: page
            }
          });
        }
      } catch (error: any) {
        setLoading(false);
        setAlertErrorTransactions(true);
        setErrorCodeAlertTransactions(
          error?.response?.status || error?.message
        );
        setLoadingTransactionsSkeleton(true);
        setUpdateFilter(false);
        //mixpanel Carga de Apps install
        MixPanelService.sendDataMixPanel(
          "sc_diagnostico_error",
          "Transacciones - filtro Transacciones",
          userRole as string,
          {
            Error: error?.response?.status ?? 404,
            "Error Message": error?.message,
          }
        );
      }
    },
    []
  );
  //Transactions

  const offset = new Date().getTimezoneOffset();
  const todayFrom = new Date();
  todayFrom.setHours(0);
  todayFrom.setMinutes(-offset);
  const todayTo = new Date();
  todayTo.setMinutes(-offset);

  console.log("");

  useEffect(() => {
    fetchTerminalTransactions(
      format(value as Date, "yyyy-MM-dd"),
      format(init as Date, "HH:mm:ss"),
      format(finish as Date, "HH:mm:ss"),
      fetchPage
    );
  }, [fetchPage]);

  const checkTimeInput = (event: any) => {
    const checkValue = event.target.value.split(":");
    if (
      parseInt(checkValue[0][0]) > 2 || //menor o igual a 2
      (parseInt(checkValue[0][0]) === 2 && parseInt(checkValue[0][1]) > 3) || // primer valor igual 2 el segundo no puede ser mayor a 3
      parseInt(checkValue[1][0]) > 5 // siempre 3 valor menor a 5
    ) {
      return event.target.blur();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={styles.cardInternal} sx={{ width: "100%" }}>
            <Grid className={styles.gridInternal}>
              <ErrorBoundary>
                <Grid
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Grid style={{ paddingRight: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label={
                          <span style={{ color: colors.grayText }}>Fecha</span>
                        }
                        value={value}
                        inputFormat={"dd/MM/yyyy"}
                        minDate={new Date("2010-01-01")}
                        maxDate={new Date()}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            variant="standard"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid style={{ paddingRight: "1rem", width: "6.5rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        inputFormat="HH:mm"
                        mask="__:__"
                        label={
                          <span style={{ color: colors.grayText }}>
                            Horario
                          </span>
                        }
                        value={init}
                        onChange={(newValue) => {
                          setInit(newValue);
                        }}
                        onError={(error) => {
                          //cambiar el foco
                          setInit(
                            new Date(
                              value ? value.setHours(0, 0, 0, 0) : new Date()
                            )
                          );
                        }}
                        onOpen={() =>
                          //mixpanel Carga de Apps install
                          MixPanelService.sendDataMixPanel(
                            "start_hour_clock",
                            "Diagnostics - Diagnostico de terminal",
                            role
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onFocus={() =>
                              //mixpanel Carga de Apps install
                              MixPanelService.sendDataMixPanel(
                                "start_hour_input_field",
                                "Diagnostics - Diagnostico de terminal",
                                role
                              )
                            }
                            onChange={(e) => {
                              checkTimeInput(e);
                            }}
                            //const valueNew = value.target.value.split(':');
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "hh:mm",
                            }}
                            variant="standard"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    style={{
                      paddingRight: "1rem",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography>a</Typography>
                  </Grid>
                  <Grid style={{ paddingRight: "1rem", width: "6.5rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes"]}
                        inputFormat="HH:mm"
                        mask="__:__"
                        label=""
                        value={finish}
                        minTime={
                          auxTimeInit &&
                          new Date(
                            auxTimeInit?.setMinutes(
                              auxTimeInit?.getMinutes() + 1
                            )
                          )
                        }
                        onChange={(newValue) => {
                          setFinish(newValue);
                        }}
                        onOpen={() =>
                          //mixpanel Carga de Apps install
                          MixPanelService.sendDataMixPanel(
                            "end_hour_clock",
                            "Diagnostics - Diagnostico de terminal",
                            role
                          )
                        }
                        onError={(error) => {
                          //cambiar el foco
                          setFinish(
                            new Date(
                              value ? value.setHours(23, 59, 0, 0) : new Date()
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onFocus={() =>
                              //mixpanel Carga de Apps install
                              MixPanelService.sendDataMixPanel(
                                "end_hour_input_field",
                                "Diagnostics - Diagnostico de terminal",
                                role
                              )
                            }
                            onChange={(event) => {
                              const checkValue = event.target.value.split(":");
                              if (
                                parseInt(checkValue[0][0]) > 2 || //menor o igual a 2
                                (parseInt(checkValue[0][0]) === 2 &&
                                  parseInt(checkValue[0][1]) > 3) || // primer valor igual 2 el segundo no puede ser mayor a 3
                                parseInt(checkValue[1][0]) > 5 // siempre 3 valor menor a 5
                              ) {
                                return event.target.blur();
                              }
                              const initialValueH = init && init?.getHours();

                              if (
                                initialValueH &&
                                initialValueH >
                                parseInt(checkValue[0].slice(0, 2)) &&
                                event.target.value.length > 3
                              ) {
                                return event.target.blur();
                              }
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "hh:mm",
                            }}
                            variant="standard"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid style={{ paddingRight: "1rem" }}>
                    {!filterTransaction ? (
                      init?.getTime() !== finish?.getTime() &&
                        endDate.diff(startDate, "minutes") > 0 ? (
                        <IconButton
                          color="primary"
                          className={styles.buttomFilter}
                          onClick={() => (
                            setFetchPage(0),
                            fetchTerminalTransactions(
                              format(value as Date, "yyyy-MM-dd"),
                              format(init as Date, "HH:mm:ss"),
                              format(finish as Date, "HH:mm:ss"),
                              0
                            ),
                            //mixpanel Carga de Apps install
                            MixPanelService.sendDataMixPanel(
                              "apply_filter_transactions",
                              "Diagnostics - Transactions - Transactions Tab",
                              role,
                              {
                                "Init hour": format(init as Date, "HH:mm:ss"),
                                "Finish hour": format(finish as Date, "HH:mm:ss"),
                                // "Estado": estado[selectState],
                              }
                            )
                          )}
                        >
                          Aplicar
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="inherit"
                          className={styles.buttomFilter}
                          style={{ backgroundColor: colors.backgroundHover }}
                        >
                          Aplicar
                          <FilterIcon style={{ paddingLeft: ".5rem" }} />
                        </IconButton>
                      )
                    ) : (
                      <IconButton
                        color="primary"
                        className={styles.buttomFilter}
                      >
                        <div
                          className="rotateIcon"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "2rem",
                            height: "2rem",
                          }}
                        >
                          <LoadingIcon />
                        </div>
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </ErrorBoundary>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} xl={12}>
          {loading ? (
            <Skeleton
              variant="rectangular"
              style={{
                background: colors.background,
                borderRadius: "10px",
              }}
              width={"100%"}
              height={"41.2rem"}
            />
          ) : !transactions?.content.length ? (
            <div
              style={{
                width: "100%",
                minHeight: "41.2rem",
              }}
            >
              <AlertMessage
                message={"¡No se encontraron transacciones!"}
                customStyle={{ paddingTop: "8rem", paddingBottom: "2rem" }}
              />
            </div>
          ) : (
            <Card className={styles.cardTable}>
              <Grid className={styles.gridInternal}>
                <ErrorBoundary>
                  <TransactionsTable
                    state={transactions?.content}
                    fetchPage={fetchPage}
                    setFetchPage={setFetchPage}
                    total_elements={transactions?.total_elements}
                    total_pages={transactions?.total_pages}
                  />
                </ErrorBoundary>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Transactions;
